/*

	MhColor mixin extends a component with some useful color functions

	2019-06-12	init

*/
import EventBus from '@/helper/EventBus.js'
const Color = require('color');

export default {
	name: 'MhColor',
	watch: {},
	data () {
		return {
			mhColor : {
				debugLog : false,
				Color : Color,
				minContrastOnWhite : 2,
				minContrastOnBlack : 3.5,
				black : '#262626',
				white : '#ffffff',
			},
		}
	},
	computed: {},
	methods: {
		getVibrantColor( baseColor ) {

			const Color = this.mhColor.Color
			const minContrastOnBlack = this.mhColor.minContrastOnBlack
			const minContrastOnWhite = this.mhColor.minContrastOnWhite
			const debugLog = this.mhColor.debugLog
			const black = this.mhColor.black
			const white = this.mhColor.white
			const contrastOnBlack = this._.round( Color( baseColor ).contrast( Color( black ) ), 2 )
			const contrastOnWhite = this._.round( Color( baseColor ).contrast( Color( white ) ), 2 )

			let colorOnBlack = null
			let colorOnWhite = null
			let returnColor = null

			if( debugLog ) console.log('')
			if( debugLog ) console.group('getVibrantColor')
			if( debugLog ) console.log('baseColor:', baseColor)
			if( debugLog ) console.log('minContrastOnWhite:', minContrastOnWhite)
			if( debugLog ) console.log('minContrastOnBlack:', minContrastOnBlack)
			if( debugLog ) console.log('white:', white)
			if( debugLog ) console.log('black:', black)
			if( debugLog ) console.log('')
			if( debugLog ) console.log('contrastOnWhite:', contrastOnWhite, contrastOnWhite < minContrastOnWhite ? '❌' : '✅')
			if( debugLog ) console.log('contrastOnBlack:', contrastOnBlack, contrastOnBlack < minContrastOnBlack ? '❌' : '✅')

			if( contrastOnWhite < minContrastOnWhite ){

				if( debugLog ) console.log('')
				if( debugLog ) console.group('contrastOnWhite too low > darken')

				for( let i = 1; i < 30; i++ ) {
					let step = i/20
					let newColor = Color( baseColor ).darken( step ).hex()
					let newContrast = this._.round( Color( newColor ).contrast( Color( white ) ), 2 )

					if( newContrast >= minContrastOnWhite && !returnColor ){
						returnColor = newColor
						i = 100 // break loop
						if( debugLog ) console.log( step + ' > ' + newColor + ' > ' + newContrast + ' ✅ ')
					}else{
						if( debugLog ) console.log( step + ' > ' + newColor + ' > ' + newContrast )
					}

				}

				if( debugLog ) console.groupEnd('contrastOnWhite too low > darken')

			}
			else if( contrastOnBlack < minContrastOnBlack ){

				if( debugLog ) console.log('')
				if( debugLog ) console.group('contrastOnBlack too low > lighten')

				for( let i = 1; i < 30; i++ ) {
					let step = i/20
					let newColor = Color( baseColor ).lighten( step ).hex()
					let newContrast = this._.round( Color( newColor ).contrast( Color( black ) ), 2 )

					if( newContrast >= minContrastOnBlack && !returnColor ){
						returnColor = newColor
						i = 100 // break loop
						if( debugLog ) console.log( step + ' > ' + newColor + ' > ' + newContrast + ' ✅ ')
					}else{
						if( debugLog ) console.log( step + ' > ' + newColor + ' > ' + newContrast )
					}

				}

				if( debugLog ) console.groupEnd('contrastOnBlack too low > lighten')

			}else{

				returnColor = baseColor

			}

			if( debugLog ) console.groupEnd('getVibrantColor')

			return returnColor
		},
	},
	beforeCreate() {},
	created() {},
	mounted() {},
}
