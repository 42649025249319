<!--

	Was macht diese Componente?
	Wofür ist die Compoente da?

	Welche $props gibt es?

	Beispiel Code:
		<LuGallery
			:backgroundSize="'contain'"
    		:ratio="'16to9'"
    		:gallery="[{}, {}, {}]"
			:lineAbove="false"
			:isProductSlider="false"
			:hideCaptions="false"
		></LuGallery>

	2019-06-08	init

-->

<template>
	<div class="LuGallery contentModule" :class="elmClasses" v-if="gallery">
		<div class="LuGallery__inner vSpace vSpace--LuGallery" :class="innerClasses">
			<VueperSlides
				class="vueperSlides"
				:class="vueperSlidesClasses"
				:infinite="false"
				:disableArrowsOnEdges="true"
				:transitionSpeed="250"
				:touchable="true"
				:slideRatio="sliderRatio"
				:draggingDistance="70"
				:bullets="true"
				@ready="onReady"
				@beforeSlide="onBeforeSlide"
				@slide="onSlide"
				>
				<VueperSlide v-for="(image, i) in gallery" :key="i" :title="image.caption">
					<template v-if="isProductSlider">
						<MhImage
							:class="{'shadow shadow--productImage' : i == 0, 'background background--black' : i > 0}"
							:imageObject="image"
							:savePixel="10"
							:mode="i == 0 ? 'contain' : 'contain'"
							:position="i == 0 ? 'left center' : 'center'"
							slot="slideContent"
						></MhImage>
					</template>
					<template v-else>
						<MhImage
							:imageObject="image"
							:savePixel="10"
							:mode="backgroundSize == 'cover' ? 'cover' :'contain'"
							:position="'left center'"
							slot="slideContent"
						></MhImage>
					</template>
				</VueperSlide>
			</VueperSlides>
			<div class="LuGallery__captionHolder" v-if="!hideCaptions">
				<div class="font font--sizeSmall">{{currentCaption}}</div>
			</div>
			<!--
			<div class="LuGallery__bullets vueperSlides__bullets">
				<a class="vueperSlides__bullet XXvueperSlides__bullet--active"
				   :class="{'vueperSlides__bullet--active' : currentIndex === i}"
				   v-for="(image, i) in gallery" :key="i">
					<span>{{i}}</span>
				</a>
			</div>
			-->
		</div>
	</div>
</template>

<script>
	// @ is an alias to /src
	//import DevInfos from '@/components/DevInfos.vue'
	import EventBus from '@/helper/EventBus.js'
	import MhImage from '@/components/MhImage/MhImage.vue'
	import VueperSlides from '@/components/VueperSlides/VueperSlides.vue'
	import VueperSlide from '@/components/VueperSlides/VueperSlide.vue'

	export default {
		name: 'LuGallery',
		components: {
			VueperSlides,
			VueperSlide,
			MhImage,
		},
		mixins: [],
		props: {
			gallery: [Array, Boolean],
			backgroundSize: [String],
			ratio: [String],
			lineAbove: [Boolean],
			isProductSlider: [Boolean],
			hideCaptions: [Boolean],
		},
		data() {
			return {
				currentCaption : '',
				currentIndex : null,
			}
		},
		watch: {},
		computed: {
			app() {
				return this.$root.$children[0]
			},
			elmClasses(){
				let classes = []

				return classes
			},
			innerClasses(){
				let classes = []

				if( this.lineAbove ){
					classes.push('border')
					classes.push('border--top')
				}

				return classes
			},
			vueperSlidesClasses(){
				let classes = []

				if( this.isProductSlider ){
					classes.push( 'vueperSlides--asProductSlider' )
				}else{
					classes.push( 'vueperSlides--asContentModule' )
				}

				return classes
			},
			sliderRatio(){ // translate given string to float value, e.g. "16to10" >>> 0.625
				const givenRatio   = this.ratio
				const ratioParts   = givenRatio.split('to')
				const firstNumber  = parseFloat(ratioParts[0])
				const secondNumber = parseFloat(ratioParts[1])
				const ratio        = secondNumber / firstNumber

				/*
				console.log('givenRatio:', givenRatio)
				console.log('ratioParts:', ratioParts)
				console.log('ratio:', ratio)
				*/

				/*
				switch( givenRatio ) {
					case "2to1":
						ratio = 0.5
						break
					case "16to9":
						ratio = 0.56
						break
					case "16to10":
						ratio = 0.56
						break
					case "29to21":
						ratio = 0.71
						break
					case "1to1":
						ratio = 1
						break
					default:
						ratio = 0.56
				}
				*/

				return ratio
			},
		},
		methods: {
			onReady(e, payload){
				if( payload.currentSlide ){
					const currentIndex = payload.currentSlide.index
					const title = payload.currentSlide.title

					this.currentIndex = currentIndex
					this.currentCaption = title
				}
				/*
				console.log('');
				console.group('onReady()');
				console.log('payload:', payload)
				console.log('currentIndex:', currentIndex);
				console.log('title:', title);
				console.groupEnd();
				*/
			},
			onBeforeSlide(e, payload){},
			onSlide(e, payload){
				if( payload.currentSlide ){
					const currentIndex = payload.currentSlide.index
					const title = payload.currentSlide.title

					this.currentIndex = currentIndex
					this.currentCaption = title
				}
			},
		},
		created() {},
		mounted() {

		},
	}
</script>

<style lang="less">
	@import "../less/vars.less";
	@import "../less/mixins.less";

	.LuGallery {
		position: relative;

		.vueperSlides {

			@ui :{
				transition-duration: 0.2s;
			}
			@arrow : {
				height: 2em;
				padding: 1em;
				offset: 0.75em; // pfeil zum rand verschieben
			}
			@bullet : {
				height: 0.6rem;
				padding: 1em 0.3em; // this defines the height of the bullets bars
				opacity: 0.2;
				activeOpacity: 0.65;
			}

			// images werden full span
			.mhImage,
			//.mhImage--mode-cover,
			//.mhImage--mode-contain
			{
				position: absolute;
				top: 0; bottom: 0;
				left: 0; right: 0;
			}

			// general slider ui styling
			&__arrows {}
			&__arrow {
				transition: opacity @ui[transition-duration] ease;

				position: absolute;
				top: 50%;
				transform: translateY(-50%);
				display: flex;
				padding: @arrow[padding];
				width: auto;
				background-color: transparent;
				border: none;
				opacity: 0;

				svg {
					display: block;
					height: @arrow[height];
					width: @arrow[height];

					path {
						stroke: currentColor;
						stroke-width: 1px
					}
				}

				.vueperSlides:hover & {
					opacity: 0.5;
				}
				&:hover {
					opacity: 1 !important;
				}

				&--prev {
					left: 0;

					svg {
						margin-left: -@arrow[offset];
					}
				}
				&--next {
					right: 0;

					svg {
						margin-right: -@arrow[offset];
					}

				}
			}
			&__bullets {
				//background-color: red;
			}
			&__bullet {
				padding: @bullet[padding];
				background-color: transparent;
				border: none;

				span {
					transition: opacity @ui[transition-duration] ease;
					overflow: hidden;
					display: inline-block;
					background-color: currentColor;
					text-indent: -100em;
					width: @bullet[height];
					height: @bullet[height];
					border-radius: 50%;
					opacity: @bullet[opacity];
					cursor: inherit;
				}
				&--active span {
					opacity: @bullet[activeOpacity];
				}
				&:hover span {
					opacity: 0.75;
				}
			}


			// context specific
			&--asContentModule {
				.vueperSlides__bullets {
					color: @swatches[awardColor];
				}
			}
			&--asProductSlider {
				// um den schlagschatten des covers sichtbar zu machen,
				// habe ich die overflow eigenschaften angepasst
				overflow: hidden;
				.vueperSlides__parallax-wrapper,
				.vueperSlides__track {
					overflow: visible;
				}

				// das cover wird etwas kleiner dargestellt,
				// damit es nicht gedrängt wirkt und der schatten
				// zur geltung kommt
				.vueperSlide:first-child .mhImage{
					bottom: 1em;
				}

				// für den platz nach unten und das es raum für
				// den cover-schatten gibt. dieser würde ansonsten vom
				// overflow abgeschnitten. das ist allerdings nur
				// notwendig wenn es nur ein bild gibt. Dann erscheinen
				// nämlich die bullets nicht (diese würde ansonsten
				// das abschneiden des schattens verhindern)
				&.vueperSlides--slidesCount-1 {
					padding-bottom: 1.5rem;
				}

			}
		}
		.vueperSlide {
			&__title {
				display: none
			}
		}
	}
	.LuGallery__captionHolder {
		margin-top: -0.25em;
		opacity: 0.5;
		//padding-top: 1.25em;

		// if there is just one image then there are no bullets
		// for the spacing thats why we some spacing here
		.vueperSlides--slidesCount-1 + & {
			padding-top: 1.25em;
		}
	}
	.LuGallery__bullets {}



	@media @mediaQuery__xs {}
	@media @mediaQuery__sm {}
	@media @mediaQuery__md {}
	@media @mediaQuery__dt {}
	@media @mediaQuery__lg {}
	@media @mediaQuery__xl {}
</style>
