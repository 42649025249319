<template>
	<div class="LubokHomeView view" v-if="viewIsReady">
		<LuMobileHeader
			class="app__mobileNav"
			v-if="$root.isSmallDevice"
		></LuMobileHeader>

		<div class="minHeight minHeight--100">

			<div class="grid grid--pageTemplateForHome vSpace vSpace--page hSpace hSpace--page maxWidth maxWidth--page">
				<!-- header: pageTitle -->
				<div>
					<LuPageHeader
						:title="$root.getTranslatedAcfValue(post.acf, 'postTitle')"
						v-if="post"
					></LuPageHeader>
				</div>
				<!-- content: contentModules -->
				<div class="hSpace hSpace--gapEnlargeRight">
					<div class="border border--top border--strong"></div>

					<div
						v-for="(contentModule, index) in $root.getContentModules( post )"
						:key="index"
						v-bind="contentModule"
						:is="'Lu' + _.upperFirst(contentModule.acf_fc_layout)"
					></div>

					<LuSpacer
						:lines="3"
					></LuSpacer>
			</div>
				<!-- aside: events widget -->
				<div>
					<LuEventsWidget
						:events="currentEvents"
						v-if="post && post.acf && post.acf.showEvents"
					></LuEventsWidget>

					<!--
					<LuSpacer
						:lines="3"
						v-if="!$root.isSmallDevice"
					></LuSpacer>
					-->

					<LuNewsletterWidget />

					<LuSpacer
						:lines="3"
					></LuSpacer>

				</div>
				<!-- footer: featured products -->
				<div>
				</div>
			</div>

		</div>

		<div class="vSpace vSpace--page hSpace hSpace--page maxWidth maxWidth--page">
			<div class="background background--productTeaser hSpace hSpace--productTeasers color color--white">
				<div class="vSpace vSpace--pageHeaderTitle"><br /></div>
				<span class="vSpace vSpace--productTextInfos border border--top border--strong font font--bold">
					{{ $t('title.featuredProducts') }}
				</span>
			</div>
			<div class="background background--productTeaser vSpace vSpace--productTeasers hSpace hSpace--productTeasers">
				<LuProductTeasers
					:products="featuredProducts"
				></LuProductTeasers>
			</div>
		</div>

		<!--
		<pre v-if="post && post.acf && post.acf.featuredProducts"  data-name="post.acf.featuredProducts">{{post.acf.featuredProducts}}</pre>
		<pre data-name="featuredProducts">{{featuredProducts}}</pre>
		<pre data-name="currentEvents">{{currentEvents}}</pre>
		<pre data-name="post">{{post}}</pre>
		-->
	</div>
</template>

<script>
	// @ is an alias to /src
	import EventBus from '@/helper/EventBus.js'
	import restHandler from '@/mixins/restHandler/v1/restHandler.mixin.js'
	import ViewMixin from './View.mixin.js'

	import MhSmartHeader from '@/components/MhSmartHeader/MhSmartHeader.vue'
	import MhDevGrid from '@/components/MhDevGrid/MhDevGrid.vue'
	import LuMobileHeader from '@/components/LuMobileHeader.vue'
	import LuPageHeader from '@/components/LuPageHeader.vue'
	import LuHeadline from '@/components/LuHeadline.vue'
	import LuText from '@/components/LuText.vue'
	import LuGallery from '@/components/LuGallery.vue'
	import LuEventsWidget from '@/components/LuEventsWidget.vue'
	import LuNewsletterWidget from '@/components/LuNewsletterWidget.vue'
	import LuProductTeasers from '@/components/LuProductTeasers.vue'
	import LuSpacer from '@/components/LuSpacer.vue'

	export default {
		name: 'LuHomeView',
		components: {
			MhSmartHeader,
			MhDevGrid,
			LuMobileHeader,
			LuPageHeader,
			LuHeadline,
			LuText,
			LuGallery,
			LuEventsWidget,
			LuNewsletterWidget,
			LuProductTeasers,
			LuSpacer,
		},
		mixins: [
			restHandler,
			ViewMixin,
		],
		props: {},
		data() {
			return {
				post             : {},
				currentEvents    : [],
				featuredProducts : [],
			}
		},
		watch: {
			'post.acf.showEvents'( to, from ) {
				if( to ) this.doEventsFetch()
			},
			'post.acf.showFeaturedProducts'( to, from ) {
				if( to ) this.doProductsFetch()
			},
		},
		computed: {},
		methods: {
			doStartFetch() {
				// send loading to MhView to show the spinner
				EventBus.$emit('stateChanged', 'loading')

				// fetch page home
				this.restHandler__fetch({
					action : 'GET',
					route : '/wp-json/mh/v1/posts/',
					params : {
						slug : 'home',
					},
					callback : (response) => {
						const data  = response.data
						const posts = data.result

						this.post = posts[0]
						this.viewIsReady = true // this comes from View.mixin.js

						// send loaded to MhView to hide the spinner again
						EventBus.$emit('stateChanged', 'loaded')
					},
				})
			},
			doEventsFetch(){
				// send loading to MhView to show the spinner
				//EventBus.$emit('stateChanged', 'loading')

				// fetch current events
				this.restHandler__fetch({
					action : 'GET',
					route : '/wp-json/mh/v1/lubok__events',
					params : {
						range : 'nowAndFuture'
					},
					callback : (response) => {
						const results = response.data.result

						this._.forEach( results, (post) => {
							if( post.acf.showOnHome ){
								this.currentEvents.push( post )
							}
						})

						// send loaded to MhView to hide the spinner again
						//EventBus.$emit('stateChanged', 'loaded')
					},
				})
			},
			doProductsFetch() {
				// send loading to MhView to show the spinner
				//EventBus.$emit('stateChanged', 'loading')

				// fetch pages as example
				this.restHandler__fetch({
					action : 'GET',
					route : '/wp-json/mh/v1/lubok__products',
					params : {
						postIds : this.post.acf.featuredProducts
					},
					callback : (response) => {
						this.featuredProducts = response.data.result

						// send loaded to MhView to hide the spinner again
						//EventBus.$emit('stateChanged', 'loaded')
					},
				})
			},
		},
		created() {},
		mounted() {
			this.doStartFetch()

			this.title = this.$t('nav.home')
		},
	}
</script>

<style lang="less">
</style>
