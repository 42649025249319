<!--

	Was macht diese Componente?
	Wofür ist die Compoente da?

	Welche $props gibt es?

	Beispiel Code:
		<LuText
			:text="'Hello World'"
			:fontSize="'default'"
			:lineAbove="false"
			:textOnBlack="false"
		></LuText>

		<LuText
			:fontSize="default"
			:lineAbove="false"
			:textOnBlack="false">

			Hello World :)

		</LuText>

	2019-06-08	init
-->

<template>
	<div class="LuText contentModule" :class="elmClasses">
		<div class="LuText__inner maxWidth maxWidth--text vSpace vSpace--LuText" :class="innerClasses">
			<template v-if="text">
				<div v-html="text"></div>
			</template>
			<template v-else>
				<slot></slot>
			</template>
		</div>
	</div>
</template>

<script>
	// @ is an alias to /src
	//import DevInfos from '@/components/DevInfos.vue'
	import EventBus from '@/helper/EventBus.js'

	export default {
		name: 'LuText',
		components: {},
		mixins: [],
		props: {
			text: [String, Boolean],
			fontSize: [String, Boolean],
			lineAbove: [Boolean],
			textOnBlack: [Boolean],
		},
		data() {
			return {
			}
		},
		watch: {},
		computed: {
			app() {
				return this.$root.$children[0]
			},
			elmClasses(){
				let classes = []

				return classes
			},
			innerClasses(){
				let classes = []

				if( this.textOnBlack ){
					classes.push('LuText__inner--textOnBlack' )
				}else{
					classes.push('LuText__inner--textOnWhite' )
				}

				if( this.lineAbove ){
					classes.push('border border--top' )
				}

				if( this.fontSize == 'default' ){
					classes.push('font font--sizeMedium' )
				}else if( this.fontSize == 'huge' ){
					classes.push('font font--sizeHuge')
					classes.push('font font--bold')
					classes.push('hyphenate')
				}else if( this.fontSize ){
					classes.push('font font--size' + this._.upperFirst( this.fontSize ) )
				}

				return classes
			},
		},
		methods: {},
		created() {},
		mounted() {},
	}
</script>

<style lang="less">
	@import "../less/vars.less";
	@import "../less/mixins.less";

	.LuText {
		position: relative;
	}

	@media @mediaQuery__xs {}
	@media @mediaQuery__sm {}
	@media @mediaQuery__md {}
	@media @mediaQuery__dt {}
	@media @mediaQuery__lg {}
	@media @mediaQuery__xl {}
</style>
