<!--

	<LuMainHeader></LuMainHeader>

-->

<template>
	<nav class="LuMainHeader contentModule">
		<div class="LuMainHeader__inner grid grid--LuMainHeader">

			<h1 class="LuMainHeader__logo">
				<router-link 	class="LuMainHeader__logoHomeLink"
								:to="$root.homeUrl">
					Lubok
				</router-link>
			</h1>

			<!--
			<div class="todo">
				<hr />
				<div v-for="(stateArr, stateName) in $store.state" :key="stateName">
					{{stateName}}
					<div v-for="(stateArr2, stateName2) in $store.state[stateName]" :key="stateName2">
						• {{stateName2}} – {{$store.state[stateName][stateName2].length}}
					</div>
					<hr />
				</div>
			</div>
			-->

			<ul class="LuMainHeader__ul">
				<!-- Suchen -->
				<li class="LuMainHeader__li LuMainHeader__row vSpace vSpace--navRow border border--top border--strong">
					<MhSearchForm 	class="font font--sizeMedium"
									:placeholder="$t('placeholder.search')">
					</MhSearchForm>
				</li>
				<!-- Verlag -->
				<li class="LuMainHeader__li LuMainHeader__row vSpace vSpace--navRow border border--top border--strong ">
					<router-link  	class="LuMainHeader__a LuMainHeader__a--first font font--sizeMedium font--bold"
									:to="'/'+$root.currentLang+'/publisher/'">
						{{ $t('nav.publisher') }}
					</router-link>
				</li>
				<!-- Termine -->
				<li class="LuMainHeader__li LuMainHeader__row vSpace vSpace--navRow border border--top border--strong">
					<router-link 	class="LuMainHeader__a font font--sizeMedium font--bold"
								 	:to="'/'+$root.currentLang+'/events/'"
								 	@click.native="toggleNavList('events', true)">
						{{ $t('nav.events') }}
					</router-link>
					<button	class="LuMainHeader__listToggleBtn icon icon--triangle reset reset--button rotate"
							:class="{'rotate--180' : navListIsOpen__events }"
							@click="toggleNavList('events')">
							Toggle
					</button>
				</li>
				<ul class="LuMainHeader__ul LuMainHeader__ul--second" v-show-slide="navListIsOpen__events">
					<li class="LuMainHeader__li LuMainHeader__li--second LuMainHeader__row vSpace vSpace--navRow border border--top">
						<router-link 	class="LuMainHeader__a LuMainHeader__a--second font font--sizeMedium font--bold"
										:to="'/'+$root.currentLang+'/events/'">
							{{ $t('nav.currentEvents') }}
						</router-link>
					</li>
					<li class="LuMainHeader__li LuMainHeader__li--second LuMainHeader__row vSpace vSpace--navRow border border--top">
						<router-link 	class="LuMainHeader__a LuMainHeader__a--second font font--sizeMedium font--bold"
										:to="'/'+$root.currentLang+'/archive/'">
							{{ $t('nav.pastEvents') }}
						</router-link>
					</li>
				</ul>
				<!-- just some urls for developing -->
				<template v-if="
					$root.hostname == 'localhostXX' ||
					$root.hostname == 'lubok.local'">
					<li class="LuMainHeader__li LuMainHeader__row vSpace vSpace--navRow border border--top border--strong">
							<router-link 	class="LuMainHeader__a font font--sizeMedium font--bold"
							:to="'/de/shop/volkskunst-fabrik/'">
							Volkskunst
						</router-link>
					</li>
					<li class="LuMainHeader__li LuMainHeader__row vSpace vSpace--navRow border border--top border--strong">
							<router-link 	class="LuMainHeader__a font font--sizeMedium font--bold"
							:to="'/de/search/ein/'">
							Suche nach "ein"
						</router-link>
					</li>
					<li class="LuMainHeader__li LuMainHeader__row vSpace vSpace--navRow border border--top border--strong">
							<router-link 	class="LuMainHeader__a font font--sizeMedium font--bold"
							:to="'/de/events/erstes-event/'">
							Marios Geburststag
						</router-link>
					</li>
				</template>

				<!-- Shop -->
				<li class="LuMainHeader__li LuMainHeader__row vSpace vSpace--navRow border border--top border--strong">
					<router-link 	class="LuMainHeader__a font font--sizeMedium font--bold"
									:to="'/'+$root.currentLang+'/shop/'"
									@click.native="toggleNavList('shop', true)">
						{{ $t('nav.shop') }}
					</router-link>
					<button	class="LuMainHeader__listToggleBtn reset reset--button rotate icon icon--triangle"
							:class="{'rotate--180' : navListIsOpen__shop }"
							@click="toggleNavList('shop')">
							Toggle
					</button>
				</li>
				<ul class="LuMainHeader__ul LuMainHeader__ul--second" v-show-slide="navListIsOpen__shop">
					<li class="LuMainHeader__li LuMainHeader__li--second LuMainHeader__row vSpace vSpace--navRow border border--top">
						<router-link 	class="LuMainHeader__a LuMainHeader__a--second font font--sizeMedium font--bold"
										:to="'/'+$root.currentLang+'/shop/'">
							{{ $t('nav.all') }} <span class="LuMainHeader__countProducts">{{app.countTotalProducts}}</span>
						</router-link>
					</li>
					<!-- Shop Filters -->
					<div v-show-slide="app.productFilters.length">
						<li class="LuMainHeader__li LuMainHeader__li--second LuMainHeader__row vSpace vSpace--navRow border border--top"
							v-for="productFilter in app.productFilters" :key="productFilter.id">
							<router-link 	class="LuMainHeader__a LuMainHeader__a--second font font--sizeMedium font--bold"
											:to="'/'+$root.currentLang+'/shop/?filter=' + productFilter.slug">
								{{productFilter.title.rendered}}
								<span class="LuMainHeader__countProducts">{{productFilter.countProducts}}</span>
							</router-link>
						</li>
					</div>
				</ul>
				<!-- Warenkorb -->
				<li class="LuMainHeader__li LuMainHeader__row vSpace vSpace--navRow border border--top border--strong">
					<router-link 	class="LuMainHeader__a LuMainHeader__a--first font font--sizeMedium font--bold"
									:to="'/'+$root.currentLang+'/cart/'">
						{{ $t('nav.cart') }} (<span class="font font--mono">{{$store.getters.countItemsInCart}}</span>)
					</router-link>
				</li>
				<!-- English/Deutsch -->
				<li class="LuMainHeader__li LuMainHeader__row vSpace vSpace--navRow border border--top border--strong">
					<router-link 	class="LuMainHeader__a LuMainHeader__a--first font font--sizeMedium font--bold routerLink"
									:to="$root.toggleLangUrl">
						<template v-if="$root.currentLang == 'de'">English</template>
						<template v-if="$root.currentLang == 'en'">Deutsch</template>
					</router-link>
				</li>
				<!-- Impressum + Datenschutz -->
				<li class="LuMainHeader__li LuMainHeader__row vSpace vSpace--navFooterRow border border--top border--strong font--sizeMini font--bold">
					<div class="LuMainHeader__footerLinks font font--sizeSmall">
						<router-link class="LuMainHeader__a LuMainHeader__a--footerLink"
									 :to="'/'+$root.currentLang+'/imprint/'">
							{{ $t('nav.imprint') }}
						</router-link>
						<router-link class="LuMainHeader__a LuMainHeader__a--footerLink"
									 :to="'/'+$root.currentLang+'/gtb/'">
							{{ $t('nav.gtb') }}
						</router-link>
						<router-link class="LuMainHeader__a LuMainHeader__a--footerLink"
									 :to="'/'+$root.currentLang+'/privacy/'">
							{{ $t('nav.privacy') }}
						</router-link>
					</div>
				</li>
			</ul>

			<!-- <div class="LuMainHeader__overlay"></div> -->

		</div>
	</nav>
</template>

<script>
	// @ is an alias to /src
	import MhSearchForm from '@/components/MhSearchForm/MhSearchForm.vue'
	import EventBus from '@/helper/EventBus.js'

	export default {
		name: 'LuMainHeader',
		components: {
			MhSearchForm,
		},
		props: {},
		data() {
			return {
				navListIsOpen__shop   : false,
				navListIsOpen__events : false,
				vibrantColor          : null,
			}
		},
		watch: {},
		computed: {
			app(){
				return this.$root.$children[0]
			},
		},
		methods: {
			toggleNavList( listName, newState = undefined ){
				let listState = this['navListIsOpen__' + listName]

				if( this._.isUndefined(newState) ){
					this['navListIsOpen__' + listName] = !this['navListIsOpen__' + listName]
				}else{
					this['navListIsOpen__' + listName] = newState
				}
			},
		},
		created() {},
		mounted() {
			// set color if product is loaded
			/*
			EventBus.$on('vibrantColorChanged', vibrantColor => {
				this.vibrantColor = vibrantColor
			})
			*/

			// open subnav lists depending on current route
			this.$router.afterEach( (to, from) => {
				const newRouteName = to.name

				//console.log('newRouteName:', newRouteName);
				//console.log('from:', from);
				//console.log('to:', to);
				if( newRouteName == 'ShopView' ) this.toggleNavList( 'shop', true )
				if( newRouteName == 'FilteredShopView' ) this.toggleNavList( 'shop', true )
				if( newRouteName == 'EventsView' ) this.toggleNavList( 'events', true )
				if( newRouteName == 'EventsArchiveView' ) this.toggleNavList( 'events', true )
			})

			// reset vibrantColor if no ProductView
			this.$router.afterEach( (to, from) => {
				const newRouteName = to.name

				if( newRouteName != 'ProductView' ){
					setTimeout( () => {
						this.vibrantColor = null
					}, 150 )
				}
			})
		},
	}
</script>

<style lang="less">
	@import "../less/vars.less";
	@import "../less/mixins.less";
	@import (reference) "../less/atoms.less";

	.LuMainHeader {
		position: relative;
		display: flex;
		flex-direction: column;
		line-height: 1em;

		&__inner {
			padding: 0.7rem;
			padding-bottom: 1rem;

			display: flex;
			flex-grow: 1;
			flex-direction: column;
			justify-content: space-between;

			//transition: color 0.1s ease; // smooth change of the vibrantColor for products
		}
		&__inner::after { // border right
			@gap : 0.25rem;
			@color : fade( black, 30 );

			position: absolute;
			top: @gap; bottom: @gap;
			right: -1px;
			width: 1px;
			background-color: currentColor;
			content: "";
		}

		&__logo {
			position: relative;
			display: flex;

			border: 1em solid transparent;
			border-left-width: 2.5em;
			border-right-width: 2.5em;
			border-top-width: 1em;

			//font-size: 0em;
			//line-height: 0;

			//background-image: url(./assets/imgs/lubokLogo.svg);
			background-position: center;
			background-size: contain;
			background-repeat: no-repeat;

			//background-color: currentColor;
			//mask: url(./assets/imgs/lubokLogo.svg) no-repeat 50% 50%;
		}
		&__logoHomeLink {
			//position: absolute;
			//top: 0; left: 0; right: 0; bottom: 0;
			overflow: hidden;
			flex-grow: 1;
			padding-bottom: 60%;

			color: inherit;
			background-position: center;
			background-size: contain;
			background-repeat: no-repeat;
			background-color: currentColor;
			mask: url(./../assets/imgs/lubokLogo.svg) no-repeat 50% 50%;
			mask-size: contain;

			text-indent: -1000em;
		}

		&__row {
			display: flex;
		}
		&__li {
			list-style: none;
			display: flex;
		}
		&__footerLinks {
			display: flex;
			width: 100%;
			justify-content: space-between;
		}

		&__a {
			position: relative;
			display: flex;
			flex-grow: 1;
			text-decoration: none;
			align-items: center;
			color: inherit;

			// indent second level links
			&--second {
				padding-left: 1em;
			}

			// active indicator
			&--first::before {
				//background-color: fade(red, 60);
				transition: all 0.4s ease;

				position: absolute;
				left: -1em;
				//transform: scale(0);
				transform-origin: center;

				//opacity: 0;
				font-size: 1.25em;
				content: "•";
			}
			&--first.routerLink--exactActive::before {
				left: -0.7em;
				//opacity: 1;
			}
			&--second::before {
				transition: all 0.35s ease;

				position: absolute;
				left: 0;
				left: -2em;
				//transform: scale(0);
				transform-origin: center;

				//opacity: 0;
				font-size: 1.25em;
				content: "•";
			}
			&--second.routerLink--exactActive::before {
				//transform: scale(1);
				left: 0;
				//opacity: 1;
			}
		}
		&__a--footerLink {
			flex-grow: 0;
		}
		&__countProducts {	// hochgestellter counter hinter den filtern unter shop
			display: inline-block;
			padding-left: .5em;
			transform: translateY(-.25em);

			font-size: .7em;
			line-height: 1em;
			font-weight: 400;
		}
	}

	@media @mediaQuery__xs {}
	@media @mediaQuery__sm {}
	@media @mediaQuery__md {
		.LuMainHeader {
			.vSpace--page;
			.border--strong;
			filter: invert(100%);

			overflow: hidden;
			background-color: fade( red, 15 ) !important;
			//background-color: ;

			&__inner {
				//background-color: inherit !important;
				//background-color: none !important;
				padding: 0;
			}
			&__inner::after { // border aside
				.background--productTeaser !important;

				@offset: -1em;

				top: @offset; left: @offset; right: @offset; bottom: @offset;
				width: auto;
				border: none;
			}

			&__logo {
				color: white;
				z-index: 1;
				border-left-width: 1.5em;
				border-right-width: 1.5em;
				border-top-width: 0em;
				border-bottom-width: 0em;
			}
			&__ul {
				display: none;
			}
		}
	}
	@media @mediaQuery__dt {}
	@media @mediaQuery__lg {}
	@media @mediaQuery__xl {}
</style>
