export default {
	de : {
		placeholder : {
			search : 'Suchen',
		},
		nav : {
			home : 'Startseite',
			search : 'Suche',
			publisher : 'Verlag',
			events : 'Termine',
			currentEvents : 'Aktuelle',
			pastEvents : 'Vergangene',
			all : 'Alle',
			shop : 'Shop',
			cart : 'Warenkorb',
			
			imprint : 'Impressum',
			gtb : 'AGB',
			privacy : 'Datenschutz',
		},
		title : {
			event: 'Termin',
			currentEvents : 'Aktuelle<br />Termine',
			pastEvents : 'Vergangene<br />Termine',
			relatedProducts : 'Ähnliche Produkte',
			currentEventsWidget : 'Aktuelle Termine',
			searchResultsFor: 'Suchergebnisse für',
			searchResultsInShop: 'Ergebnisse im Shop',
			searchResultsInPages: 'Ergebnisse in Seiten',
			searchResultsInEvents: 'Ergebnisse in Termine',
			featuredProducts : 'Featured Products',
			cart: 'Warenkorb',
			product: 'Produkt',			
			products: 'Produkte',			
			shipping: 'Versand',			
			payment: 'Bezahlmethode',			
			finishOrder: 'Bestellung abschliessen',			
			shippingToGermany: 'Versand innerhalb Deutschlands',			
			shippingToEU: 'Versand innerhalb der EU',			
			shippingToWorldwide: 'Versand außerhalb der EU',						
			taxes: 'Mwst.',
			taxRate: 'Mwst.<br />Satz',
			priceInclTaxes: 'Einzelpreis<br />inkl. Mwst.',
			priceExclTaxes: 'Einzelpreis<br />exkl. Mwst.',
			number: 'Anzahl',
			sumInklTaxes: 'Summe<br />inkl. Mwst.',
			
			includedTaxesSum: 'enthaltene Mwst.',
			sumExclTaxes: 'Summe exkl. Mwst.',
			sumExclTaxes2: 'Summe<br />exkl. Mwst.',
			invoiceAmountInclVat: 'Rechnungsbetrag inkl. Mwst.',
			invoiceAmount: 'Rechnungsbetrag',
			taxFreeDelivery: 'Umsatzsteuerfreie Ausfuhrlieferung',
			totalExclTaxes: 'Gesamt exkl. Mwst.',
			totalInclTaxes: 'Gesamt inkl. Mwst.',
			
			paymentWithInvoice: 'Kauf auf Rechnung',
			billingAddress: 'Rechnungsadresse',
			deliveryAddress: 'Lieferadresse',
			billingAndDeliveryAddresses: 'Rechnungs- und Lieferadresse',
			company : 'Unternehmen',
			name : 'Name',
			street : 'Straße',
			zipCode : 'PLZ',
			city : 'Stadt',
			country : 'Land',
			mail : 'Mail',
			phone : 'Telefon',
			message : 'Kommentar',
			deliveryAddressIsDifferent : 'Abweichende Lieferadresse',
			
			newsletterWidget : 'Newsletter abonnieren',
			
			orderSubmitted : 'Bestellung abgeschlossen',
		},
		button : {
			allEvents : 'Alle Termine',	// Termine-Widget auf der Startseite
			addToCart : 'In den Warenkorb',
			proceedToCheckout :	'Weiter zum Checkout →',
			backToCart : '← Zurück zum Warenkorb',
			backToProduct : '← Zurück zum Product',
			goToShop : '← Zum Shop',
			continueCheckout : 'Checkout fortsetzen →',
			back : '← Zurück',
			placeOrder : 'Kostenpflichtig bestellen',
			menu : 'Menü',
		},
		message : {
			chooseCountry: 'Wähle Land ...',
			forFree: 'Kostenlos',
			flatRate: 'Pauschal',
			flatRateInklVat: 'inkl. Mwst.',
			inclusiveTax: 'inkl. {number}% Mwst.',
			freeDelivery: 'zzgl. <a href="{shippingInfosLink}">Versandkosten</a>, <br /> Versand innerhalb DE kostenfrei',
			noSearchResults: 'Nichts gefunden',
			productIsOutShort: 'Leider vergriffen',
			productIsOut: 'Das Produkt ist leider vergriffen.',
			productAddedToCart: 'Das Produkt wurde zum <a href="/de/cart/">Warenkorb</a> hinzugefügt.',
			noItemsInCart: 'Es befinden sich noch keine Produkte im Warenkorb.',
			
			paymentWithInvoiceInfo: 'Die Ware wird zusammen mit der Rechnung versendet. Bitte begleichen Sie den Rechnungsbetrag erst nach Erhalt der Rechnung.',
			
			shippingToWorldwideInfo: 'Wir versenden weltweit! Sollte Ihr Land hier nicht aufgelistet sein, senden Sie bitte eine Email mit Ihrer Rechnungs- und Lieferanschrift sowie den gewünschten Produkten an <a class="link link--color" href="mailto:order@lubok.de">order@lubok.de</a>. Wir melden uns umgehend zurück!',
			shippingToWorldwideInfo2: 'Lieferungen ins Nicht-EU-Ausland sind umsatzsteuerbefreit. Die Zahlung der im Empfangsland geltenden Einfuhrumsatzsteuer und evtl. anfallende Zollgebühren muss vom Empfänger übernommen werden.',
			gtbAcceptedInfo: 'Hiermit bestätige ich, dass ich die <a class="link link--color" href="/de/gtb/">Allgemeinen Geschäftsbedingungen</a> gelesen, verstanden und akzeptiert habe.', // TODO:2280 add link
			ppAcceptedInfo: 'Hiermit bestätige ich, dass ich die <a class="link link--color" href="/de/privacy/">Datenschutzerklärung</a> gelesen, verstanden und akzeptiert habe.', // TODO:2290 add link
			                                                                                                                                                                         // newsletterAcceptedInfo: 'Newsletter-Abonnieren: Bitte halten Sie mich mit News aus dem Verlag auf dem Laufenden.',
			newsletterAcceptedInfo: 'Newsletter abonnieren: Ich möchte Informationen zu neuen Büchern, Künstlern und Events erhalten! <a class="link link--color" href="/de/privacy/">Datenschutz&shy;erklärung</a>',
			newsletterWidget: 'Ja, ich möchte Informationen zu neuen Büchern, Künstlern und Events erhalten! <a class="link link--underlined" href="/de/privacy/">Datenschutz&shy;erklärung</a>',
			newsletterWidgetSubmitMessage: 'Viele Dank, Ihre Mailadresse wurde erfolgreich übermittelt. Sie erhalten in Kürze eine Mail mit einem Bestätigungslink.',
			
			orderSubmitted : 'Vielen Dank. Ihre Bestellung wurde erfolgreich übermittelt.<br />Sie erhalten in Kürze eine Bestellbestätigung als Mail. Sollte sich die Mail nicht in Ihrem Posteingang befinden, überprüfen Sie bitte ihre Spamverdachts-Ordner. <br /><br />Bei allen Fragen rund um Ihre Bestellung können Sie uns gern jederzeit unter <a href="mailto:order@​lubok.​de" class="link link--color">order@​lubok.​de</a> kontaktieren.',
		}
	},
	en : {
		placeholder : {
			search : 'Search',
		},
		nav : {
			home : 'Home',
			search : 'Search',
			publisher : 'Publisher',
			events : 'Events',
			currentEvents : 'Current',
			pastEvents : 'Archive',
			all : 'All',
			shop : 'Shop',
			cart : 'Cart',
			
			imprint : 'Imprint',
			gtb : 'GTB',
			privacy : 'Privacy Policy',
		},	
		title : {
			event: 'Event',
			currentEvents : 'Current<br />events',
			pastEvents : 'Past<br />events',
			relatedProducts : 'Related Products',
			currentEventsWidget : 'Current events',
			searchResultsFor: 'Search results for',
			searchResultsInShop: 'Results in Shop',
			searchResultsInPages: 'Results in Pages',
			searchResultsInEvents: 'Results in Events',
			featuredProducts : 'Featured Products',
			cart: 'Cart',
			product: 'Product',
			products: 'Products',
			shipping: 'Shipping',
			payment: 'Payment method',
			finishOrder: 'Complete order',
			shippingToGermany: 'Shipping within Germany',
			shippingToEU: 'Shipping within the EU',			
			shippingToWorldwide: 'Shipping outside the EU',			
			taxes: 'VAT',
			taxRate: 'VAT<br />Rate',
			priceInclTaxes: 'Unit price<br />incl. VAT',
			priceExclTaxes: 'Unit price<br />excl. VAT',
			number: 'Number',
			sumInklTaxes: 'Total<br />incl. VAT',
			
			includedTaxesSum: 'VAT included',
			sumExclTaxes: 'Total excl. VAT',
			sumExclTaxes2: 'Total<br />excl. VAT',
			invoiceAmountInclVat: 'Invoice amount incl. VAT',
			invoiceAmount: 'Invoice amount',
			taxFreeDelivery: 'Export delivery exempt from VAT',
			totalExclTaxes: 'Total excl. VAT',
			totalInclTaxes: 'Total incl. VAT',
			
			paymentWithInvoice: 'Purchase on account',
			billingAddress: 'Billing Address',
			deliveryAddress: 'Delivery Address',
			billingAndDeliveryAddresses: 'Billing and Delivery Address',
			company : 'Company',
			name : 'Name',
			street : 'Street',
			zipCode : 'ZIP',
			city : 'City',
			country : 'Country',
			mail : 'Mail',
			phone : 'Phone',
			message : 'Comment',
			deliveryAddressIsDifferent : 'Differing delivery address',
			
			newsletterWidget : 'Subscribe to newsletter',
			
			orderSubmitted : 'Order completed',
		},	
		button : {
			allEvents : 'All events', // Termine-Widget auf der Startseite
			addToCart : 'Add to cart',
			proceedToCheckout :	'Proceed to checkout →',
			backToCart : '← Back to cart',
			backToProduct : '← Back to product',
			goToShop : '← Go to Shop',
			continueCheckout : 'Continue checkout →',
			back : '← Back',
			placeOrder : 'Place order with costs', 
			menu : 'Menu',
		},
		message : {
			chooseCountry: 'Choose country ...',
			forFree: 'free',
			flatRate: 'flat',
			flatRateInklVat: 'incl. VAT',
			inclusiveTax: 'incl. {number}% VAT',
			freeDelivery: 'plus <a href="{shippingInfosLink}"> shipping costs</a>, <br>free shipping within Germany',
			noSearchResults: 'Nothing found',
			productIsOutShort: 'Out of stock',
			productIsOut: 'Unfortunately this product is out of stock.',
			productAddedToCart: 'Product was added to <a href="/en/cart/">cart</a>.',
			noItemsInCart: 'Your shopping cart is empty.',
			
			paymentWithInvoiceInfo: 'The ordered products will be delivered together with the invoice. Please settle the invoice amount only upon receiving the invoice.',
			
			shippingToWorldwideInfo: 'We ship worldwide! For orders to countries that are not listed above please send an email to <a class="link link--color" href="mailto:order@lubok.de">order@lubok.de</a> with your invoicing and delivery address and the products you would like to order. We will get back to you right away!',
			shippingToWorldwideInfo2: 'Deliveries to non-EU countries are exempt from sales tax. The import sales tax valid in the country of receipt and any possible custom fees must be paid for by the recipient.',
			gtbAcceptedInfo: 'I hereby confirm that I have read, understood and accepted the <a class="link link--color" href="/en/gtb/">General Terms of Business</a>. ', // TODO:2300 add link
			ppAcceptedInfo: ' I hereby confirm that I have read, understood and accepted the <a class="link link--color" href="/en/privacy/">Privacy Policy</a>.',
			newsletterAcceptedInfo: 'Subscribe to newsletter: I want to receive information about new books, artists and events! <a class="link link--color" href="/en/privacy/">Privacy Policy</a>', // TODO:3500 ergänzen!
			newsletterWidget: 'Yes, I want to receive information about new books, artists and events! <a class="link link--underlined" href="/en/privacy/">Privacy Policy</a>',
			newsletterWidgetSubmitMessage: 'Thank you very much. Your email address has been submitted successfully. You will receive an email with a confirmation link shortly.',
			
			orderSubmitted: 'Thank you. Your order has been submitted successfully. You will receive an order confirmation via email shortly. If you can\'t find the email in your inbox please check your spam folder.<br /><br />If you have any questions regarding your order please feel free to write an email to <a href="mailto:order@​lubok.​de" class="link link--color">order@​lubok.​de</a> at any time.',
		}
	},
}
