/*

	Helper mixin extends a view component with some useful methods

	title : 'Home' 		// change this to set the title tag in header
	viewIsReady : false // set ths to true after view content is fetched

	2019-10-24	improvement: added metaInfo() vie vue-meta
	2019-09-28	init


*/
import EventBus from '@/helper/EventBus.js'

export default {
	metaInfo() {
		return {
			title: this.title,
			titleTemplate: this.titleTemplate,
			meta: [],
			link: [
				{
					rel: 'alternate',
					hreflang: this.$i18n.locale === 'de' ? 'en' : 'de',
					href: this.$i18n.locale === 'de' ? window.location.href.replace('/de/', '/en/') : window.location.href.replace('/en/', '/de/')
				},
			],
		}
	},
	watch: {
		viewIsReady ( to, from ) {
			if( to ) {
				EventBus.$emit('ViewEvent', {
					fromComponent : this.$options.name,
					eventName     : 'ready',
					vibrantColor  : this.vibrantColor, // wird nur vom LuProductView gesetzt
				})
			}
		},
	},
	data () {
		return {
			title : '',
			titleTemplate : (titleChunk) => {
				// re-replace ampersand '&#038;' back to '&'' in title
				const cleanTitleChunk = titleChunk.replace('&#038;', '&')
				// If undefined or blank then we don't need the hyphen
				return cleanTitleChunk ? `${cleanTitleChunk} – Lubok` : 'Lubok';
			},

			viewIsReady  : false,
			vibrantColor : false, // wird nur vom LuProductView gesetzt
		}
	},
	computed: {},
	methods: {},
	beforeCreate() {},
	created() {},
	beforeMount() {
		EventBus.$emit('ViewEvent', {
			fromComponent : this.$options.name,
			eventName     : 'beforeMount',
		})
	},
	mounted() {
		EventBus.$emit('ViewEvent', {
			fromComponent : this.$options.name,
			eventName     : 'mounted',
		})
	},
	beforeDestroy() {
		EventBus.$emit('ViewEvent', {
			fromComponent : this.$options.name,
			eventName     : 'beforeDestroy',
		})
	},
}
