<!--

	Was macht diese Componente?
	Wofür ist die Compoente da?

	Welche $props gibt es?

	Beispiel Code:
		<LuNewsletterWidget></LuNewsletterWidget>

	2019-07-05	init

-->

<template>
	<div class="LuNewsletterWidget contentModule" :class="elmClasses">
		<div class="LuNewsletterWidget__inner border border--top border--bottom border--strong">

			<h3 class="vSpace vSpace--LuNewsletterWidget font font--bold">
				<span v-html="$t('title.newsletterWidget')"></span>
				<br /><br />
			</h3>

			<div class="relative border border--top">

				<div class="vSpace vSpace--LuNewsletterWidget">
					<div class="font font--sizeSmall" :class="messageClasses" v-html="$t('message.newsletterWidget')"></div>
				</div>

				<form class="LuNewsletterWidget__form vSpace vSpace--LuNewsletterWidget">

					<div class="flex">

						<BaseInput
							class="BaseInput--newsletterAbo"
							:class="mailAddressInputClasses"
							type="text"
							spellcheck="false"
							@input="onInput"
							:value="mailAddress"
							:placeholder="'Mail'"
						></BaseInput>

						<BaseButton
							class="BaseButton--newsletterAbo"
							tag="a"
							label="OK"
							@click.native="onSubmit"
						></BaseButton>

					</div>

				</form>

				<div class="LuNewsletterWidget__submitMessage vSpace vSpace--LuNewsletterWidget"
				     :class="{'LuNewsletterWidget__submitMessage--isVisible' : showSubmitMessage}">
					<div class="font font--sizeSmall" v-text="$t('message.newsletterWidgetSubmitMessage')"></div>
				</div>

			</div>
		</div>
	</div>
</template>

<script>
	// @ is an alias to /src
	//import DevInfos from '@/components/DevInfos.vue'
	import EventBus from '@/helper/EventBus.js'
	import restHandler from '@/mixins/restHandler/v1/restHandler.mixin.js'
	import BaseInput from '@/components/Base/BaseInput.vue'
	import BaseButton from '@/components/Base/BaseButton.vue'

	export default {
		name: 'LuNewsletterWidget',
		components: {
			BaseInput,
			BaseButton,
		},
		mixins: [ restHandler ],
		props: {
			//imageObject: [Object, Boolean],
			//events: [Array, Boolean],
		},
		data() {
			return {
				mailAddress : '',
				showSubmitMessage : false,
			}
		},
		watch: {},
		computed: {
			app() {
				return this.$root.$children[0]
			},
			elmClasses() {
				let classes = []

				if( this.$root.isSmallDevice ) classes.push('font--sizeMedium')

				return classes
			},
			messageClasses() {
				let classes = []

				/*
				if( this.$root.isSmallDevice ){
					classes.push('font--sizeMedium')
				}
				*/

				return classes
			},
			mailAddressInputClasses() {
				let classes = []

				if( this.mailAddress && !this.$root.isValidEmailAddress( this.mailAddress ) ) classes.push('BaseInput--invalid')

				return classes
			},
			mailAddressIsValid() {
				return false
			}
		},
		methods: {
			onInput( mailAddress ){
				this.mailAddress = mailAddress
			},
			onSubmit(){
				//console.log('onSubmit');

				if( this.mailAddress && this.$root.isValidEmailAddress( this.mailAddress ) ){

					// fetch event by slug
					this.restHandler__fetch({
						//action : 'GET',
						action : 'POST',
						route : '/wp-json/mh/v1/lubok__newsletterSubscription',
						params : {
							mailAddress : this.mailAddress,
						},
						callback : (response) => {
							//const data  = response.data
							//console.log('response', response)

							this.showSubmitMessage = true
						},
					})

				}
			},
		},
		created() {},
		mounted() {},
	}
</script>

<style lang="less">
	@import "../less/vars.less";
	@import "../less/mixins.less";

	.LuNewsletterWidget {
		position: relative;

		&__form {
			//background-color: fade( red, 20 );
			//display: flex;
			margin-bottom: 0.35em;
		}
		&__submitMessage {
			position: absolute;
			top: 0; left: 0; width: 100%; height: 100%;
			background-color: white;
			opacity: 0;
			transition: all 0.2s ease;
			padding-right: 1em;
			pointer-events: none;
		}
		&__submitMessage--isVisible {
			opacity: 1;
			pointer-events: auto;
		}

		.BaseInput {
			flex-grow: 1;

			&__input { 	width: 100%; }
		}
		.BaseInput--invalid .BaseInput__input:not(:focus) {
			background-color: fade( red, 35 );
		}

		.LuEventsWidget + & {
			margin-top: -@border[strongWidth];
		}

	}

	@media @mediaQuery__xs {}
	@media @mediaQuery__sm {}
	@media @mediaQuery__md {}
	@media @mediaQuery__dt {}
	@media @mediaQuery__lg {}
	@media @mediaQuery__xl {}
</style>
