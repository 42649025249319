import _ from 'lodash'

const isValidEmailAddress = function( emailString ) {
	let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
	return re.test( String( emailString ).toLowerCase() )
}

export default {
	state: {
		cart : [],
		shipping : {
			regions : [],	// { name : 'YourRegion', shippingPrice : 0 }
			countries : [],	// { name : 'YourLand', shippingPrice : 3.23 }
		},
		customer : {
			shippingRegion : null, // e.g. germany | eu | world,
			shippingCountry : null,
			paymentOption : null, // e.g. PayPal | Invoice
			billingAddress : {
				company : '',
				name : '',
				street : '',
				zipCode : '',
				city : '',
				country : '',
				mail : '',
				phone : '',
				message : '',
			},
			deliveryAddress : {
				company : '',
				name : '',
				street : '',
				zipCode : '',
				city : '',
				country : '',
				mail : '',
				phone : '',
			},
			/*
			billingAddress : {
				company : 'In Kombination',
				name : 'Mario Helbing',
				street : 'Simildenstraße 30',
				zipCode : '04277',
				city : 'Leipzig',
				country : 'Germany',
				mail : 'helbing@in-kombination.de',
				phone : '0163 250 74 37',
				message : 'Marios Message',
			},
			deliveryAddress : {
				company : '',
				name : 'Franziska Weißgerber',
				street : 'Similde 30',
				zipCode : '04288',
				city : 'Leipzig Connewitz',
				country : 'Frankreich',
				mail : 'weissgerber@in-kombination.de',
				phone : '',
			},
			*/
			deliveryAddressIsDifferent : false,
			acceptedGTB : false,
			acceptedPP : false,
			acceptedNewsletter : false,
		},
		products : [],
	},
	getters: {
		bruttoSumOfAllProducts: state => {
			const cart     = state.cart
			const products = state.products
			let bruttoSum  = 0
			
			// walk the ids in cart and addiere all the bruttoSums to get the bruttoSum
			cart.forEach( id => {
				const product  = products.find( (post) => { return post.id == id })
				const priceEur = product ? parseFloat( product.acf.priceEur ) : null
				const taxRate  = product ? parseFloat( product.acf.taxRate ) : null
				
				if( product ){					
					bruttoSum = bruttoSum + priceEur
				}
			})
			
			return bruttoSum
		},
		nettoSumOfAllProducts: state => {
			const cart     = state.cart
			const products = state.products
			let nettoSum   = 0
			
			// walk the ids in cart and addiere all the nettoPrices to get the nettoSum
			cart.forEach( id => {
				const product  = products.find( (post) => { return post.id == id })
				const priceEur = product ? parseFloat( product.acf.priceEur ) : null
				const taxRate  = product ? parseFloat( product.acf.taxRate ) : null
				
				if( product ){
					const divider    = 1 + ( taxRate / 100 )
					const nettoPrice = _.round( priceEur / divider, 2 )
					const taxPrice   = _.round( priceEur - nettoPrice, 2 )
					
					nettoSum = nettoSum + nettoPrice
				}
			})
			
			return nettoSum
		},
		sumOfAllProductsTaxes: state => {
			const cart     = state.cart
			const products = state.products
			let taxesSum   = 0
			let debugLog   = false
			
			if( debugLog ) console.group('sumOfAllProductsTaxes')
			
			// walk the ids in cart and addiere all the taxPrices to get the taxesSum
			cart.forEach( id => {
				const product  = products.find( (post) => { return post.id == id })
				const priceEur = product ? parseFloat( product.acf.priceEur ) : null
				const taxRate  = product ? parseFloat( product.acf.taxRate ) : null
				
				if( product ){
					const divider    = 1 + ( taxRate / 100 )
					const nettoPrice = _.round( priceEur / divider, 2 )
					const taxPrice   = _.round( priceEur - nettoPrice, 2 )
					
					taxesSum = taxesSum + taxPrice
					
					if( debugLog ) {
						console.log('productId ', id)
						console.log('taxRate   ', taxRate)
						console.log('priceEur  ', priceEur)
						console.log('nettoPrice', nettoPrice)
						console.log('taxPrice  ', taxPrice)
						console.log('------')
					}
				}
			})	
			
			if( debugLog ) console.groupEnd('sumOfAllProductsTaxes')
			
			return taxesSum		
		},
		sumOfShippingTaxes: (state, getters) => {	
			const shippingPrice      = getters.shippingPrice
			const shippingTaxRate    = getters.shippingTaxRate
			
			const divider            = 1 + ( shippingTaxRate / 100 )
			const nettoShippingPrice = _.round( shippingPrice / divider, 2 )
			const shippingTaxPrice   = _.round( shippingPrice - nettoShippingPrice, 2 )
			let debugLog             = false
			
			if( debugLog ) {
				console.group('sumOfShippingTaxes')
				
				console.log('shippingPrice', shippingPrice)
				console.log('shippingTaxRate', shippingTaxRate)
				console.log('nettoShippingPrice', nettoShippingPrice)
				console.log('shippingTaxPrice', shippingTaxPrice)
				
				console.groupEnd('sumOfShippingTaxes')
			}
			
			return shippingTaxPrice
		},
		nettoShippingPrice: (state, getters) => {
			const shippingPrice = getters.shippingPrice
			const sumOfShippingTaxes = getters.sumOfShippingTaxes
			
			return shippingPrice - sumOfShippingTaxes
		},
		
		cartIsEmpty: state => {
			return state.cart.length < 1 ? true : false
		},
		countItemsInCart: state => {
			return state.cart.length
		},
		cart: state => {
			return state.cart
		},
		idsInCart: state => {
			const cart = state.cart
			const uniqueCart = cart.filter((v, i, a) => a.indexOf(v) === i)
			
			return uniqueCart
		},
		itemsInCart: state => {
			const cart = state.cart
			const products = state.products
			const uniqueCart = cart.filter((v, i, a) => a.indexOf(v) === i)
			let returnArray = []
			
			uniqueCart.forEach( (productId, index) => {
				const count = cart.filter( id => id == productId ).length
				const productObj = products.find( (post) => { return post.id == productId })
				
				// if there is already on post object, add count and push it
				if( productObj ){
					productObj.count = count
										
					returnArray.push( productObj )
				}
				// if there is no post object (yet) > push nothing
				else{
					/*
					returnArray.push({
						id : productId, 
						count : count,
					})
					*/
				}

			})
			
			return returnArray
		},
		countryNames: (state) => (filterArgs) => {
			const countries = _.filter( state.shipping.countries, filterArgs )
			const names     = _.map( countries, 'name' )
			
			names.sort()
			
			return names
		},
		paymentOption: (state) => {
			return state.customer.paymentOption
		},
		shippingCountry: state => {
			return state.customer.shippingCountry
		},
		shippingRegion: state => {
			return state.customer.shippingRegion
		},
		shippingPrice: (state, getters) => {
			const shippingRegion  = state.customer.shippingRegion
			const shippingCountry = state.customer.shippingCountry
			let price             = null
			
			if( shippingRegion == 'germany' ){
				const region = _.find( state.shipping.regions, { name : 'germany'} )
				price = region ? region.shippingPrice : 0
			} 
			if( shippingRegion == 'eu' ){
				const region = _.find( state.shipping.regions, { name : 'eu'} )
				price = region ? region.shippingPrice : 0
			}
			if( shippingRegion == 'world' ){				 
				 const region = _.find( state.shipping.countries, { name : shippingCountry} )
				 price = region ? region.shippingPrice : 0
			}
			
			return price
		},
		shippingRegionPrice: (state) => (filterArgs) => {
			const region        = _.filter( state.shipping.regions, filterArgs )[0]
			const shippingPrice = region ? region.shippingPrice : 0
			
			return shippingPrice
		},
		shippingCountryPrice: (state) => (filterArgs) => {
			const country       = _.filter( state.shipping.countries, filterArgs )[0]
			const shippingPrice = country ? country.shippingPrice : 0
			
			return shippingPrice
		},
		shippingTaxRate: (state, getters) => {
			//const billingAddress = state.customer.billingAddress
			const itemsInCart = getters.itemsInCart
			let   productTaxRates = []
			
			// collect all the taxRates 
			_.forEach( itemsInCart, (item) => {
				const taxRate = parseFloat( item.acf.taxRate )
				
				productTaxRates.push( taxRate )
			})
			
			//console.log('itemsInCart', itemsInCart)
			//console.log('productTaxRates', productTaxRates)
			
			// sobald 19% in den taxRates vorkommt ist der komplette 
			// steuersatz für den versand 19%, wenn nur 7% taxRates
			// im warenkorb sind, ist die versand taxRate auch 7%
			return _.includes( productTaxRates, 19 ) ? 19 : 7
		},
		billingAddress: (state) => {
			const billingAddress = state.customer.billingAddress
			
			return billingAddress
		},
		deliveryAddress: (state) => {
			const deliveryAddress = state.customer.deliveryAddress
			
			return deliveryAddress
		},
		deliveryAddressIsDifferent: (state) => {
			const deliveryAddressIsDifferent = state.customer.deliveryAddressIsDifferent
			
			return deliveryAddressIsDifferent
		},
		acceptedGTB: (state) => {
			const acceptedGTB = state.customer.acceptedGTB
			
			return acceptedGTB
		},
		acceptedPP: (state) => {
			const acceptedPP = state.customer.acceptedPP
			
			return acceptedPP
		},
		acceptedNewsletter: (state) => {
			const acceptedNewsletter = state.customer.acceptedNewsletter
			
			return acceptedNewsletter
		},
		stepIsValid: (state, getters) => (stepName) => {
			let isValid = false
			
			if( stepName == 'cart' ){
				if( !getters.cartIsEmpty ){
					isValid = true
				}
			}
			else if( stepName == 'shipping' ) {
				if( getters.shippingRegion == 'germany' ){
					isValid = true
				}
				else if ( getters.shippingRegion == 'eu' ){
					isValid = true
				}
				else if( getters.shippingRegion == 'world' && getters.shippingCountry ){
					isValid = true
				}
			}
			else if( stepName == 'payment' ) {
				if( getters.paymentOption == 'Invoice' ){
					isValid = true
				}
			}
			else if( stepName == 'customer' ) {
				const requiredBillingFields      = ['name', 'street', 'zipCode', 'city', 'country', 'mail']
				const requiredDeliveryFields     = ['name', 'street', 'zipCode', 'city', 'country']
				const deliveryAddressIsDifferent = getters.deliveryAddressIsDifferent
				
				// it starts with valid, will be negate inside the walks
				isValid = true
				
				// walk the billing fields end check if there is a value
				_.forEach( requiredBillingFields, entry => {
					let value = getters.billingAddress[entry]						
					if( !value || value == '' ) isValid = false
				})
				
				// if deliveryAddress is different:
				// walk the delivery fields end check if there is a value
				if( deliveryAddressIsDifferent ){
					_.forEach( requiredDeliveryFields, entry => {
						let value = getters.deliveryAddress[entry]						
						if( !value || value == '' ) isValid = false
					})
				}
				
				// check billing mail-address
				if( !isValidEmailAddress( getters.billingAddress.mail ) ){
					isValid = false
				}
			}
			else if( stepName == 'finishing' ) {
				if( getters.stepIsValid('cart') &&
				 	getters.stepIsValid('shipping') &&
				 	getters.stepIsValid('payment') &&
				 	getters.acceptedGTB &&
				 	getters.acceptedPP
				) isValid = true
			}
			
			return isValid	
		},
	},
	mutations: {
		emptyCart( state ) {
			state.cart = []
		},
		addToCart( state, id ) {
			state.cart.push( id )
		},
		removeFromCart( state, id ) {
			let products = state.products
			const lastIndexOfProduct = _.findLastIndex(products, (o) => { return o.id == id })
			
			products.splice( lastIndexOfProduct, 1 )
			
			state.cart = _.without(state.cart, id);
		},
		addToProducts( state, productObj ) {
			state.products.push( productObj );
		},
		changeNumberOf( state, productObj ) {
			const id = productObj.id
			const newNumber = parseInt( productObj.number )
			const cart = state.cart
			const products = state.products
			const currentNumber = cart.filter( cId => cId == id ).length
			const difference = newNumber - currentNumber
			/*
			console.group('changeNumberOf')
			console.log('id:', id)
			console.log('newNumber:', newNumber)
			console.log('currentNumber:', currentNumber)
			console.log('difference:', difference)
			console.log('')
			*/
			// add ids to cart, append to end
			if( difference > 0 ){
				for( let i = 0; i < difference; i++) {
					//console.log( i + ' add id' )
					cart.push( id )
				}
			}
			// remove ids from cart, remove from end
			if( difference < 0 ){				
				//console.log(cart);
				for( let i = 0; i < Math.abs( difference ); i++) {
					const lastIndexOfId = cart.lastIndexOf( id )
					
					if( lastIndexOfId > -1 ){
						//console.log( i + ' remove id, lastIndexOf:', lastIndexOf )
						cart.splice( lastIndexOfId, 1 )
					} 
				}
				
				// if newNumber is below 1: remove the product from products too
				if( newNumber < 1 ){
					const lastIndexOfProduct = _.findLastIndex(products, (o) => { return o.id == id })
					//console.log('lastIndex of ' + id + ' is:', lastIndexOfProduct)					
					products.splice( lastIndexOfProduct, 1 )
				}
			}
			
			//console.groupEnd('changeNumberOf')
		},
		changeShippingRegion( state, regionStr ) {
			const oldRegion = state.customer.shippingRegion
			const newRegion = regionStr
			/*
			console.log('')
			console.group('changeShippingRegion')
			console.log('oldRegion:', oldRegion)			
			console.log('newRegion:', newRegion)			
			console.groupEnd('changeShippingRegion')
			*/
			state.customer.shippingRegion = newRegion
		},
		changeShippingCountry( state, countryStr ){
			const oldShippingCountry = state.customer.shippingCountry
			const newShippingCountry = countryStr
			/*
			console.log('')
			console.group('changeShippingCountry')
			console.log('oldShippingCountry:', oldShippingCountry)			
			console.log('newShippingCountry:', newShippingCountry)			
			console.groupEnd('changeShippingCountry')
			*/
			state.customer.shippingCountry = newShippingCountry
		},
		addShippingCountry( state, countryObj ){
			/*
			console.log('')
			console.group('addShippingCountry')
			console.log('countryObj:', countryObj)			
			console.groupEnd('addShippingCountry')
			*/
			// wenn es das land schon gibt im array, wird es entfernt
			const alreadyThereIndex = _.findIndex( state.shipping.countries, (o) => { return o.name == countryObj.name })
			if( alreadyThereIndex > -1 ) state.shipping.countries.splice( alreadyThereIndex, 1 )
			
			state.shipping.countries.push( countryObj )
		},
		addShippingRegion( state, regionObj ){
			// wenn es das land schon gibt im array, wird es entfernt
			const alreadyThereIndex = _.findIndex( state.shipping.regions, (o) => { return o.name == regionObj.name })
			if( alreadyThereIndex > -1 ) state.shipping.regions.splice( alreadyThereIndex, 1 )
			
			state.shipping.regions.push( regionObj )
		},
		changePaymentOption( state, paymentStr ) {
			const oldPaymentStr = state.customer.paymentOption
			const newPaymentStr = paymentStr
			/*
			console.log('')
			console.group('changePaymentOption')
			console.log('oldPaymentStr:', oldPaymentStr)			
			console.log('newPaymentStr:', newPaymentStr)			
			console.groupEnd('changePaymentOption')
			*/
			state.customer.paymentOption = newPaymentStr
		},
		changeAddress( state, addressObj ){
			const type = addressObj.type
			const key = addressObj.key
			const value = _.trim( addressObj.value )
			/*
			console.log('')
			console.group('changeBillingAddress')
			console.log('addressObj:', addressObj)			
			console.log('type:', type)			
			console.log('key:', key)			
			console.log('value:', value)			
			console.groupEnd()
			*/
			state.customer[type][key] = value
		},
		changeDeliveryAddressIsDifferent( state, newBool ){
			const oldBool = state.customer.deliveryAddressIsDifferent
			/*
			console.log('')
			console.group('changeDeliveryAddressIsDifferent')
			console.log('oldBool:', oldBool)		
			console.log('newBool:', newBool)		
			console.groupEnd()
			*/
			state.customer.deliveryAddressIsDifferent = newBool
		},
		changeAcceptedGTB( state, newBool ){
			const oldBool = state.customer.acceptedGTB
			/*
			console.log('')
			console.group('changeAcceptedGTB')
			console.log('oldBool:', oldBool)		
			console.log('newBool:', newBool)		
			console.groupEnd()
			*/
			state.customer.acceptedGTB = newBool
		},
		changeAcceptedPP( state, newBool ){
			const oldBool = state.customer.acceptedPP
			/*
			console.log('')
			console.group('changeAcceptedPP')
			console.log('oldBool:', oldBool)		
			console.log('newBool:', newBool)		
			console.groupEnd()
			*/
			state.customer.acceptedPP = newBool
		},
		changeAcceptedNewsletter( state, newBool ){
			const oldBool = state.customer.acceptedNewsletter
			/*
			console.log('')
			console.group('changeAcceptedNewsletter')
			console.log('oldBool:', oldBool)		
			console.log('newBool:', newBool)		
			console.groupEnd()
			*/
			state.customer.acceptedNewsletter = newBool
		},
	}
}
