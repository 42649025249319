<!--

	Was macht diese Componente?
	Wofür ist die Compoente da?

	Welche $props gibt es?

	Welche events gibt es?
		EventBus.$emit('addToCart', product)

	Welche store functions gibt es?
		$store.getters.countItemsInCart		Number
		$store.getters.idsInCart			Array
		...

	Beispiel Code:
		<MhCart></MhCart>

	2019-06-11	init

-->

<template>
	<div class="mhCart"></div>
</template>

<script>
	// @ is an alias to /src
	import state from "./state";
	import restHandler from '@/mixins/restHandler/v1/restHandler.mixin.js'
	import EventBus from '@/helper/EventBus.js'

	export default {
		name: 'MhCart',
		components: {},
		mixins: [ restHandler ],
		props: {},
		data() {
			return {}
		},
		watch: {
			// if cart changes:
			// 1. refetch products
			// 2. store cart to localStorage
			'$store.state.mhCart.cart': {
				handler : function( to, from ) {
					this.doFetchProducts()

					localStorage.setItem('mhCart.cart', JSON.stringify( this.$store.state.mhCart.cart ));
				},
				deep: true,
			},

			// if customer changes: store customer to localStorage
			'$store.state.mhCart.customer': {
				handler : function( to, from ) {
					localStorage.setItem('mhCart.customer', JSON.stringify( this.$store.state.mhCart.customer ));
				},
				deep: true,
			},
		},
		computed: {},
		methods: {
			doFetchProducts( callback ){
				// fetch products by id array
				this.restHandler__fetch({
					action : 'GET',
					route : '/wp-json/mh/v1/posts/',
					params : {
						perPage : 99,
						postIds : this.$store.getters.idsInCart,
					},
					callback : (response) => {
						const data  = response.data
						const posts = data.result

						// add post to store products array if its not there already
						this._.forEach( posts, (post) => {
							const id = post.id
							const products = this.$store.state.mhCart.products

							//console.log('');
							//console.log('$store.getters.idsInCart:', this.$store.getters.idsInCart)

							if( !products.find( (post) => { return post.id == id }) ){
								this.$store.commit("addToProducts", post)
							}
						})

						if( callback ) callback()
					},
				})
			},
			restoreCartFromLocalStorage(){ // restore cart from localStorage
				if( localStorage.getItem('mhCart.cart') ){
					// empty cart before just to be sure
					this.$store.commit("emptyCart")

					// get old cart from localStorage
					const cart = JSON.parse(localStorage.getItem('mhCart.cart'))

					// push every id back to cart
					this._.forEach( cart, (id)=>{
						this.$store.commit("addToCart", id)
					})
				}
			},
			restoreCustomerFromLocalStorage(){ // restore customer from localStorage
				if( localStorage.getItem('mhCart.customer') ){
					const customer = JSON.parse(localStorage.getItem('mhCart.customer'))

					this.$store.commit("changeShippingRegion", customer.shippingRegion)
					this.$store.commit("changeShippingCountry", customer.shippingCountry)
					this.$store.commit("changePaymentOption", customer.paymentOption)
					this.$store.commit("changeDeliveryAddressIsDifferent", customer.deliveryAddressIsDifferent)
					this.$store.commit("changeAcceptedGTB", customer.acceptedGTB)
					this.$store.commit("changeAcceptedPP", customer.acceptedPP)
					this.$store.commit("changeAcceptedNewsletter", customer.acceptedNewsletter)

					// billingAddress
					if( customer.billingAddress ){
						this._.forEach( customer.billingAddress, (value, key) => {
							this.$store.commit('changeAddress', {
								type : 'billingAddress',
								key,
								value
							})
						})
					}
					// deliveryAddress
					if( customer.deliveryAddress ){
						this._.forEach( customer.deliveryAddress, (value, key) => {
							this.$store.commit('changeAddress', {
								type : 'deliveryAddress',
								key,
								value
							})
						})
					}
				}
			},
		},
		created() { // register vuex module
			// register vuex module, but only once
			const alreadyRegistered = this.$store.state.mhCart ? true : false

			if( !alreadyRegistered ){
				//console.log('registerModule("mhCart", state)');
				//console.log('$store.state.mhCart:', this.$store.state.mhCart)
				this.$store.registerModule("mhCart", state)
				//console.log('$store.state.mhCart:', this.$store.state.mhCart)
			}
		},
		mounted() {
			// trigger products fetch, which loads the needed product posts
			EventBus.$on('mhCart__fetchProducts', callback => {
				this.doFetchProducts( callback )
			})

			// restore cart from localStorage
			this.restoreCartFromLocalStorage()
			// restore customer from localStorage
			this.restoreCustomerFromLocalStorage()
		},
	}
</script>

<style lang="less" scoped>
	.mhCart {}
</style>
