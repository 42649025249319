<!--

	Was macht diese Componente?
	Wofür ist die Compoente da?

	Welche $props gibt es?

	Beispiel Code:
		<LuProductTeasers
			:products="[{}, {}, {}]"
		></LuProductTeasers>

	2019-06-08	init

-->

<template>
	<div class="LuProductTeasers contentModule" :class="elmClasses">
		<div class="LuProductTeasers__inner grid grid--LuProductTeasers">
			<div class="LuProductTeasers__product font font--sizeDefault"
				:style="itemStyles( post )"
				v-for="(post, index) in products"
				:key="index">

				<MhImage
					class="LuProductTeasers__productImage shadow shadow--productImage"
					:class="'LuProductTeasers__productImage--size-' + post.acf.productSize"
					mode="contain"
					position="left bottom"
					:imageObject="post.acf.coverImage"
				></MhImage>

				<div class="LuProductTeasers__productTextInfos border border--top border--bottom vSpace vSpace--productTextInfos">
					<div class="LuProductTeasers__productAuthor" v-text="$root.getTranslatedAcfValue(post.acf, 'author')"></div>
					<div class="LuProductTeasers__productTitle font font--bold" v-text="$root.getTranslatedAcfValue(post.acf, 'postTitle')"></div>

					<template v-if="post.acf.notOut">
						<LuPrice class="font font--autoSmooth" :price="post.acf.priceEur"></LuPrice>
					</template>
					<template v-else>
						<div class="">
							{{ $t('message.productIsOutShort') }}
						</div>
					</template>

					<!--
					<template v-if="post.acf.vibrantPalette">
						<MhVibrantPalette @trashSetVibrantColor="trashSetVibrantColor" :palette="post.acf.vibrantPalette"></MhVibrantPalette>
						<br />
					</template>
					-->
				</div>
				<routerLink
					class="LuProductTeasers__productLink"
					:to="{ name : 'ProductView', params : { slug : post.slug } }"
				>Read more ...</routerLink>
			</div>
		</div>
	</div>
</template>

<script>
	// @ is an alias to /src
	//import DevInfos from '@/components/DevInfos.vue'
	import EventBus from '@/helper/EventBus.js'
	import MhColor from '@/components/MhColor/MhColor.js'
	import MhImage from '@/components/MhImage/MhImage.vue'
	import LuPrice from '@/components/LuPrice.vue'
	import MhVibrantPalette from '@/components/MhVibrantPalette.vue'

	export default {
		name: 'LuProductTeasers',
		components: {
			MhImage,
			MhVibrantPalette,
			LuPrice,
		},
		mixins: [ MhColor ],
		props: {
			//imageObject: [Object, Boolean],
			products: [Array, Boolean],
		},
		data() {
			return {
			}
		},
		watch: {},
		computed: {
			app() {
				return this.$root.$children[0]
			},
			elmClasses(){
				let classes = []

				return classes
			},
		},
		methods: {
			itemStyles( post ){
				if( post && post.acf.vibrantPalette && post.acf.vibrantPalette.vibrant ){
					const baseColor = post.acf.vibrantPalette.vibrant
					//let newColor = this.getVibrantColor( baseColor )
					let newColor = baseColor

					return {
						color : newColor
					}
				}
			},
		},
		created() {},
		mounted() {},
	}
</script>

<style lang="less">
	@import "../less/vars.less";
	@import "../less/mixins.less";

	.LuProductTeasers {
		position: relative;

		&__inner {}

		&__product {
			[showborders3] & { background-color: fade(red, 30);	}

			position: relative;
			display: flex;
			flex-direction: column;
			transform: translateZ( 1px ); // solves some blurry/color changing issues

			&Image {
				[showborders3] & { background-color: fade(red, 10); }

				transition: all 0.3s ease 0.1s;
				padding-bottom: 100%;
				margin-right: 0.75rem; // prevent to small gaps

				//display: none;
			}
			&Image--size {	// es gibt fünf verschiedene product größen
				.sizeMixin( @marginTop : 0rem ){
					//outline: 1px solid red;

					.mhImage__image {
						top: @marginTop;
						height: calc( 100% - @marginTop );
					}
				}

				&-huge { 	.sizeMixin( 0rem ); }
				&-big {		.sizeMixin( 1rem ); }
				&-default { .sizeMixin( 2rem ); }
				&-small { 	.sizeMixin( 2.5rem ); }
				&-mini { 	.sizeMixin( 3rem ); }
			}
			&:hover &Image {
				transition-duration: 0.2s;
				transition-delay: 0s;
				transform: translateY( -0.75em );
			}

			&TextInfos {
				flex-grow: 1;
				margin-top: 0.75em;
			}
			&Link {
				position: absolute;
				top: 0; left: 0; right: 0; bottom: 0;
				// hide "Read more ..."
				font-size: 0; line-height: 0;
				z-index: 5;
			}
		}
	}

	@media @mediaQuery__xs {}
	@media @mediaQuery__sm {}
	// enlarge books on mobile
	@media @mediaQuery__md {
		.LuProductTeasers {
			&__productImage {
				padding-bottom: 130%;
			}
		}
	}
	@media @mediaQuery__dt {}
	@media @mediaQuery__lg {}
	@media @mediaQuery__xl {}
</style>
