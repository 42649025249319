<!--

	Echoes html string with duration or day event

	Usage examples:
		<LuDate
			startDate="20191020"
			endDate="20191122"
		></LuDate>

		<LuDate
			startDate="20191020"
		></LuDate>

	2019-05-20	init

-->

<template>
	<div class="LuDate"
		 :class="elmClasses"
		 :data-startsIn="startsIn"
		 :data-startsInFuture="startsInFuture"
		 :data-isFinished="isFinished"
		 v-html="dateHtml">
		<!--
		<pre>{{startDate}}</pre>
		<pre>{{endDate}}</pre>
		-->
	</div>
</template>

<script>
	// @ is an alias to /src
	//import DevInfos from '@/components/DevInfos.vue'
	import EventBus from '@/helper/EventBus.js'

	export default {
		name: 'LuDate',
		components: {},
		mixins: [],
		props: {
			startDate: [ Number, String, Boolean ],
			endDate: [ Number, String, Boolean ],
			showDaysLeft: [ Boolean ],
		},
		data() {
			return {
				//tilDivider : '–',
				tilDivider : '',
			}
		},
		watch: {
			someVar: {
				handler: function( to, from ) {
				},
				deep: true,
			}
		},
		computed: {
			elmClasses(){
				let classes = []

				if( this.showDaysLeft ) classes.push('LuDate--showDaysCounter')
				if( this.startsInFuture ) classes.push('LuDate--startsInFuture')
				if( this.isFinished ) classes.push('LuDate--isFinished')
				if( this.isRunning ) classes.push('LuDate--isRunning')

				return classes
			},
			diffDays(){ // calculate and set days from today to end date
				const startDate   = this.startDate
				const todayDate   = this.date( 'Ymd', new Date() )
				const endDate     = this.endDate

				if( !startDate ) return

				const startDateTS = this.parseTS( startDate )
				const todayDateTS = this.parseTS( todayDate )
				const endDateTS   = this.parseTS( endDate )

				let diffDays      = 0

				if( startDate && endDate && todayDate > startDate ){  // && todayDate > endDate ){

					const oneDay 	   = 24*60*60*1000; // hours*minutes*seconds*milliseconds
					const firstDate    = Date.parse(todayDateTS);
					const secondDate   = Date.parse(endDateTS);

					diffDays = Math.round( (secondDate - firstDate) / oneDay )

					/*
					console.log('');
					console.log('startDate:', startDate);
					console.log('todayDate:', todayDate);
					console.log('endDate:', endDate);

					console.log('firstDate:', firstDate);
					console.log('secondDate:', secondDate);
					//console.log('startDateStr:', startDateStr);
					//console.log('endDateStr:', endDateStr);
					console.log('diffDays:', diffDays);
					*/

				}

				return diffDays
			},
			startsInFuture(){
				const startDate   = this.startDate
				let returnValue   = false

				if( startDate ){
					const todayDate = this.date( 'Ymd', new Date() )
					const endDate   = this.endDate

					returnValue = todayDate < startDate ? true : false
				}

				return returnValue
			},
			startsIn(){
				const startDate = this.startDate
				const todayDate = this.date( 'Ymd', new Date() )
				let returnValue = null

				if( startDate ){
					const startDateTS = this.parseTS( startDate )
					const todayDateTS = this.parseTS( todayDate )

					const oneDay 	  = 24*60*60*1000; // hours*minutes*seconds*milliseconds
					const firstDate   = Date.parse(todayDateTS);
					const secondDate  = Date.parse(startDateTS);

					const diffDays    = Math.round( (secondDate - firstDate) / oneDay )

					returnValue       = diffDays
				}

				return (returnValue > 0) ? returnValue : false
			},
			isFinished(){
				return (this.diffDays < 0) ? true : false
			},
			isRunning(){
				if( !this.isFinished && !this.startsInFuture ){
					return true
				}else{
					return false
				}
			},
			dateHtml(){
				const startDate = this.startDate
				const endDate   = this.endDate
				let returnValue = ''

				if( startDate && endDate ){

					const tilDivider   = this.tilDivider;

					const startDateTS  = this.parseTS( startDate )
					const endDateTS    = this.parseTS( endDate )
					const startDateY   = this.date( 'Y', startDateTS )
					const endDateY     = this.date( 'Y', endDateTS )

					let   startDateStr = this.date( 'd•m•Y', startDateTS )
					const endDateStr   = this.date( 'd•m•Y', endDateTS )

					let diffDays       = this.diffDays > 0 ? this.diffDays : 0 // days from now til end of event

					if( startDateY == endDateY ){
						startDateStr = this._.replace( startDateStr, endDateY, '' )
					}

					const startsInStr = this.startsIn

					let outputString = startDateStr + '---' + endDateStr
						outputString = this._.replace( outputString, '---', '<span class="LuDate__tilDivider"><span class="LuDate__tilDividerStroke"></span><span class="LuDate__daysLeftLabel" data-daysLeft="'+diffDays+'"></span>'+tilDivider+'</span>' )
						outputString = this._.replace( outputString, new RegExp('•',"g"), '<span class="LuDate__dotDivider">.</span>' )

					returnValue = outputString
				}
				if( startDate && !endDate ){
					const startDateTS = this.parseTS( startDate )
					const startDateStr = this.date( 'd-m-Y', startDateTS )

					let outputString = startDateStr
						outputString = this._.replace( outputString, new RegExp('-',"g"), '<span class="LuDate__dotDivider">.</span>' )

					returnValue = outputString
				}

				returnValue = returnValue + '<span class="LuDate__daysToGoLabel" data-daysToGo="'+this.startsIn+'"></span>'

				return returnValue
			},
		},
		methods: {
			date( format, timestamp) {
				  //  discuss at: http://locutus.io/php/date/
				  // original by: Carlos R. L. Rodrigues (http://www.jsfromhell.com)
				  // original by: gettimeofday
				  //    parts by: Peter-Paul Koch (http://www.quirksmode.org/js/beat.html)
				  // improved by: Kevin van Zonneveld (http://kvz.io)
				  // improved by: MeEtc (http://yass.meetcweb.com)
				  // improved by: Brad Touesnard
				  // improved by: Tim Wiel
				  // improved by: Bryan Elliott
				  // improved by: David Randall
				  // improved by: Theriault (https://github.com/Theriault)
				  // improved by: Theriault (https://github.com/Theriault)
				  // improved by: Brett Zamir (http://brett-zamir.me)
				  // improved by: Theriault (https://github.com/Theriault)
				  // improved by: Thomas Beaucourt (http://www.webapp.fr)
				  // improved by: JT
				  // improved by: Theriault (https://github.com/Theriault)
				  // improved by: Rafał Kukawski (http://blog.kukawski.pl)
				  // improved by: Theriault (https://github.com/Theriault)
				  //    input by: Brett Zamir (http://brett-zamir.me)
				  //    input by: majak
				  //    input by: Alex
				  //    input by: Martin
				  //    input by: Alex Wilson
				  //    input by: Haravikk
				  // bugfixed by: Kevin van Zonneveld (http://kvz.io)
				  // bugfixed by: majak
				  // bugfixed by: Kevin van Zonneveld (http://kvz.io)
				  // bugfixed by: Brett Zamir (http://brett-zamir.me)
				  // bugfixed by: omid (http://locutus.io/php/380:380#comment_137122)
				  // bugfixed by: Chris (http://www.devotis.nl/)
				  //      note 1: Uses global: locutus to store the default timezone
				  //      note 1: Although the function potentially allows timezone info
				  //      note 1: (see notes), it currently does not set
				  //      note 1: per a timezone specified by date_default_timezone_set(). Implementers might use
				  //      note 1: $locutus.currentTimezoneOffset and
				  //      note 1: $locutus.currentTimezoneDST set by that function
				  //      note 1: in order to adjust the dates in this function
				  //      note 1: (or our other date functions!) accordingly
				  //   example 1: date('H:m:s \\m \\i\\s \\m\\o\\n\\t\\h', 1062402400)
				  //   returns 1: '07:09:40 m is month'
				  //   example 2: date('F j, Y, g:i a', 1062462400)
				  //   returns 2: 'September 2, 2003, 12:26 am'
				  //   example 3: date('Y W o', 1062462400)
				  //   returns 3: '2003 36 2003'
				  //   example 4: var $x = date('Y m d', (new Date()).getTime() / 1000)
				  //   example 4: $x = $x + ''
				  //   example 4: var $result = $x.length // 2009 01 09
				  //   returns 4: 10
				  //   example 5: date('W', 1104534000)
				  //   returns 5: '52'
				  //   example 6: date('B t', 1104534000)
				  //   returns 6: '999 31'
				  //   example 7: date('W U', 1293750000.82); // 2010-12-31
				  //   returns 7: '52 1293750000'
				  //   example 8: date('W', 1293836400); // 2011-01-01
				  //   returns 8: '52'
				  //   example 9: date('W Y-m-d', 1293974054); // 2011-01-02
				  //   returns 9: '52 2011-01-02'
				  //        test: skip-1 skip-2 skip-5

				  var jsdate, f
				  // Keep this here (works, but for code commented-out below for file size reasons)
				  // var tal= [];
				  var txtWords = [
					'Sun', 'Mon', 'Tues', 'Wednes', 'Thurs', 'Fri', 'Satur',
					'January', 'February', 'March', 'April', 'May', 'June',
					'July', 'August', 'September', 'October', 'November', 'December'
				  ]
				  // trailing backslash -> (dropped)
				  // a backslash followed by any character (including backslash) -> the character
				  // empty string -> empty string
				  var formatChr = /\\?(.?)/gi
				  var formatChrCb = function (t, s) {
					return f[t] ? f[t]() : s
				  }
				  var _pad = function (n, c) {
					n = String(n)
					while (n.length < c) {
					  n = '0' + n
					}
					return n
				  }
				  f = {
					// Day
					d: function () {
					  // Day of month w/leading 0; 01..31
					  return _pad(f.j(), 2)
					},
					D: function () {
					  // Shorthand day name; Mon...Sun
					  return f.l()
						.slice(0, 3)
					},
					j: function () {
					  // Day of month; 1..31
					  return jsdate.getDate()
					},
					l: function () {
					  // Full day name; Monday...Sunday
					  return txtWords[f.w()] + 'day'
					},
					N: function () {
					  // ISO-8601 day of week; 1[Mon]..7[Sun]
					  return f.w() || 7
					},
					S: function () {
					  // Ordinal suffix for day of month; st, nd, rd, th
					  var j = f.j()
					  var i = j % 10
					  if (i <= 3 && parseInt((j % 100) / 10, 10) === 1) {
						i = 0
					  }
					  return ['st', 'nd', 'rd'][i - 1] || 'th'
					},
					w: function () {
					  // Day of week; 0[Sun]..6[Sat]
					  return jsdate.getDay()
					},
					z: function () {
					  // Day of year; 0..365
					  var a = new Date(f.Y(), f.n() - 1, f.j())
					  var b = new Date(f.Y(), 0, 1)
					  return Math.round((a - b) / 864e5)
					},

					// Week
					W: function () {
					  // ISO-8601 week number
					  var a = new Date(f.Y(), f.n() - 1, f.j() - f.N() + 3)
					  var b = new Date(a.getFullYear(), 0, 4)
					  return _pad(1 + Math.round((a - b) / 864e5 / 7), 2)
					},

					// Month
					F: function () {
					  // Full month name; January...December
					  return txtWords[6 + f.n()]
					},
					m: function () {
					  // Month w/leading 0; 01...12
					  return _pad(f.n(), 2)
					},
					M: function () {
					  // Shorthand month name; Jan...Dec
					  return f.F()
						.slice(0, 3)
					},
					n: function () {
					  // Month; 1...12
					  return jsdate.getMonth() + 1
					},
					t: function () {
					  // Days in month; 28...31
					  return (new Date(f.Y(), f.n(), 0))
						.getDate()
					},

					// Year
					L: function () {
					  // Is leap year?; 0 or 1
					  var j = f.Y()
					  return j % 4 === 0 & j % 100 !== 0 | j % 400 === 0
					},
					o: function () {
					  // ISO-8601 year
					  var n = f.n()
					  var W = f.W()
					  var Y = f.Y()
					  return Y + (n === 12 && W < 9 ? 1 : n === 1 && W > 9 ? -1 : 0)
					},
					Y: function () {
					  // Full year; e.g. 1980...2010
					  return jsdate.getFullYear()
					},
					y: function () {
					  // Last two digits of year; 00...99
					  return f.Y()
						.toString()
						.slice(-2)
					},

					// Time
					a: function () {
					  // am or pm
					  return jsdate.getHours() > 11 ? 'pm' : 'am'
					},
					A: function () {
					  // AM or PM
					  return f.a()
						.toUpperCase()
					},
					B: function () {
					  // Swatch Internet time; 000..999
					  var H = jsdate.getUTCHours() * 36e2
					  // Hours
					  var i = jsdate.getUTCMinutes() * 60
					  // Minutes
					  // Seconds
					  var s = jsdate.getUTCSeconds()
					  return _pad(Math.floor((H + i + s + 36e2) / 86.4) % 1e3, 3)
					},
					g: function () {
					  // 12-Hours; 1..12
					  return f.G() % 12 || 12
					},
					G: function () {
					  // 24-Hours; 0..23
					  return jsdate.getHours()
					},
					h: function () {
					  // 12-Hours w/leading 0; 01..12
					  return _pad(f.g(), 2)
					},
					H: function () {
					  // 24-Hours w/leading 0; 00..23
					  return _pad(f.G(), 2)
					},
					i: function () {
					  // Minutes w/leading 0; 00..59
					  return _pad(jsdate.getMinutes(), 2)
					},
					s: function () {
					  // Seconds w/leading 0; 00..59
					  return _pad(jsdate.getSeconds(), 2)
					},
					u: function () {
					  // Microseconds; 000000-999000
					  return _pad(jsdate.getMilliseconds() * 1000, 6)
					},

					// Timezone
					e: function () {
					  // Timezone identifier; e.g. Atlantic/Azores, ...
					  // The following works, but requires inclusion of the very large
					  // timezone_abbreviations_list() function.
					  /*              return that.date_default_timezone_get();
					   */
					  var msg = 'Not supported (see source code of date() for timezone on how to add support)'
					  throw new Error(msg)
					},
					I: function () {
					  // DST observed?; 0 or 1
					  // Compares Jan 1 minus Jan 1 UTC to Jul 1 minus Jul 1 UTC.
					  // If they are not equal, then DST is observed.
					  var a = new Date(f.Y(), 0)
					  // Jan 1
					  var c = Date.UTC(f.Y(), 0)
					  // Jan 1 UTC
					  var b = new Date(f.Y(), 6)
					  // Jul 1
					  // Jul 1 UTC
					  var d = Date.UTC(f.Y(), 6)
					  return ((a - c) !== (b - d)) ? 1 : 0
					},
					O: function () {
					  // Difference to GMT in hour format; e.g. +0200
					  var tzo = jsdate.getTimezoneOffset()
					  var a = Math.abs(tzo)
					  return (tzo > 0 ? '-' : '+') + _pad(Math.floor(a / 60) * 100 + a % 60, 4)
					},
					P: function () {
					  // Difference to GMT w/colon; e.g. +02:00
					  var O = f.O()
					  return (O.substr(0, 3) + ':' + O.substr(3, 2))
					},
					T: function () {
					  // The following works, but requires inclusion of the very
					  // large timezone_abbreviations_list() function.
					  /*              var abbr, i, os, _default;
					  if (!tal.length) {
						tal = that.timezone_abbreviations_list();
					  }
					  if ($locutus && $locutus.default_timezone) {
						_default = $locutus.default_timezone;
						for (abbr in tal) {
						  for (i = 0; i < tal[abbr].length; i++) {
							if (tal[abbr][i].timezone_id === _default) {
							  return abbr.toUpperCase();
							}
						  }
						}
					  }
					  for (abbr in tal) {
						for (i = 0; i < tal[abbr].length; i++) {
						  os = -jsdate.getTimezoneOffset() * 60;
						  if (tal[abbr][i].offset === os) {
							return abbr.toUpperCase();
						  }
						}
					  }
					  */
					  return 'UTC'
					},
					Z: function () {
					  // Timezone offset in seconds (-43200...50400)
					  return -jsdate.getTimezoneOffset() * 60
					},

					// Full Date/Time
					c: function () {
					  // ISO-8601 date.
					  return 'Y-m-d\\TH:i:sP'.replace(formatChr, formatChrCb)
					},
					r: function () {
					  // RFC 2822
					  return 'D, d M Y H:i:s O'.replace(formatChr, formatChrCb)
					},
					U: function () {
					  // Seconds since UNIX epoch
					  return jsdate / 1000 | 0
					}
				  }

				  var _date = function (format, timestamp) {
					jsdate = (timestamp === undefined ? new Date() // Not provided
					  : (timestamp instanceof Date) ? new Date(timestamp) // JS Date()
					  : new Date(timestamp * 1000) // UNIX timestamp (auto-convert to int)
					)
					return format.replace(formatChr, formatChrCb)
				  }

				  return _date(format, timestamp)
			},
			parseTS( str ) {	// yyyymmdd string to timestamp
				//if( str ){
				var y = str.substr(0,4),
					m = str.substr(4,2) - 1,
					d = str.substr(6,2);
				var D = new Date(y,m,d);
				return (D.getFullYear() == y && D.getMonth() == m && D.getDate() == d) ? D : 'invalid date';
				//}
			}
		},
		created() {},
		mounted() {},
	}
</script>

<style lang="less">
	@import "../less/vars.less";
	@import "../less/mixins.less";
	@import (reference) "../less/atoms.less";

	@tilLine__stroke        : 0.1em;
	@tilLine__width         : 0.75em;
	@tilLine__expandedWidth : 4em;
	@tilLine__margin        : 0 0.15em 0 0.03em;

	.LuDate {
		display: flex;
		position: relative;

		//& > * { outline: 1px solid red; }
		//&__tilDivider { background-color: fade( red, 20 ); }

		// dotDivider
		&__dotDivider {
			padding-right: 0.05em;
		}

		// line between dates
		&__tilDivider {
			position: relative;
			width: @tilLine__width;
			display: flex;
			margin: @tilLine__margin;
			display: inline-block;
		}
		&__tilDividerStroke {
			position: absolute;
			top: 50%; left: 0; right: 0;
			transform: translateY( -40% );
			display: block;

			height: @tilLine__stroke;
			background-color: currentColor;
		}

		// labels ( "days left" and "days to to")
		&__daysLeftLabel,
		&__daysToGoLabel {
			position: relative;
			background-color: currentColor;

			padding-top: 0.1em;
			padding-left: 0.4em;
			padding-right: 0.4em;
			height: 1.2em;

			white-space: nowrap;
			line-height: 1em;
			font-size: 0.385em;
			display: none;

			&::before { color: white; }
		}
		&__daysLeftLabel {
			position: absolute;
			top: 0; left: 50%;
			transform: translateX( -50% );

			&::before { content: attr( data-daysLeft ) " days left"; }
		}
		&__daysToGoLabel {
			position: relative;
			top: 0.6em;
			top: 0;
			left: 0.5em;

			&::before { content: attr( data-daysToGo ) " days to go"; }
		}

		// is running
		&--showDaysCounter&--isRunning {
			//color: red;

			.LuDate__tilDivider {
				width: @tilLine__expandedWidth;
			}
			.LuDate__tilDividerStroke::before {
				position: absolute;
				top: 0; left: 0; bottom: 0; right: 50%;
				content: "";
				background-color: fade( white, 75 );
			}
			.LuDate__daysLeftLabel {
				display: block;
			}

		}
		// will start
		&--showDaysCounter&--startsInFuture {
			//color: blue;

			.LuDate__daysToGoLabel {
				display: block;
			}
		}
	}
</style>
