import Vue from 'vue'
import App from '@/App.vue'
import router from '@/config/router'
import store from '@/store/store'
import axios from 'axios'
import VueI18n from 'vue-i18n'
import VueAxios from 'vue-axios'
import VueLodash from 'vue-lodash'
import VShowSlide from 'v-show-slide'
import VueMq from 'vue-mq'
import VueMeta from 'vue-meta'
import mqBreakpoints from '@/config/mqBreakpoints'
import currentEnv from '@/helper/getCurrentEnv.js'
import EventBus from '@/helper/EventBus.js'

Vue.config.productionTip = false
Vue.config.silent = false

// on error + warn: play beep sound and show errorBadge
// see: https://vuejs.org/v2/api/#errorHandler
// see: https://vuejs.org/v2/api/#warnHandler
const warnAndErrorHandler = ( err, vm, info ) => {
	// err: error trace
	// vm: component in which error occured
	// info: Vue specific error information such as lifecycle hooks, events etc.

	// TODO: Perform any custom logic or log to server
	const componentName = vm ? vm.$options.name : ''

	// remove eventually previously added errorBadge
	const prevErrorBadge = document.body.querySelector('errorBadge')
	if (prevErrorBadge) document.body.removeChild(prevErrorBadge)
	// Show red dot as visible error indicator
	const badgeHeight = '150px'
	const errorBadgeElm = document.createElement('div')
	errorBadgeElm.classList.add("errorBadge")
	errorBadgeElm.style.cssText = `
		position: fixed;
		border-radius: 50%;
		top: calc( 50% - ( ${badgeHeight} * 0.5 ) );
		left: calc( 50% - ( ${badgeHeight} * 0.5 ) );
		width: ${badgeHeight};
		height: ${badgeHeight};
		opacity: 0.85;
		z-index: 100000;
		background: red;
	`;
	document.body.appendChild(errorBadgeElm);

	console.group('%cerrorHandler() •', 'color : red', componentName)
	console.error('err:', err)
	console.log('%cvm:', 'color : red', vm)
	console.log('%cinfo:', 'color : red', info)
	console.groupEnd()

	const a = new AudioContext() // browsers limit the number of concurrent audio contexts, so you better re-use'em

	function beep(vol, freq, duration) {
		const v = a.createOscillator()
		const u = a.createGain()
		v.connect(u)
		v.frequency.value = freq
		v.type = "square"
		u.connect(a.destination)
		u.gain.value = vol * 0.01
		v.start(a.currentTime)
		v.stop(a.currentTime + duration * 0.001)
	}

	beep(10, 320, 150)
}
if( ['local', 'stage'].includes( currentEnv.name ) ){
	Vue.config.warnHandler = warnAndErrorHandler
	Vue.config.errorHandler = warnAndErrorHandler
}

Vue.use(VueMeta)
Vue.use(VueI18n)
Vue.use(VueAxios, axios)
Vue.use(VueLodash)
Vue.use(VShowSlide)
Vue.use(VueMq, mqBreakpoints )

// Setup i18n
// See: http://kazupon.github.io/vue-i18n/started.html
const i18n = new VueI18n({
	locale: 'de', // set locale
	fallbackLocale: 'en',
	messages : {
		//de : require('./i18n/de').default,
		//en : require('./i18n/en').default,
		de : require('./config/i18n.de-en').default.de,
		en : require('./config/i18n.de-en').default.en,
	},
})
// Enable hot updates for i18n
// See: http://kazupon.github.io/vue-i18n/guide/hot-reload.html
if( module.hot ) {
	module.hot.accept(['./config/i18n.de-en'], () => {
		console.log('i18n message hot reloaded');

		//i18n.setLocaleMessage('de', require('./i18n/de').default)
		//i18n.setLocaleMessage('en', require('./i18n/en').default)

		i18n.setLocaleMessage('de', require('./config/i18n.de-en').default.de)
		i18n.setLocaleMessage('en', require('./config/i18n.de-en').default.en)

		// Or the following hot updates via $i18n property
		// app.$i18n.setLocaleMessage('en', require('./en').default)
		// app.$i18n.setLocaleMessage('ja', require('./ja').default)
	})
}

// Setup and mount root
new Vue({
	i18n,
	router,
	store,
	data() {
		return {}
	},
	watch: {
		'$route.params.lang'( to, from ){	// set this.$i18n.local
			const lang = this.$route.params.lang
			this.$i18n.locale = lang
		}
	},
	//errorCaptured: (err, vm, info) => alert('I have a broken child :('),
	computed: {
		isSmallDevice() {
			if (this.$mq === 'xs' || this.$mq === 'sm' || this.$mq === 'md') return true
		},
		homeUrl() {
			let currentLang = this.currentLang

			return '/' + currentLang + '/'
		},
		toggleLangUrl() {
			const currentUrl = this.$route.fullPath
			const currentLang = this.currentLang
			let newUrl = currentUrl

			if (currentLang == 'de'){
				newUrl = newUrl.replace('/de', '/en')
			}
			if (currentLang == 'en'){
				newUrl = newUrl.replace('/en', '/de')
			}

			return newUrl
		},
		currentLang() {
			const validLangs = ['de', 'en']
			let currentLang = this.$route.params.lang

			if (!currentLang || validLangs.indexOf(currentLang) == -1) {
				return 'de'
			} else {
				return currentLang
			}
		},
		hostname(){
			return window.location.hostname
		},
	},
	methods: {
		// wegen der zweisprachigkeit ist das acf-object komplexer, hier wird es gestreamlinet
		// außerdem wird vor jeder headline (außer der ersten) eine LuSpacer eingefügt
		getContentModules( post ) {
			const currentLang     = this.$root.currentLang
			let contentModules    = null
			let newContentModules = []

			// wähle die richtigen contentModules anhand der aktuellen sprache aus
			if( post && post.acf ){
				if( currentLang == 'de' && post.acf.contentModules__de ){
					contentModules = post.acf.contentModules__de.contentModules
				}else if( currentLang == 'en' && post.acf.contentModules__en ){
					contentModules = post.acf.contentModules__en.contentModules
				}
			}

			// füge spacer module hinzu
			// -	über jede überschrift (außer der ersten)
			if( contentModules ){
				this._.forEach( contentModules, (contentModule, index) => {
					const acf_fc_layout = contentModule.acf_fc_layout
					/*
					console.log('');
					console.log('index:', index);
					console.log('acf_fc_layout:', acf_fc_layout);
					*/
					if( index > 0 && acf_fc_layout == 'headline' ){
						newContentModules.push({
							acf_fc_layout : 'spacer',
							lines : 2,
						})
					}
					newContentModules.push( contentModule )
				})
				contentModules = newContentModules
			}

			return contentModules
		},
		getTranslatedAcfValue( parentObject, acfKey ) {
			const _acfKey = acfKey + '__' + this.currentLang
			let returnString = ''

			if( parentObject && parentObject[_acfKey] ){
				returnString = parentObject[_acfKey]
				return returnString
			}
			else{
				return null
			}
		},
		getTranslatedValue( value ) {
			let _value = value

			return _value



			/*
			if(!this.$children[0].acfOptions) return deValue

			const currentLang = this.getCurrentLang()
			const translations = this.$children[0].acfOptions.translations2
			const enValue = translations[deValue]

			if('en' == currentLang && enValue){
				return enValue
			}else{
				return deValue
			}
			*/
		},
		getPathFromUrl( url = window.location.href ) {
			if (!url) return

			return url.replace(/^.*\/\/[^\/]+/, '')
		},
		getPathLinkInCurrentLang( url = false ){
			let returnPath = null

			if( url ){
				const path = this.getPathFromUrl( url )
				const currentLang = this.currentLang

				if( currentLang == 'de' ) returnPath = path.replace('/en/', '/de/')
				if( currentLang == 'en' ) returnPath = path.replace('/de/', '/en/')
			}

			return returnPath
		},
		trim( str, charlist ) {
			// taken from https://github.com/hirak/phpjs/blob/master/functions/strings/trim.js

			//  discuss at: http://phpjs.org/functions/trim/
			// original by: Kevin van Zonneveld (http://kevin.vanzonneveld.net)
			// improved by: mdsjack (http://www.mdsjack.bo.it)
			// improved by: Alexander Ermolaev (http://snippets.dzone.com/user/AlexanderErmolaev)
			// improved by: Kevin van Zonneveld (http://kevin.vanzonneveld.net)
			// improved by: Steven Levithan (http://blog.stevenlevithan.com)
			// improved by: Jack
			//    input by: Erkekjetter
			//    input by: DxGx
			// bugfixed by: Onno Marsman
			//   example 1: trim('    Kevin van Zonneveld    ');
			//   returns 1: 'Kevin van Zonneveld'
			//   example 2: trim('Hello World', 'Hdle');
			//   returns 2: 'o Wor'
			//   example 3: trim(16, 1);
			//   returns 3: 6

			var whitespace, l = 0,
				i = 0;
			str += '';

			if (!charlist) {
				// default list
				whitespace =
					' \n\r\t\f\x0b\xa0\u2000\u2001\u2002\u2003\u2004\u2005\u2006\u2007\u2008\u2009\u200a\u200b\u2028\u2029\u3000';
			} else {
				// preg_quote custom list
				charlist += '';
				whitespace = charlist.replace(/([\[\]\(\)\.\?\/\*\{\}\+\$\^\:])/g, '$1');
			}

			l = str.length;
			for (i = 0; i < l; i++) {
				if (whitespace.indexOf(str.charAt(i)) === -1) {
					str = str.substring(i);
					break;
				}
			}

			l = str.length;
			for (i = l - 1; i >= 0; i--) {
				if (whitespace.indexOf(str.charAt(i)) === -1) {
					str = str.substring(0, i + 1);
					break;
				}
			}

			return whitespace.indexOf(str.charAt(0)) === -1 ? str : '';
		},
		slugify( text ){
			return text.toString().toLowerCase()
				.replace(/\s+/g, '-')           // Replace spaces with -
				.replace(/[^\w\-]+/g, '')       // Remove all non-word chars
				.replace(/\-\-+/g, '-')         // Replace multiple - with single -
				.replace(/^-+/, '')             // Trim - from start of text
				.replace(/-+$/, '');            // Trim - from end of text
		},
		isValidEmailAddress( emailString ) {
			let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
			return re.test( String( emailString ).toLowerCase() )
		},
	},
	created() {},
	mounted() {
		// Set $i18n.locale from current route param
		this.$router.onReady( () => {
			this.$i18n.locale = this.$route.params.lang
		})

		// Set html lang attribute
		// 1)	on load
		const htmlElm = document.querySelector('html')
		this.$router.onReady( () => {
			//console.log('set lang to:', htmlElm)
			htmlElm.lang = this.$route.params.lang
		})
		// 2)	on route change
		this.$router.afterEach( (to, from) => {
			//console.log('set lang to:', htmlElm)
			htmlElm.lang = this.$route.params.lang
		})

		//console.log('locale:', this.$i18n.locale)
		//console.log('$t("message.hello"):', this.$t("message.hello") )
	},
	render: h => h(App)
}).$mount('#app')
