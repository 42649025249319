<!--

	Was macht diese Componente?
	Wofür ist die Compoente da?

	Welche $props gibt es?

	Beispiel Code:
		<LuPageHeader
			:title="'Amazing Headline'"
			:subTitle="'Amazing SubHeadline'"
			:big="false"
			:text="'Filter desc text'"
		></LuPageHeader>

	2019-06-08	init

-->

<template>
	<div class="LuPageHeader contentModule" :class="elmClasses">
		<div class="LuPageHeader__inner grid grid--LuPageHeader height height--pageHeader">
			<div>
				<h2 class="LuPageHeader__title inlineBlock font border border--top border--strong vSpace vSpace--pageHeaderTitle"
					:class="big ? 'font--sizeBig' : 'font--sizeDefault'">
					<span v-html="title" :class="{ 'font font--bold' : !subTitle }"></span><br />
					<span class="font font--bold" v-if="subTitle" v-html="subTitle"></span><br />
				</h2>
				<br v-if="$root.isSmallDevice" />
				<br v-if="$root.isSmallDevice" />
			</div>
			<div class="vSpace vSpace--pageHeaderTitle" v-if="text" v-html="text"></div>
		</div>
	</div>
</template>

<script>
	// @ is an alias to /src
	//import DevInfos from '@/components/DevInfos.vue'
	import EventBus from '@/helper/EventBus.js'

	export default {
		name: 'LuPageHeader',
		components: {},
		mixins: [],
		props: {
			/*
			title: [String, Boolean],
			subTitle: [String, Boolean],
			text: [String, Boolean],
			big: [Boolean],
			*/
			title: {
				type     : [String, Boolean],
				default  : false,
				required : false,
			},
			subTitle: {
				type     : [String, Boolean],
				default  : false,
				required : false,
			},
			text: {
				type     : [String, Boolean],
				default  : false,
				required : false,
			},
			big: {
				type     : [Boolean],
				default  : false,
				required : false,
			},
		},
		data() {
			return {
			}
		},
		watch: {},
		computed: {
			app() {
				return this.$root.$children[0]
			},
			elmClasses(){
				let classes = []



				return classes
			},
		},
		methods: {},
		created() {},
		mounted() {},
	}
</script>

<style lang="less">
	@import "../less/vars.less";
	@import "../less/mixins.less";

	.LuPageHeader {
		position: relative;

		&__inner {}

		&__title {
			span {
				display: inline-block;
				transform: translateY(0.1em);
			}
		}
	}

	@media @mediaQuery__xs {}
	@media @mediaQuery__sm {}
	@media @mediaQuery__md {}
	@media @mediaQuery__dt {}
	@media @mediaQuery__lg {}
	@media @mediaQuery__xl {}
</style>
