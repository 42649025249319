<!--

	Was macht diese Componente?
	Wofür ist die Compoente da?

	Welche $props gibt es?

	Beispiel Code:
		<LuSpacer
			:lines="3"
		></LuSpacer>

	2019-06-08	init

-->

<template>
	<div class="LuSpacer contentModule" :class="elmClasses">
		<div class="LuSpacer__inner">
			<br v-for="line in lines" :key="line"/>
		</div>
	</div>
</template>

<script>
	// @ is an alias to /src
	//import DevInfos from '@/components/DevInfos.vue'
	import EventBus from '@/helper/EventBus.js'

	export default {
		name: 'LuSpacer',
		components: {},
		mixins: [],
		props: {
			//imageObject: [Object, Boolean],
			lines: [Number, Boolean],
		},
		data() {
			return {
			}
		},
		watch: {},
		computed: {
			app() {
				return this.$root.$children[0]
			},
			elmClasses(){
				let classes = []

				return classes
			},
		},
		methods: {},
		created() {},
		mounted() {},
	}
</script>

<style lang="less">
	@import "../less/vars.less";
	@import "../less/mixins.less";

	.LuSpacer {
	}

	@media @mediaQuery__xs {}
	@media @mediaQuery__sm {}
	@media @mediaQuery__md {}
	@media @mediaQuery__dt {}
	@media @mediaQuery__lg {}
	@media @mediaQuery__xl {}
</style>
