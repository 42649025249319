<!--

	Was macht diese Componente?
	Wofür ist die Compoente da?

	Welche $props gibt es?

	Beispiel Code:
		<LuSetCssVars></LuSetCssVars>

	2019-09-26	init

-->

<template>
	<div class="LuSetCssVars"></div>
</template>

<script>
	// @ is an alias to /src
	//import DevInfos from '@/components/DevInfos.vue'
	import EventBus from '@/helper/EventBus.js'

	export default {
		name: 'LuSetCssVars',
		components: {},
		mixins: [],
		props: {},
		data() {
			return {
				doIt : false,
				debugLog : false,
				root : document.documentElement,
				lastViewName : '',
			}
		},
		watch: {},
		computed: {},
		methods: {
			onEventBeforeMount( payload ){
				// set black text to sidebar
				// set white text on dark background to bodyInner
				if( payload.fromComponent == 'LuShopView' ){
					this.setCssVar( { appBodyInner__background : '--swatch__shopBackground' } )
					this.setCssVar( { appBodyInner__color : '--swatch__textWhite' } )

					this.setCssVar( { appNav__background : '--swatch__white' } )
					this.setCssVar( { appNav__color : '--swatch__textBlack' } )

					this.setCssVar( { appMobileNav__background : '--swatch__shopBackground' } )
					this.setCssVar( { appMobileNav__color : '--swatch__textWhite' } )
				}
				// set white text on dark background to bodyInner
				else if ( payload.fromComponent == 'LuProductView' ) {
					this.setCssVar( { appBodyInner__background : '--swatch__shopBackground' } )
					this.setCssVar( { appBodyInner__color : '--swatch__shopBackground' } )

					this.setCssVar( { appNav__background : '--swatch__white' } )
					this.setCssVar( { appNav__color : '--swatch__textBlack' } )

					this.setCssVar( { appMobileNav__background : '--swatch__shopBackground' } )
					this.setCssVar( { appMobileNav__color : '--swatch__shopBackground' } )
					this.setCssVar( { appMobileNav__color : '--swatch__textWhite' } )
				}
				// set black text on white background to bodyInner
				// set awardColor text to sidebar
				else{
					this.setCssVar( { appBodyInner__background : '--swatch__white' } )
					this.setCssVar( { appBodyInner__color : '--swatch__textBlack' } )

					this.setCssVar( { appNav__background : '--swatch__white' } )
					this.setCssVar( { appNav__color : '--swatch__awardColor' } )

					this.setCssVar( { appMobileNav__background : '--swatch__white' } )
					this.setCssVar( { appMobileNav__color : '--swatch__awardColor' } )
				}

				this.setCssVar( { appLoadingSpinner__opacity : 1 } )

			},
			onEventMounted( payload ){},
			onEventReady( payload ){
				// set vibrantColor to nav + bodyInner
				if( payload.fromComponent == 'LuProductView' ){
					this.setCssVar( { appBodyInner__color : payload.vibrantColor } )

					this.setCssVar( { appNav__color : payload.vibrantColor } )

					this.setCssVar( { appMobileNav__color : payload.vibrantColor } )
				}

				this.setCssVar( { appLoadingSpinner__opacity : 0 } )
			},
			onEventBeforeDestroy( payload ){
				const currentViewName = payload.fromComponent
				const lastViewName = this.lastViewName

				// wenn zwei schwarze hintergründe aufeinander treffen
				// soll es zwischendrin nicht weiss von body blitzen,
				// deswegen wird der schwarz gefärbt
				if( currentViewName == 'LuShopView' ||
					currentViewName == 'LuProductView' ){

					if( lastViewName == 'LuShopView' ||
						lastViewName == 'LuProductView' ){

						this.setCssVar( { appBody__background : '--swatch__shopBackground' } )

					}
					else{

						this.setCssVar( { appBody__background : '--swatch__white' } )

					}

				}
				else{

					this.setCssVar( { appBody__background : '--swatch__white' } )

				}

				/*
				console.log('');
				console.log('onEventBeforeDestroy');
				console.log('	currentViewName:', payload.fromComponent);
				console.log('	lastViewName:', this.lastViewName);
				*/
			},

			setCssVar( pair ){
				const root     = this.root
				const key      = Object.keys(pair)[0]
				const value    = Object.values(pair)[0]
				const property = this._.startsWith( value, '--' ) ? getComputedStyle(document.documentElement).getPropertyValue( value ) : value;

				if( this.debugLog ){
					console.log('	key      ', key);
					console.log('	value    ', value);
					console.log('	property ', property);
					console.log('	------');
				}

				root.style.setProperty('--' + key, property);
			}
		},
		created() {},
		mounted() {
			//this.setCssVar( { duration__tint : '2s' } )
			this.setCssVar( { duration__tint : '0.2s' } )

			EventBus.$on('ViewEvent', (payload) => {
				//console.log('')
				//console.log('ViewEvent')
				//console.log('	payload', payload)

				if( payload.eventName == 'beforeMount' ){
					this.onEventBeforeMount( payload )
				}
				if( payload.eventName == 'mounted' ){
					this.onEventMounted( payload )
				}
				if( payload.eventName == 'ready' ){
					this.onEventReady( payload )
				}
				if( payload.eventName == 'beforeDestroy' ){
					this.lastViewName = payload.fromComponent
					this.onEventBeforeDestroy( payload )
				}
			})
		},
	}
</script>

<style lang="less">
	@import "../less/vars.less";
	@import "../less/mixins.less";
	@import (reference) "../less/atoms.less";

	// vars
	:root {
		--swatch__white              : @swatches[white];
		--swatch__textWhite          : @swatches[textWhite];
		--swatch__textBlack          : @swatches[textBlack];
		--swatch__awardColor         : @swatches[awardColor];
		--swatch__shopBackground     : @swatches[shopBackground];
		--swatch__mario              : @swatches[awardColor];

		--duration__tint             : 0s;
		--appLoadingSpinner__opacity : 0;
	}
	// map vars to properties
	:root {
		--appBody__background      : var(--swatch__white);
		--appBody__color           : var(--swatch__textBlack);

		--appBodyInner__background : var(--swatch__white);
		--appBodyInner__color      : var(--swatch__textBlack);

		--appNav__background       : var(--swatch__white);
		--appNav__color            : var(--swatch__awardColor);

		--appMobileNav__background : var(--swatch__white);
		--appMobileNav__color      : var(--swatch__awardColor);
	}
	// map properties to selectors
	.app {
		.app__nav,
		.app__mobileNav,
		.app__bodyInner {
			transition:
				background-color var(--duration__tint) ease,
				color 			 var(--duration__tint) ease;
		}
		.app__bodyInner {
			transition:
				opacity 		 var(--duration__tint) ease;
		}

		.app__nav {
			background-color: var(--appNav__background);
			color: var(--appNav__color);
		}
		.app__mobileNav {
			background-color: var(--appMobileNav__background);
			color: var(--appMobileNav__color);
		}
		.app__body {
			background-color: var(--appBody__background);
			color: var(--appBody__color);
		}
		.app__bodyInner {
			background-color: var(--appBodyInner__background);
			color: var(--appBodyInner__color);

			//@transitionDelay : 0s;

			// router view transition
			&.fade-enter-active,
			&.fade-leave-active {
				transition-duration: var(--duration__tint);
				transition-property: opacity;
				transition-timing-function: ease;
			}
			&.fade-enter-active {
				//transition-delay: var(--duration__tint) !important;
			}
			&.fade-enter,
			&.fade-leave-active {
				opacity: 0;
				//transition-delay: @transitionDelay !important;
			}
		}
		.app__loadingSpinner {
			opacity: var(--appLoadingSpinner__opacity);
			//opacity: 1 !important;
		}

	}

	@media @mediaQuery__xl {}
	@media @mediaQuery__lg {}
	@media @mediaQuery__dt {}
	@media @mediaQuery__md {}
	@media @mediaQuery__sm {}
	@media @mediaQuery__xs {}
</style>
