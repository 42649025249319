<!--

	Formates a given string or number to currency.
	Reactes on language (de or en).
	Echoes html string.

	Usage examples:
		<LuPrice
			:noFontClasses="true"
			price="23,78"
		></LuPrice>
		<LuPrice
			price="3,78123"
		></LuPrice>

-->

<template>
	<span class="LuPrice">
		<span class="LuPrice__inner" :class="innerClasses" v-html="priceHtml"></span>
	</span>
</template>

<script>
	// @ is an alias to /src
	//import DevInfos from '@/components/DevInfos.vue'
	import EventBus from '@/helper/EventBus.js'

	export default {
		name: 'LuPrice',
		components: {},
		mixins: [],
		props: {
			noFontClasses: [ Boolean ],
			price: [ Number, String ],
		},
		data() {
			return {}
		},
		watch: {
			someVar: {
				handler: function( to, from ) {
				},
				deep: true,
			}
		},
		computed: {
			innerClasses(){
				let classes = []

				if( !this.noFontClasses ){
					 classes.push('font')
					 classes.push('font--mono')
				}

				return classes
			},
			priceEur(){
				const currentLang = this.$root.currentLang
				const locales = (currentLang == 'de' ? "de-DE" : "en-US");

				// format price as currency
				let ouputPrice = this.price
					ouputPrice = new Intl.NumberFormat(locales, { style: 'currency', currency: 'USD' }).format(ouputPrice)
					ouputPrice = ouputPrice.replace('$', '')
					ouputPrice = ouputPrice.replace('€', '')
					ouputPrice = this._.trim( ouputPrice )

				return ouputPrice
			},
			priceHtml(){
				const currentLang = this.$root.currentLang
				const priceEur = this.priceEur
				const centDivider = (currentLang == 'de' ? "," : ".");
				const groupDivider = (currentLang == 'de' ? "." : ",");

				let outputString = priceEur
					outputString = outputString.replace(centDivider, '<span class="LuPrice__centDivider">'+centDivider+'</span>')
					outputString = outputString.replace(groupDivider, '<span class="LuPrice__groupDivider">'+groupDivider+'</span>')

				return outputString + ' <span class="LuPrice__currencyString">€</span>'
			}
		},
		methods: {},
		created() {},
		mounted() {},
	}
</script>

<style lang="less">
	@import "../less/vars.less";
	@import "../less/mixins.less";

	.LuPrice {
		position: relative;
		white-space: nowrap;

		&__inner {
			//display: inherit;
		}

		&__centDivider,
		&__groupDivider,
		&__currencyString {
			display: inline-block;
			text-align: center;
		}
		&__centDivider {
			[showborders5] & { .outline( red ) };
			margin: 0 -0.08em;
		}
		&__groupDivider {
			[showborders5] & { .outline( blue ) };
			margin: 0 -0.08em;
		}
		&__currencyString {
			[showborders5] & { .outline( green ) };
			margin-left: -0.25em;
		}
	}
</style>
