<!--


	<MhVibrantPalette :palette="post.acf.vibrantPalette"></MhVibrantPalette>


-->

<template>
	<div class="mhVibrant">
		<div class="mhVibrant__palette">
			<!-- {{ _.round( Color(palette.vibrant).luminosity(), 2) }} <br />-->
			<div class="mhVibrant__swatch" :style="{ backgroundColor : palette.vibrant}">
				{{ _.round( Color(palette.vibrant).contrast( Color( backgroundColor ) ), 2 ) }} <br />
			</div>
			<div class="mhVibrant__swatch" :style="{ backgroundColor : palette.lightVibrant}">
				{{ _.round( Color(palette.lightVibrant).contrast( Color(backgroundColor) ), 2 ) }} <br />
			</div>
			<div class="mhVibrant__swatch" :style="{ backgroundColor : palette.darkVibrant}"></div>
			<div class="mhVibrant__swatch" :style="{ backgroundColor : palette.muted}"></div>
			<div class="mhVibrant__swatch" :style="{ backgroundColor : palette.lightMuted}"></div>
			<div class="mhVibrant__swatch" :style="{ backgroundColor : palette.darkMuted}"></div>
		</div>
		<div class="mhVibrant__results">
			<br />
			<template v-if="getContrast( palette.vibrant, backgroundColor ) > minContrast">
				✅ {{ getContrast( palette.vibrant, backgroundColor ) }}
			</template>
			<template v-else>
				❌ {{ getContrast( palette.vibrant, backgroundColor ) }}
			</template>
			<br />
			<br />
			<template>
				Farbe auf schwarz: <br />
				<div class="mhVibrant__palette">
					<div @click="doEmitColor($event)" class="mhVibrant__swatch" :style="{ backgroundColor : palette.vibrant}">
						{{ _.round( Color( palette.vibrant ).contrast( Color( backgroundColor ) ), 2 ) }} <br />
					</div>
					<div @click="doEmitColor($event)" class="mhVibrant__swatch" :style="{ backgroundColor : Color( palette.vibrant ).lighten( 0.06 ) }">
						{{ _.round( Color( palette.vibrant ).lighten( 0.06 ).contrast( Color( backgroundColor ) ), 2 ) }} <br />
					</div>
					<div @click="doEmitColor($event)" class="mhVibrant__swatch" :style="{ backgroundColor : Color( palette.vibrant ).lighten( 0.12 ) }">
						{{ _.round( Color( palette.vibrant ).lighten( 0.12 ).contrast( Color( backgroundColor ) ), 2 ) }} <br />
					</div>

					<div @click="doEmitColor($event)" class="mhVibrant__swatch" :style="{ backgroundColor : Color( palette.vibrant ).lighten( 0.18 ) }">
						{{ _.round( Color( palette.vibrant ).lighten( 0.18 ).contrast( Color( backgroundColor ) ), 2 ) }} <br />
					</div>
					<div @click="doEmitColor($event)" class="mhVibrant__swatch" :style="{ backgroundColor : Color( palette.vibrant ).lighten( 0.24 ) }">
						{{ _.round( Color( palette.vibrant ).lighten( 0.24 ).contrast( Color( backgroundColor ) ), 2 ) }} <br />
					</div>
					<div @click="doEmitColor($event)" class="mhVibrant__swatch" :style="{ backgroundColor : Color( palette.vibrant ).lighten( 0.30 ) }">
						{{ _.round( Color( palette.vibrant ).lighten( 0.30 ).contrast( Color( backgroundColor ) ), 2 ) }} <br />
					</div>

					<div @click="doEmitColor($event)" class="mhVibrant__swatch" :style="{ backgroundColor : Color( palette.vibrant ).lighten( 0.36 ) }">
						{{ _.round( Color( palette.vibrant ).lighten( 0.36 ).contrast( Color( backgroundColor ) ), 2 ) }} <br />
					</div>
					<div @click="doEmitColor($event)" class="mhVibrant__swatch" :style="{ backgroundColor : Color( palette.vibrant ).lighten( 0.42 ) }">
						{{ _.round( Color( palette.vibrant ).lighten( 0.42 ).contrast( Color( backgroundColor ) ), 2 ) }} <br />
					</div>
					<div @click="doEmitColor($event)" class="mhVibrant__swatch" :style="{ backgroundColor : Color( palette.vibrant ).lighten( 0.48 ) }">
						{{ _.round( Color( palette.vibrant ).lighten( 0.48 ).contrast( Color( backgroundColor ) ), 2 ) }} <br />
					</div>

					<div @click="doEmitColor($event)" class="mhVibrant__swatch" :style="{ backgroundColor : palette.vibrant}">
						{{ _.round( Color(palette.vibrant).contrast( Color( backgroundColor ) ), 2 ) }} •••<br />
					</div>
					<div @click="doEmitColor($event)" class="mhVibrant__swatch" :style="{ backgroundColor : Color( palette.vibrant ).darken( 0.06 ) }">
						{{ _.round( Color( palette.vibrant ).darken( 0.06 ).contrast( Color( backgroundColor ) ), 2 ) }} <br />
					</div>
					<div @click="doEmitColor($event)" class="mhVibrant__swatch" :style="{ backgroundColor : Color( palette.vibrant ).darken( 0.12 ) }">
						{{ _.round( Color( palette.vibrant ).darken( 0.12 ).contrast( Color( backgroundColor ) ), 2 ) }} <br />
					</div>

					<div @click="doEmitColor($event)" class="mhVibrant__swatch" :style="{ backgroundColor : Color( palette.vibrant ).darken( 0.18 ) }">
						{{ _.round( Color( palette.vibrant ).darken( 0.18 ).contrast( Color( backgroundColor ) ), 2 ) }} <br />
					</div>
					<div @click="doEmitColor($event)" class="mhVibrant__swatch" :style="{ backgroundColor : Color( palette.vibrant ).darken( 0.24 ) }">
						{{ _.round( Color( palette.vibrant ).darken( 0.24 ).contrast( Color( backgroundColor ) ), 2 ) }} <br />
					</div>
					<div @click="doEmitColor($event)" class="mhVibrant__swatch" :style="{ backgroundColor : Color( palette.vibrant ).darken( 0.30 ) }">
						{{ _.round( Color( palette.vibrant ).darken( 0.30 ).contrast( Color( backgroundColor ) ), 2 ) }} <br />
					</div>

					<div @click="doEmitColor($event)" class="mhVibrant__swatch" :style="{ backgroundColor : Color( palette.vibrant ).darken( 0.36 ) }">
						{{ _.round( Color( palette.vibrant ).darken( 0.36 ).contrast( Color( backgroundColor ) ), 2 ) }} <br />
					</div>
					<div @click="doEmitColor($event)" class="mhVibrant__swatch" :style="{ backgroundColor : Color( palette.vibrant ).darken( 0.42 ) }">
						{{ _.round( Color( palette.vibrant ).darken( 0.42 ).contrast( Color( backgroundColor ) ), 2 ) }} <br />
					</div>
					<div @click="doEmitColor($event)" class="mhVibrant__swatch" :style="{ backgroundColor : Color( palette.vibrant ).darken( 0.48 ) }">
						{{ _.round( Color( palette.vibrant ).darken( 0.48 ).contrast( Color( backgroundColor ) ), 2 ) }} <br />
					</div>
				</div>
				<br />
				Farbe auf weiss: <br />
				<div class="mhVibrant__palette">
					<div @click="doEmitDarkColor($event)" class="mhVibrant__swatch" :style="{ backgroundColor : palette.vibrant}">
						{{ _.round( Color( palette.vibrant ).contrast( Color( 'white' ) ), 2 ) }} <br />
					</div>
					<div @click="doEmitDarkColor($event)" class="mhVibrant__swatch" :style="{ backgroundColor : Color( palette.vibrant ).lighten( 0.06 ) }">
						{{ _.round( Color( palette.vibrant ).lighten( 0.06 ).contrast( Color( 'white' ) ), 2 ) }} <br />
					</div>
					<div @click="doEmitDarkColor($event)" class="mhVibrant__swatch" :style="{ backgroundColor : Color( palette.vibrant ).lighten( 0.12 ) }">
						{{ _.round( Color( palette.vibrant ).lighten( 0.12 ).contrast( Color( 'white' ) ), 2 ) }} <br />
					</div>

					<div @click="doEmitDarkColor($event)" class="mhVibrant__swatch" :style="{ backgroundColor : Color( palette.vibrant ).lighten( 0.18 ) }">
						{{ _.round( Color( palette.vibrant ).lighten( 0.18 ).contrast( Color( 'white' ) ), 2 ) }} <br />
					</div>
					<div @click="doEmitDarkColor($event)" class="mhVibrant__swatch" :style="{ backgroundColor : Color( palette.vibrant ).lighten( 0.24 ) }">
						{{ _.round( Color( palette.vibrant ).lighten( 0.24 ).contrast( Color( 'white' ) ), 2 ) }} <br />
					</div>
					<div @click="doEmitDarkColor($event)" class="mhVibrant__swatch" :style="{ backgroundColor : Color( palette.vibrant ).lighten( 0.30 ) }">
						{{ _.round( Color( palette.vibrant ).lighten( 0.30 ).contrast( Color( 'white' ) ), 2 ) }} <br />
					</div>

					<div @click="doEmitDarkColor($event)" class="mhVibrant__swatch" :style="{ backgroundColor : Color( palette.vibrant ).lighten( 0.36 ) }">
						{{ _.round( Color( palette.vibrant ).lighten( 0.36 ).contrast( Color( 'white' ) ), 2 ) }} <br />
					</div>
					<div @click="doEmitDarkColor($event)" class="mhVibrant__swatch" :style="{ backgroundColor : Color( palette.vibrant ).lighten( 0.42 ) }">
						{{ _.round( Color( palette.vibrant ).lighten( 0.42 ).contrast( Color( 'white' ) ), 2 ) }} <br />
					</div>
					<div @click="doEmitDarkColor($event)" class="mhVibrant__swatch" :style="{ backgroundColor : Color( palette.vibrant ).lighten( 0.48 ) }">
						{{ _.round( Color( palette.vibrant ).lighten( 0.48 ).contrast( Color( 'white' ) ), 2 ) }} <br />
					</div>

					<div @click="doEmitDarkColor($event)" class="mhVibrant__swatch" :style="{ backgroundColor : palette.vibrant}">
						{{ _.round( Color(palette.vibrant).contrast( Color( 'white' ) ), 2 ) }} ••• <br />
					</div>
					<div @click="doEmitDarkColor($event)" class="mhVibrant__swatch" :style="{ backgroundColor : Color( palette.vibrant ).darken( 0.06 ) }">
						{{ _.round( Color( palette.vibrant ).darken( 0.06 ).contrast( Color( 'white' ) ), 2 ) }} <br />
					</div>
					<div @click="doEmitDarkColor($event)" class="mhVibrant__swatch" :style="{ backgroundColor : Color( palette.vibrant ).darken( 0.12 ) }">
						{{ _.round( Color( palette.vibrant ).darken( 0.12 ).contrast( Color( 'white' ) ), 2 ) }} <br />
					</div>

					<div @click="doEmitDarkColor($event)" class="mhVibrant__swatch" :style="{ backgroundColor : Color( palette.vibrant ).darken( 0.18 ) }">
						{{ _.round( Color( palette.vibrant ).darken( 0.18 ).contrast( Color( 'white' ) ), 2 ) }} <br />
					</div>
					<div @click="doEmitDarkColor($event)" class="mhVibrant__swatch" :style="{ backgroundColor : Color( palette.vibrant ).darken( 0.24 ) }">
						{{ _.round( Color( palette.vibrant ).darken( 0.24 ).contrast( Color( 'white' ) ), 2 ) }} <br />
					</div>
					<div @click="doEmitDarkColor($event)" class="mhVibrant__swatch" :style="{ backgroundColor : Color( palette.vibrant ).darken( 0.30 ) }">
						{{ _.round( Color( palette.vibrant ).darken( 0.30 ).contrast( Color( 'white' ) ), 2 ) }} <br />
					</div>

					<div @click="doEmitDarkColor($event)" class="mhVibrant__swatch" :style="{ backgroundColor : Color( palette.vibrant ).darken( 0.36 ) }">
						{{ _.round( Color( palette.vibrant ).darken( 0.36 ).contrast( Color( 'white' ) ), 2 ) }} <br />
					</div>
					<div @click="doEmitDarkColor($event)" class="mhVibrant__swatch" :style="{ backgroundColor : Color( palette.vibrant ).darken( 0.42 ) }">
						{{ _.round( Color( palette.vibrant ).darken( 0.42 ).contrast( Color( 'white' ) ), 2 ) }} <br />
					</div>
					<div @click="doEmitDarkColor($event)" class="mhVibrant__swatch" :style="{ backgroundColor : Color( palette.vibrant ).darken( 0.48 ) }">
						{{ _.round( Color( palette.vibrant ).darken( 0.48 ).contrast( Color( 'white' ) ), 2 ) }} <br />
					</div>
				</div>
			</template>
		</div>
	</div>
</template>

<script>
	// @ is an alias to /src
	//import DevInfos from '@/components/DevInfos.vue'
	import EventBus from '@/helper/EventBus.js'
	const Color = require('color');

	export default {
		name: 'MhVibrantPalette',
		components: {},
		mixins: [],
		props: {
			palette: [Object],
		},
		data() {
			return {
				backgroundColor: 'rgb(37, 37, 37)',
				minContrast : 4,
				Color : Color,
			}
		},
		watch: {},
		computed: {},
		methods: {
			doEmitColor(e){
				const style = window.getComputedStyle( e.target )
				const backgroundColor = style.backgroundColor

				this.$emit('trashSetVibrantColor', backgroundColor)
			},
			doEmitDarkColor(e){
				const style = window.getComputedStyle( e.target )
				const backgroundColor = style.backgroundColor

				EventBus.$emit('vibrantChanged', backgroundColor)
				//this.$emit('trashSetVibrantColor', backgroundColor)
			},
			getContrast( fgColor, bgColor ) {
				let contrast = Color(fgColor).contrast( Color(bgColor) )
					contrast = this._.round( contrast, 2 )

				return contrast
			}
		},
		created() {},
		mounted() {},
	}
</script>

<style lang="less" scoped>
	.mhVibrant {
		z-index: 100;

		&__palette {
			display: flex;
			justify-content: space-between;
			flex-wrap: wrap;
			line-height: 1.2em;
			color: black;
		}
		&__swatch {
			padding: 0.1em;
			height: 1.5em; width: 32%;
			margin-bottom: 2%;
		}
		&__results {
			color: white;
		}
	}
</style>
