<!--

	Was macht diese Componente?
	Wofür ist die Compoente da?

	Welche $props gibt es?

	Beispiel Code:
		<MhMenuToggleButton
			:label="'Menü'"
		></MhMenuToggleButton>

	2019-06-08	init

-->

<template>
	<a class="MhMenuToggleButton" :class="elmClasses" role="button" :aria-pressed="ariaPressed" @click="elmClicked">
		<div class="MhMenuToggleButton__inner">
			<div class="MhMenuToggleButton__label">{{label}}</div>
			<div class="MhMenuToggleButton__lines" :class="linesElmClasses">
				<div class="MhMenuToggleButton__line MhMenuToggleButton__line--1"></div>
				<div class="MhMenuToggleButton__line MhMenuToggleButton__line--2"></div>
			</div>
		</div>
	</a>
</template>

<script>
	// @ is an alias to /src
	//import DevInfos from '@/components/DevInfos.vue'
	import EventBus from '@/helper/EventBus.js'

	export default {
		name: 'MhMenuToggleButton',
		components: {},
		mixins: [],
		props: {
			//imageObject: [Object, Boolean],
			label: [String, Boolean],
		},
		data() {
			return {
				isActive : false,
			}
		},
		watch: {
			someVar: {
				handler: function( to, from ) {
				},
				deep: true,
			}
		},
		computed: {
			app() {
				return this.$root.$children[0]
			},
			elmClasses(){
				let classes = []

				if( this.isActive ) classes.push("MhMenuToggleButton--isActive")

				return classes
			},
			linesElmClasses(){
				let classes = []

				return classes
			},
			ariaPressed(){
				return this.isActive ? 'true' : 'false'
			}
		},
		methods: {
			elmClicked(){
				this.isActive = !this.isActive

				this.$emit('stateChanged', this.isActive)
			},
		},
		created() {},
		mounted() {},
	}
</script>

<style lang="less">
	@import "../../less/vars.less";
	@import "../../less/mixins.less";
	@import (reference) "../../less/atoms.less";

	@btn__height            : 2rem;
	@btn__width             : 2rem;
	@btn__paddingLeft       : 0rem;
	@btn__paddingRight      : 0rem;
	@btn__paddingTop        : 0.75rem;
	@btn__paddingBottom     : 0.75rem;
	@btn__stroke            : 2px;
	@btn__animationDuration : 1s;

	[showBorders1] .MhMenuToggleButton  {
		background-color: fade( red, 20 );
	}

	.MhMenuToggleButton {
		position: relative;
		height: @btn__height;
		width: @btn__width;
		min-height: 1em;
		min-width: 1em;
		display: flex;

		padding-top: @btn__paddingTop;
		padding-bottom: @btn__paddingBottom;
		padding-left: @btn__paddingLeft;
		padding-right: @btn__paddingRight;

		color: currentColor;

		.MhMenuToggleButton__inner {
			position: relative;
			display: flex;
			flex-grow: 1;
		}

		.MhMenuToggleButton__label {
			//background-color: fade( green, 30 );

			position: absolute;
			top: 0; left: 0%; right: 0; bottom: 0;
			//color: transparent;
		}
		.MhMenuToggleButton__lines {
			//background-color: fade( green, 20 );

			position: relative;
			display: flex;
			flex-grow: 1;
		}
	}



	.MhMenuToggleButton__lines--isActive {}
	.MhMenuToggleButton__line {
		//display: none;
		position: absolute;
		left: 0; right: 0;
		height: @btn__stroke;
		background-color: currentColor;
		//transition: transform @btn__animationDuration ease;
		transform: rotate(0deg);
	}
	.MhMenuToggleButton__line--1 {
		top: calc( 0% - @btn__stroke/2 );

		.MhMenuToggleButton--isActive & {
			top: calc( 50% - @btn__stroke/2 );
			transform: rotate( -45deg );
		}
	}
	.MhMenuToggleButton__line--2 {
		top: calc( 100% - @btn__stroke/2 );

		.MhMenuToggleButton--isActive & {
			top: calc( 50% - @btn__stroke/2 );
			transform: rotate( 45deg );
		}
	}

	@keyframes example {
		0%   {
			background-color: red;
		}
		25%  {
			background-color: yellow;
		}
		50%  {
			background-color: blue;
		}
		100% {
			background-color: green;
		}
	}

	@media @mediaQuery__xs {}
	@media @mediaQuery__sm {}
	@media @mediaQuery__md {}
	@media @mediaQuery__dt {}
	@media @mediaQuery__lg {}
	@media @mediaQuery__xl {}
</style>
