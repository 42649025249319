<template>
	<form class="mhSearchForm" @submit="onSubmit">
		<input  class="mhSearchForm__input"
				type="text" spellcheck="false"
				v-model="searchString"
				:placeholder="placeholder" />
		<button class="mhSearchForm__btn">Go</button>
	</form>
</template>

<script>
	// @ is an alias to /src
	//import DevInfos from '@/components/DevInfos.vue'
	import EventBus from '@/helper/EventBus.js'

	export default {
		name: 'MhSearchForm',
		components: {},
		mixins: [],
		props: {
			placeholder: [String],
		},
		data() {
			return {
				searchString: '',
			}
		},
		watch: {
			'$route.params.searchString' (to, from){	// is there a seach query in the url?
				const newSearchString = this.$route.params.searchString

				//console.log('watcher: $route.params.searchString changed to:', newSearchString)

				this.searchString = newSearchString
			}
		},
		computed: {},
		methods: {
			onSubmit(e) {
				// if triggered on empty field > blur the field
				if( !e.target.value ) e.target.blur()

				// no searchString > change route to SearchViewEmpty
				if( !this.searchString  ){
					this.$router.push({
						name: 'SearchViewEmpty',
					})
				}
				// if searchString > change route to SearchView
				else{
					this.$router.push({
						name: 'SearchView',
						params: {
							searchString: this.searchString,
						},
					})
				}

				e.preventDefault()
			},
		},
		created() {},
		mounted() {},
	}
</script>

<style lang="less">
	@import "../../less/vars.less";
	@import "../../less/mixins.less";
	@import (reference) "../../less/atoms.less";

	.mhSearchForm {
		width: 100%;
		display: flex;
		//overflow: hidden;

		&__input {
			background-color: transparent;
			transition: opacity 0.1s ease;
			flex-grow: 1;

			line-height: 1em;
			outline: none;
			border: none;
			color: inherit;
			opacity: 0.75;

			&::placeholder {
				transition: transform 0.4s ease;
				opacity: 0.5;
				//color: fade( black, 75 );
				color: currentColor;
				transform: translateX( 0% );
			}
			&:focus {
				outline: none;
				opacity: 1;

				&::placeholder {
					transform: translateX( -50% );
					//opacity: 0.3;
				}
			}
		}
		&__btn {
			//background-color: fade(red, 30) !important;

			.reset;
			.reset--button;
			.icon;
			.icon--lense;
		}
	}
</style>
