// https://github.com/AlexandreBonaventure/vue-mq
//
// der wert der breakpoints heißt: "dieser bp geht bis"

export default {
	defaultBreakpoint: 'md',
	breakpoints: {		// add one pixel more to css vars !!
		xs   : 321, 	// iPhone 5 / SE
		sm   : 651,
		md   : 1025,	// iPad
		dt   : 1501,
		lg   : 1921,
		xl   : Infinity,
	}
}