<!--

	Was macht diese Componente?
	Wofür ist die Compoente da?

	Welche $props gibt es?

	Beispiel Code:
		<MhSmartHeader
			:scrollElmSelector="'.mhView__inner'"
			:enable="true"
		></MhSmartHeader>

	TODO: prevent logs with debugLog var

	2019-07-17	init

-->

<template>
	<div class="MhSmartHeader" :class="elmClasses" :style="elmStyles">
		<div class="MhSmartHeader__inner" :style="innerStyles">
			<!--
			MhSmartHeader <br />
			• find parent scroll elm<br />
			• bind scroll listener on create<br />
			• unbind scroll listener on destroy<br />
			• detect scrolldown + scrollup<br />
			• slide in on scrollup<br />
			• slide out on scrollup<br />
			<br />
			<br />
			-->
			<slot></slot>
		</div>
		<div class="MhSmartHeader__rectHelper" ref="rectHelper"></div>
		<div class="MhSmartHeader__debug">
			currentScrollTop: {{currentScrollTop}}<br />
			scrollDirection: {{scrollDirection}}<br />
			inView: {{inView}} <input type="checkbox" :checked="inView" /><br />
			isFixed: {{isFixed}} <input type="checkbox" :checked="isFixed" /><br />
			elmStyles: {{elmStyles}}<br />
			innerStyles: {{innerStyles}}<br />
		</div>
	</div>
</template>

<script>
	// @ is an alias to /src
	//import EventBus from '@/helper/EventBus.js'
	//import DevInfos from '@/components/DevInfos.vue'

	export default {
		name: 'MhSmartHeader',
		components: {},
		mixins: [],
		props: {
			scrollElmSelector: [String, Boolean],
			enable: [Boolean],
		},
		data() {
			return {
				scrollElm          : null,
				prevScrollTop      : null,
				currentScrollTop   : null,
				scrollDirection    : '',
				inView             : null,
				elmStyles          : {},
				innerStyles        : {},
				transitionDuration : 0.2,
				showDebugPanel     : false,
				isFixed			   : false,
			}
		},
		watch: {
			'inView': {
				handler: function( to, from ) {
					//this.onInViewChanged( to )
					if( to ) this.onInView()
					if( !to ) this.onOutView()
				},
				deep: true,
			},
			'scrollDirection': {
				handler: function( to, from ) {
					//this.onScrollDirectionChanged( to )
					if( to == '↑' ) this.onScrollUp()
					if( to == '↓' ) this.onScrollDown()
				},
				deep: true,
			},
		},
		computed: {
			app() {
				return this.$root.$children[0]
			},
			elmClasses(){
				let classes = []

				return classes
			},
		},
		methods: {
			stickHeader( withTransition = true ) {
				//console.log('')
				//console.group('stickHeader()')
				//console.groupEnd()

				const rectElm         = this.$refs.rectHelper
				const rectElmBounding = rectElm.getBoundingClientRect()

				this.elmStyles.height = rectElmBounding.height + 'px'
				this.innerStyles.position = 'fixed'
				this.innerStyles.left = rectElmBounding.left + 'px'
				this.innerStyles.width = rectElmBounding.width + 'px'

				if( withTransition ){
					this.innerStyles.top = -rectElmBounding.height + 'px'
					this.innerStyles.transition = 'top ' + this.transitionDuration + 's ease'

					window.requestAnimationFrame( () => {
						this.innerStyles.top = '0px'
					})
				}
				else{
					this.innerStyles.top = '0px'
				}
			},
			releaseHeader() {
				//console.log('')
				//console.group('releaseHeader()')
				//console.groupEnd()

				this.elmStyles = {}
				this.innerStyles = {}
			},

			onScrollTop() {
				//console.log('')
				//console.group('onScrollTop()')
				//console.groupEnd()

				const rectElm         = this.$refs.rectHelper
				const rectElmBounding = rectElm.getBoundingClientRect()

				if( !this.isFixed ) this.releaseHeader()

				/*
				this.elmStyles = {}
				this.innerStyles = {}
				*/
			},
			onScrollUp() {
				//console.log('')
				//console.group('onScrollUp()')
				//console.groupEnd()

				const rectElm         = this.$refs.rectHelper
				const rectElmBounding = rectElm.getBoundingClientRect()

				if( !this.isFixed ) this.stickHeader()

				/*
				this.elmStyles.height = rectElmBounding.height + 'px'
				this.innerStyles.position = 'fixed'
				this.innerStyles.top = -rectElmBounding.height + 'px'
				this.innerStyles.left = rectElmBounding.left + 'px'
				this.innerStyles.width = rectElmBounding.width + 'px'
				this.innerStyles.transition = 'top ' + this.transitionDuration + 's ease'

				window.requestAnimationFrame( () => {
					this.innerStyles.top = '0px'
				})
				*/
			},
			onScrollDown() {
				//console.log('')
				//console.group('onScrollDown()')
				//console.groupEnd()

				const rectElm         = this.$refs.rectHelper
				const rectElmBounding = rectElm.getBoundingClientRect()
				const scrollTop       = this.scrollElm ? this.scrollElm.scrollTop : null

				if( !this.isFixed ){

					this.innerStyles.top = -rectElmBounding.height + 'px'

					setTimeout( () => {
						this.releaseHeader()

						/*
						this.elmStyles = {}
						this.innerStyles = {}
						*/
					}, this.transitionDuration * 1000 )

				}
			},
			onInView() {
				//console.log('')
				//console.group('onInView()')
				//console.groupEnd()
			},
			onOutView() {
				//console.log('')
				//console.group('onOutView()')
				//console.groupEnd()
			},

			scrollHandler( e = null ){
				const currentScrollTop = this.scrollElm.scrollTop
				const scrollDelta      = currentScrollTop - this.prevScrollTop
				const elmBounding      = this.$el.getBoundingClientRect()
				const elmHeight        = elmBounding.height
				const elmTop           = elmBounding.top
				const inView           = ( elmHeight + elmTop ) > 0 ? true : false
				let   scrollDirection  = null
				const debugLog         = false

				// set direction but not on mount where no scroll had happend
				if( this.prevScrollTop !== null ) {
					scrollDirection  = scrollDelta > 0 ? '↓' : '↑'

					if( currentScrollTop == 0 ) this.onScrollTop()
				}

				if( debugLog ){
					//console.log('')
					console.groupCollapsed('scrollHandler()')
					console.log('currentScrollTop', currentScrollTop)
					console.log('scrollDelta', scrollDelta)
					console.log('scrollDirection', scrollDirection)
					console.log('inView', inView)
					console.groupEnd()
				}

				this.prevScrollTop = currentScrollTop
				this.currentScrollTop = currentScrollTop
				this.scrollDirection = scrollDirection
				this.inView = inView
			},
		},
		created() {},
		mounted() {
			this.scrollElm = document.querySelector( this.scrollElmSelector )

			//console.log('')
			//console.groupCollapsed('mounted()')
			//console.log('scrollElmSelector:', this.scrollElmSelector)
			//console.log('scrollElm:', this.scrollElm)
			//console.groupEnd()

			if( this.scrollElm ){
				this.scrollHandler() // manuell trigger handler on mount

				this.scrollElm.addEventListener('scroll', this.scrollHandler) // bind handler on event
			}
			if( this.scrollElm && this.isFixed ){
				this.stickHeader( false )
			}
		},
		destroyed() {
			//console.log('')
			//console.group('destroy()')
			//console.groupEnd()

			if( this.scrollElm ) this.scrollElm.removeEventListener('scroll', this.scrollHandler)
		},
	}
</script>

<style lang="less">
	@import "../../less/vars.less";
	@import "../../less/mixins.less";
	@import (reference) "../../less/atoms.less";

	.MhSmartHeader {
		//background-color: fade(red, 20);

		position: relative;
		z-index: 10;
	}
	.MhSmartHeader__rectHelper {
		position: absolute;
		top: 0; left: 0; right: 0; bottom: 0;
		pointer-events: none;
	}
	.MhSmartHeader__inner {
		//background-color: fade(yellow, 60);
		z-index: 1;
		transform: translateZ(1px); // iOS needs this to render the bar above the book teasers
	}
	.MhSmartHeader__debug {
		display: none;
		background-color: fade(red, 80);

		position: fixed;
		bottom: 2rem; left: 1rem;
		z-index: 100;
		padding: 0.25em;
		font-size: 12px;
		line-height: 15px;
	}

	@media @mediaQuery__xs {}
	@media @mediaQuery__sm {}
	@media @mediaQuery__md {}
	@media @mediaQuery__dt {}
	@media @mediaQuery__lg {}
	@media @mediaQuery__xl {}
</style>
