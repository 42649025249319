<!--

	Was macht diese Componente?
	Wofür ist die Compoente da?

	Welche $props gibt es?

	Beispiel Code:
		<LuEventsWidget
			:events="[{}, {}, {}]"
		></LuEventsWidget>

	2019-07-05	init

-->

<template>
	<div class="LuEventsWidget contentModule" :class="elmClasses">
		<div class="LuEventsWidget__inner border border--top border--bottom border--strong">
			<h3 class="vSpace vSpace--LuEventsWidget font font--bold">
				<span v-html="$t('title.currentEventsWidget')"></span>
				<br /><br />
			</h3>
			<ul class="reset reset--list">
				<li class="border border--top" v-for="post in events" :key="post.id">
					<h2 class="vSpace vSpace--LuEventsWidget">
						<router-link class="block link link--withArrow" :to="{ name: 'EventView', params : { slug : post.slug } }">
							<strong v-html="$root.getTranslatedAcfValue( post.acf, 'postTitle' )"></strong>
							<strong><LuDate :startDate="post.acf.startDate" :endDate="post.acf.endDate"></LuDate></strong>
							<div class="LuEventsWidget__location font font--sizeSmall" v-html="$root.getTranslatedAcfValue( post.acf, 'location' )"></div>
						 </router-link>
					</h2>
				</li>
			</ul>
			<div class="vSpace vSpace--LuEventsWidget border border--top">
				<router-link class="block link link--withArrow"
					:to="{ name: 'EventsView' }">
					{{ $t('button.allEvents') }}
				</router-link>
				<br v-if="$root.isSmallDevice" />
				<br />
			</div>
		</div>
	</div>
</template>

<script>
	// @ is an alias to /src
	//import DevInfos from '@/components/DevInfos.vue'
	import EventBus from '@/helper/EventBus.js'
	import LuDate from '@/components/LuDate.vue'

	export default {
		name: 'LuEventsWidget',
		components: {
			LuDate,
		},
		mixins: [],
		props: {
			//imageObject: [Object, Boolean],
			events: [Array, Boolean],
		},
		data() {
			return {
			}
		},
		watch: {},
		computed: {
			app() {
				return this.$root.$children[0]
			},
			elmClasses(){
				let classes = []

				if( this.$root.isSmallDevice ) classes.push('font--sizeMedium')

				return classes
			},
		},
		methods: {},
		created() {},
		mounted() {},
	}
</script>

<style lang="less">
	@import "../less/vars.less";
	@import "../less/mixins.less";

	.LuEventsWidget {
		position: relative;

		&__location:empty {
			display: none;
		}
		&__location {
			padding-top: 0.2em;
		}
	}

	@media @mediaQuery__xs {}
	@media @mediaQuery__sm {}
	@media @mediaQuery__md {}
	@media @mediaQuery__dt {}
	@media @mediaQuery__lg {}
	@media @mediaQuery__xl {}
</style>
