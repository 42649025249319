import Vue from 'vue'
import Router from 'vue-router'
import HomeView from '@/views/LuHome.view.vue'

Vue.use(Router)

// this delay is needed to let
// my route transition by smooth
// wait for restoring til the new route
// is proper fade in
const restorationDelay = 200

// Restores previous scroll position after popstate navigation
const scrollBehavior1 = ( to, from, savedPosition ) => {
	if( savedPosition ){
		return new Promise((resolve, reject) => {
			setTimeout(() => {
				resolve( savedPosition )
			}, restorationDelay)
		})
	}
	else{
		return new Promise((resolve, reject) => {
			setTimeout(() => {
				resolve( { x: 0, y: 0 } )
			}, restorationDelay)
		})
		//return { x: 0, y: 100 }
	}
}
const scrollBehavior2 = ( to, from, savedPosition ) => {
	if( savedPosition ) {
		return savedPosition
	}
	else{
		return { x: 0, y: 0 }
	}
}

// Handle scrollRestoration manual, stops browser from doing it
if('scrollRestoration' in window.history) {
	window.history.scrollRestoration = 'manual';
}

// setup router + define routes
const router = new Router({
	base: '/',
	scrollBehavior: scrollBehavior1,
	mode: 'history',
	linkActiveClass : 'routerLink--active',
	linkExactActiveClass : 'routerLink--exactActive',
	routes: [
		{
			path: '/',
			redirect: '/de/',
		},
		{
			path: '/:lang/',
			name: 'HomeView',
			component: HomeView,
			pathToRegexpOptions: { strict: true },
		},
		{
			path: "/:lang/search/:searchString/",
			name: 'SearchView',
			pathToRegexpOptions: { strict: true },
			component: () =>
				import ( /* webpackChunkName: "SearchView" */ '@/views/LuSearch.view.vue')
		},
		{
			path: "/:lang/search/",
			name: 'SearchViewEmpty',
			pathToRegexpOptions: { strict: true },
			component: () =>
				import ( /* webpackChunkName: "SearchView" */ '@/views/LuSearch.view.vue')
		},
		{
			path: "/:lang/events/",
			name: 'EventsView',
			pathToRegexpOptions: { strict: true },
			component: () =>
				import ( /* webpackChunkName: "EventsView" */ '@/views/LuEvents.view.vue')
		},
		{
			path: "/:lang/archive/",
			name: 'EventsArchiveView',
			pathToRegexpOptions: { strict: true },
			component: () =>
				import ( /* webpackChunkName: "EventsView" */ '@/views/LuEvents.view.vue')
		},
		{
			path: "/:lang/events/:slug/",
			name: 'EventView',
			pathToRegexpOptions: { strict: true },
			component: () =>
				import ( /* webpackChunkName: "EventView" */ '@/views/LuEvent.view.vue')
		},
		{
			path: "/:lang/shop/",
			name: 'ShopView',
			pathToRegexpOptions: { strict: true },
			component: () =>
				import ( /* webpackChunkName: "ShopView" */ '@/views/LuShop.view.vue')
		},
		{
			path: "/:lang/shop/:slug/",
			name: 'ProductView',
			pathToRegexpOptions: { strict: true },
			component: () =>
				import ( /* webpackChunkName: "ProductView" */ '@/views/LuProduct.view.vue')
		},
		{
			path: "/:lang/cart/",
			name: 'CartView',
			pathToRegexpOptions: { strict: true },
			component: () =>
				import ( /* webpackChunkName: "CartView" */ '@/views/LuCart.view.vue')
		},
		{
			path: "/:lang/cart",
			name: 'CartView2',
			pathToRegexpOptions: { strict: true },
			component: () =>
				import ( /* webpackChunkName: "CartView" */ '@/views/LuCart.view.vue')
		},
		{
			path: "/:lang/checkout/:slug/",
			name: 'CheckoutView',
			pathToRegexpOptions: { strict: true },
			component: () =>
				import ( /* webpackChunkName: "CartView" */ '@/views/LuCart.view.vue')
		},
		{
			path: "/:lang/checkout/:slug",
			name: 'CheckoutView2',
			pathToRegexpOptions: { strict: true },
			component: () =>
				import ( /* webpackChunkName: "CartView" */ '@/views/LuCart.view.vue')
		},
		{
			path: "/:lang/:slug/",
			name: 'PageView',
			pathToRegexpOptions: { strict: true },
			component: () =>
				import ( /* webpackChunkName: "PageView" */ '@/views/LuPage.view.vue')
		},
		{
			path: "/:lang/*",
			name: 'NotFoundView',
			component: () =>
				import ( /* webpackChunkName: "NotFoundView" */ '@/views/Lu404.view.vue')
		},
	],
})

// if route has no trailing slash > add one
// e.g. http://localhost:3000/de/cart is redirected to http://localhost:3000/de/cart/
router.beforeEach( ( to, from, next ) => {
	const toPath     = to.path
	const toFullPath = to.fullPath
	const debug      = false

	if( debug ) console.log('')
	if( debug ) console.log('router.beforeEach')
	if( debug ) console.log('	to.path:', toPath)
	if( debug ) console.log('	to.fullPath:', toFullPath)

	if( toPath.charAt( toPath.length - 1 ) != '/' ){
		const newPath = toPath + '/'
		if( debug ) console.log('%c	replace route:' + newPath, 'font-weight: bold')

		router.replace({
			path: newPath,
		})
	}else{
		next()
	}

})

// check if first param (lang) is valid
// if not redirect to /de/
router.beforeEach( ( to, from, next ) => {
	const currentLang  = to.params.lang
	const validLangs   = ['de', 'en']
	const fallbackLang = 'de'
	const isValidLang  = validLangs.includes( currentLang )
	const debugLog     = false

	if( debugLog ) {
		console.log('')
		console.log('router.beforeEach')
		console.log('	currentLang:', currentLang)
		console.log('	validLangs:', validLangs)
		console.log('	isValidLang:', isValidLang)
	}

	if( !isValidLang ) router.replace({ path: "/" + fallbackLang + "/" })

	next()
})

// export router
export default router
