<!--

	<LuMainMenu></LuMainMenu>

-->

<template>
	<div class="LuMainMenu contentModule">
		<ul class="LuMainMenu__ul">
			<!-- Suchen -->
			<li class="LuMainMenu__li LuMainMenu__row vSpace vSpace--navRow border border--top border--strong">
				<MhSearchForm 	class="font font--sizeMobileMenu"
								:placeholder="$t('placeholder.search')">
				</MhSearchForm>
			</li>
			<!-- Verlag -->
			<li class="LuMainMenu__li LuMainMenu__row vSpace vSpace--navRow border border--top border--strong ">
				<router-link  	class="LuMainMenu__a LuMainMenu__a--first font font--sizeMobileMenu font--bold"
								:to="'/'+$root.currentLang+'/publisher/'">
					{{ $t('nav.publisher') }}
				</router-link>
			</li>
			<!-- Termine -->
			<li class="LuMainMenu__li LuMainMenu__row vSpace vSpace--navRow border border--top border--strong">
				<router-link class="LuMainMenu__a font font--sizeMobileMenu font--bold"
							:to="'/'+$root.currentLang+'/events/'"
							@click.native="toggleNavList('events', true)">
							{{ $t('nav.events') }}
				</router-link>
				<button	class="LuMainMenu__listToggleBtn icon icon--triangle reset reset--button rotate"
						:class="{'rotate--180' : navListIsOpen__events }"
						@click="toggleNavList('events')">
						Toggle
				</button>
				<a class="LuMainMenu__mobileListToggleBtn" v-if="$root.isSmallDevice" @click="toggleNavList('events')" aria-role="button"></a>
			</li>
			<ul class="LuMainMenu__ul LuMainMenu__ul--second" v-show-slide="navListIsOpen__events">
				<li class="LuMainMenu__li LuMainMenu__li--second LuMainMenu__row vSpace vSpace--navRow border border--top">
					<router-link 	class="LuMainMenu__a LuMainMenu__a--second font font--sizeMobileMenu font--bold"
									:to="'/'+$root.currentLang+'/events/'">
						{{ $t('nav.currentEvents') }}
					</router-link>
				</li>
				<li class="LuMainMenu__li LuMainMenu__li--second LuMainMenu__row vSpace vSpace--navRow border border--top">
					<router-link 	class="LuMainMenu__a LuMainMenu__a--second font font--sizeMobileMenu font--bold"
									:to="'/'+$root.currentLang+'/archive/'">
						{{ $t('nav.pastEvents') }}
					</router-link>
				</li>
			</ul>

			<!-- just some urls for developing -->
			<template v-if="
				$root.hostname == 'localhostXX' ||
				$root.hostname == 'lubok.local:8080'">
				<li class="LuMainMenu__li LuMainMenu__row vSpace vSpace--navRow border border--top border--strong">
						<router-link 	class="LuMainMenu__a font font--sizeMobileMenu font--bold"
						:to="'/de/shop/volkskunst-fabrik/'">
						Volkskunst
					</router-link>
				</li>
				<li class="LuMainMenu__li LuMainMenu__row vSpace vSpace--navRow border border--top border--strong">
						<router-link 	class="LuMainMenu__a font font--sizeMobileMenu font--bold"
						:to="'/de/search/ein/'">
						Suche nach "ein"
					</router-link>
				</li>
				<li class="LuMainMenu__li LuMainMenu__row vSpace vSpace--navRow border border--top border--strong">
						<router-link 	class="LuMainMenu__a font font--sizeMobileMenu font--bold"
						:to="'/de/events/erstes-event/'">
						Marios Geburststag
					</router-link>
				</li>
			</template>

			<!-- Shop -->
			<li class="LuMainMenu__li LuMainMenu__row vSpace vSpace--navRow border border--top border--strong">
				<router-link 	class="LuMainMenu__a font font--sizeMobileMenu font--bold"
								:to="'/'+$root.currentLang+'/shop/'"
								@click.native="toggleNavList('shop', true)">
					{{ $t('nav.shop') }}
				</router-link>
				<button	class="LuMainMenu__listToggleBtn reset reset--button rotate icon icon--triangle"
						:class="{'rotate--180' : navListIsOpen__shop }"
						@click="toggleNavList('shop')">
						Toggle
				</button>
				<a class="LuMainMenu__mobileListToggleBtn" v-if="$root.isSmallDevice" @click="toggleNavList('shop')" aria-role="button"></a>
			</li>
			<ul class="LuMainMenu__ul LuMainMenu__ul--second" v-show-slide="navListIsOpen__shop">
				<li class="LuMainMenu__li LuMainMenu__li--second LuMainMenu__row vSpace vSpace--navRow border border--top">
					<router-link 	class="LuMainMenu__a LuMainMenu__a--second font font--sizeMobileMenu font--bold"
									:to="'/'+$root.currentLang+'/shop/'">
						{{ $t('nav.all') }} <span class="LuMainMenu__countProducts">{{app.countTotalProducts}}</span>
					</router-link>
				</li>
				<!-- Shop Filters -->
				<div v-show-slide="app.productFilters.length">
					<li class="LuMainMenu__li LuMainMenu__li--second LuMainMenu__row vSpace vSpace--navRow border border--top"
						v-for="productFilter in app.productFilters" :key="productFilter.id">
						<router-link 	class="LuMainMenu__a LuMainMenu__a--second font font--sizeMobileMenu font--bold"
										:to="'/'+$root.currentLang+'/shop/?filter=' + productFilter.slug">
							{{productFilter.title.rendered}}
							<span class="LuMainMenu__countProducts">{{productFilter.countProducts}}</span>
						</router-link>
					</li>
				</div>
			</ul>
			<!-- Warenkorb -->
			<li class="LuMainMenu__li LuMainMenu__row vSpace vSpace--navRow border border--top border--strong">
				<router-link 	class="LuMainMenu__a LuMainMenu__a--first font font--sizeMobileMenu font--bold"
								:to="'/'+$root.currentLang+'/cart/'">
					{{ $t('nav.cart') }} (<span class="font font--mono">{{$store.getters.countItemsInCart}}</span>)
				</router-link>
			</li>
			<!-- English/Deutsch -->
			<li class="LuMainMenu__li LuMainMenu__row vSpace vSpace--navRow border border--top border--strong">
				<router-link 	class="LuMainMenu__a LuMainMenu__a--first font font--sizeMobileMenu font--bold"
								:to="$root.toggleLangUrl">
					<template v-if="$root.currentLang == 'de'">English</template>
					<template v-if="$root.currentLang == 'en'">Deutsch</template>
				</router-link>
			</li>
			<!-- Impressum + Datenschutz -->
			<li class="LuMainMenu__li LuMainMenu__row vSpace vSpace--navFooterRow border border--top border--strong font--sizeMini font--bold">
				<div class="LuMainMenu__footerLinks font font--sizeMobileMenuSmall">
					<router-link class="LuMainMenu__a LuMainMenu__a--footerLink"
								 :to="'/'+$root.currentLang+'/imprint/'">
						{{ $t('nav.imprint') }}
					</router-link>
					<router-link class="LuMainMenu__a LuMainMenu__a--footerLink"
								 :to="'/'+$root.currentLang+'/gtb/'">
						{{ $t('nav.gtb') }}
					</router-link>
					<router-link class="LuMainMenu__a LuMainMenu__a--footerLink"
								 :to="'/'+$root.currentLang+'/privacy/'">
						{{ $t('nav.privacy') }}
					</router-link>
				</div>
			</li>
		</ul>
	</div>
</template>

<script>
	// @ is an alias to /src
	import MhSearchForm from '@/components/MhSearchForm/MhSearchForm.vue'
	import EventBus from '@/helper/EventBus.js'

	export default {
		name: 'LuMainMenu',
		components: {
			MhSearchForm,
		},
		props: {},
		data() {
			return {
				navListIsOpen__shop   : false,
				navListIsOpen__events : false,
			}
		},
		watch: {},
		computed: {
			app(){
				return this.$root.$children[0]
			},
		},
		methods: {
			toggleNavList( listName, newState = undefined ){
				let listState = this['navListIsOpen__' + listName]

				if( this._.isUndefined( newState ) ){
					this['navListIsOpen__' + listName] = !this['navListIsOpen__' + listName]
				}else{
					this['navListIsOpen__' + listName] = newState
				}
			},
		},
		created() {},
		mounted() {
			// open subnav lists depending on current route
			this.$router.afterEach( (to, from) => {
				const newRouteName = to.name

				//console.log('newRouteName:', newRouteName);
				//console.log('from:', from);
				//console.log('to:', to);
				if( newRouteName == 'ShopView' ) this.toggleNavList( 'shop', true )
				if( newRouteName == 'FilteredShopView' ) this.toggleNavList( 'shop', true )
				if( newRouteName == 'EventsView' ) this.toggleNavList( 'events', true )
				if( newRouteName == 'EventsArchiveView' ) this.toggleNavList( 'events', true )
			})
		},
	}
</script>

<style lang="less">
	@import "../less/vars.less";
	@import "../less/mixins.less";
	@import (reference) "../less/atoms.less";

	.LuMainMenu {
		//background-color: fade( yellow, 75 );

		&__row {
			display: flex;
		}
		&__li {
			position: relative;
			list-style: none;
			display: flex;
		}
		&__footerLinks {
			display: flex;
			width: 100%;
			justify-content: space-between;
		}

		// this fold toggle button disables the click on the events/shop-links
		// die auf dem desktop getriggert werden
		&__mobileListToggleBtn {
			//background-color: fade( red, 20 );

			position: absolute;
			top: 0; left: 0; right: 0; bottom: 0;
			z-index: 2;
			cursor: pointer;
		}

		&__a {
			position: relative;
			display: flex;
			flex-grow: 1;
			text-decoration: none;
			align-items: center;
			color: inherit;

			// indent second level links
			&--second {
				padding-left: 1em;
			}

			// active indicator
			&--first::before {
				//background-color: fade(red, 60);
				transition: all 0.4s ease;

				position: absolute;
				left: -1em;
				//transform: scale(0);
				transform-origin: center;

				//opacity: 0;
				font-size: 1.25em;
				content: "•";
			}
			&--first.routerLink--exactActive::before {
				left: -0.75em;
				//opacity: 1;
			}
			&--second::before {
				transition: all 0.35s ease;

				position: absolute;
				left: 0;
				left: -2em;
				//transform: scale(0);
				transform-origin: center;

				//opacity: 0;
				font-size: 1.25em;
				content: "•";
			}
			&--second.routerLink--exactActive::before {
				//transform: scale(1);
				left: 0;
				//opacity: 1;
			}
		}
		&__a--footerLink {
			flex-grow: 0;
		}
		&__countProducts {	// hochgestellter counter hinter den filtern unter shop
			display: inline-block;
			padding-left: .5em;
			transform: translateY(-.25em);

			font-size: .7em;
			line-height: 1em;
			font-weight: 400;
		}
	}

	@media @mediaQuery__xl {}
	@media @mediaQuery__lg {}
	@media @mediaQuery__dt {}
	@media @mediaQuery__md {}
	@media @mediaQuery__sm {}
	@media @mediaQuery__xs {}
</style>
