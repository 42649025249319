<!-- 

	Die Componente blendet ein definierbares Spaltenraster ein.
	Es kann mit Drücken der Taste 'G' ein- und ausgeblendet werden.
	
	Die Componente muss in dem Element plaziert werden für
	die das Grid angezeigt werden soll. z.b. ".app__content"
	
	Beispiel Code:
		<MhDevGrid
			padding="0 1rem"
			color="red"
			opacity="0.5"
			columns="8"
			maxWidth="1200px"
			gap="2vw"
		></MhDevGrid>
	
	2019-08-06	bugfix: durch "grid-auto-flow: column;" war das grid nicht genau. 
				die cols werden jetzt durch "grid-template-columns' : 'repeat(' + this.columns + ', 1fr);"
				positionsiert, da klappts :)
	2019-06-22	init

-->

<template>
	<div class="MhDevGrid" :class="elmClasses" :style="elmStyles">
		<div class="MhDevGrid__cols" :style="wrapperStyles">
			<div 
				class="MhDevGrid__col"
				:style="colStyles"
			 	v-for="(col, i) in _.parseInt( columns )" 
				:key="i"
			>{{i+1}}</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'MhDevGrid',
		components: {},
		mixins: [],
		props: {
			color: [String],
			opacity: [String, Number],
			padding: [String],
			columns: [String, Number],
			maxWidth: [String],
			gap: [String],
		},
		data() {
			return {
				isVisible : false,
			}
		},
		watch: {
			isVisible: { // save current 'isVisible' value to localStorage
				handler: function( to, from ) {
					localStorage.setItem('MhDevGrid.isVisible', JSON.stringify( this.isVisible ))
				},
				deep: true,
			}
		},
		computed: {
			elmClasses(){
				let classes = []
				
				return classes
			},
			elmStyles(){
				return {
					'visibility' : this.isVisible ? 'visible' : 'hidden',
				}
			},
			wrapperStyles(){
				return {
					'grid-column-gap' : this.gap,
					'grid-template-columns' : 'repeat(' + this.columns + ', 1fr)',
					'padding' : this.padding,
					'max-width' : this.maxWidth,
				}
			},
			colStyles(){
				return {
					'background-color' : this.color,
					'opacity' : this.opacity,
				}
			},
		},
		methods: {
			init(){	
				const wrapperElm = this.$el.parentNode
				const elmStyles = window.getComputedStyle( wrapperElm )
				const stylePosition = elmStyles.position
				
				//console.log( wrapperElm )
				//console.log( stylePosition )				
				
				// load previous 'isVisible' value from localStorage
				if( localStorage.getItem('MhDevGrid.isVisible') ) {
					this.isVisible = JSON.parse( localStorage.getItem('MhDevGrid.isVisible') )
				}
				
				// check for right 'position' css value in wrapper, at least 'relative' is needed
				if( stylePosition == 'static' ){
					console.error('MhDevGrid: Wrapper has "position:static" but at least "position:relative" is needed.');
				}
			},
			keydownHandler( e ) { // event handler for grid toggle with 'KeyG'
				const eCode            = e.code;
				const keyCode          = e.keyCode;
				const activeElmTagName = document.activeElement.tagName.toLowerCase()

				// not if input or textarea is focused
				if( activeElmTagName == 'input' || activeElmTagName == 'textarea' ) return

				// w: toggle borders
				if( eCode == 'KeyG' ){
					this.isVisible = !this.isVisible
					e.preventDefault() // needed for ff to prevent triggering site search
				}
			},
		},
		created: function() {
			window.addEventListener('keydown', this.keydownHandler);
		},
		destroyed: function() {
			window.removeEventListener('keydown', this.keydownHandler);
		},
		mounted() {
			this.init()
		},
	}
</script>

<style lang="less">
	.MhDevGrid {
		position: absolute;
		top: 0; left: 0; right: 0; bottom: 0; // span the wrapper
		pointer-events: none; 
		display: flex;
		justify-content: center;
		
		z-index: 100;
		
		&__cols {
			//position: absolute;
			//top: 0; left: 0; right: 0; bottom: 0; // span the wrapper
			display: grid;
			//grid-auto-flow: column;
			//max-width: 1200px;
			width: 100%;
		}
		&__col {
			// just for colum-index text styling
			text-align: right;
			line-height: 1em;
			padding: 0.25em;
			min-height: 0;
			min-width: 0;
		}
	}
</style>
