<!--

	Was macht diese Componente?
	Wofür ist die Compoente da?

	Welche $props gibt es?

	Beispiel Code:
		<Dummy></Dummy>

	2019-06-08	init

-->

<template>
	<div class="LuHandleRouteChangeTransitions" :class="elmClasses"></div>
</template>

<script>
	// @ is an alias to /src
	//import DevInfos from '@/components/DevInfos.vue'
	import EventBus from '@/helper/EventBus.js'

	export default {
		name: 'LuHandleRouteChangeTransitions',
		components: {},
		mixins: [],
		props: {
			//imageObject: [Object, Boolean],
			//text: [String],
		},
		data() {
			return {
				appSelector : '.app',
				appElm      : null,
				debugLog    : false,
			}
		},
		watch: {
			someVar: {
				handler: function( to, from ) {
				},
				deep: true,
			}
		},
		computed: {
			app() {
				return this.$root.$children[0]
			},
			elmClasses(){
				let classes = []

				return classes
			},
		},
		methods: {},
		created() {},
		mounted() {
			const appElm = document.querySelector( this.appSelector )
			const debugLog = this.debugLog

			if( appElm ) this.appElm = appElm
			if( appElm ) this.appElm.classList.add("routeChange")

			/*
			this.$router.beforeEach( (to, from, next ) => {
				const debugLog = this.debugLog

				if( debugLog ){
					console.log('')
					console.group('routeChange: beforeEach')
					console.groupEnd('routeChange: beforeEach')
				}

				if( this.appElm ) this.appElm.classList.remove("routeChange--finished")
				if( this.appElm ) this.appElm.classList.add("routeChange--before")

				next()
			})
			this.$router.afterEach( (to, from) => {
				const debugLog = this.debugLog

				if( debugLog ){
					// console.log('')
					console.group('routeChange: afterEach')
					console.groupEnd('routeChange: afterEach')
				}

				if( this.appElm ) this.appElm.classList.remove("routeChange--before")
				if( this.appElm ) this.appElm.classList.add("routeChange--finished")

				this.scrollTo = 0
			})
			*/

			EventBus.$on('MhView: stateChange', (to) => {
				if( debugLog ) console.log( 'MhView: stateChange', to );
			})
		},
	}
</script>

<style lang="less">
	@import "../less/vars.less";
	@import "../less/mixins.less";
	@import (reference) "../less/atoms.less";

	.LuHandleRouteChangeTransitions {
	}

	.routeChange--before {
		.MhView--state-loading {
			//background-color: red;
		}
	}
	.routeChange--finished {
		.MhView--state-loaded {
			//background-color: green;
		}
	}

	@media @mediaQuery__xl {}
	@media @mediaQuery__lg {}
	@media @mediaQuery__dt {}
	@media @mediaQuery__md {}
	@media @mediaQuery__sm {}
	@media @mediaQuery__xs {}
</style>
