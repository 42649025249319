<!--

	Was macht diese Componente?
	Wofür ist die Compoente da?

	Welche $props gibt es?

	Beispiel Code:
		<LuHeadline
			:text="'Amazing Title'"
			:subText="'My not-bold-line below the Amazing Title'"
			:hasLink="false"
			:linkUrl="'//www.google.de'"
			:hasAnchor="true"
			:anchorTitle="'String'"
			:startDate="'20191224'"
			:endDate="'20191227'"
			:size="big"
			:lineAbove="false"
		></LuHeadline>

	2019-06-08	init

-->

<template>
	<div class="LuHeadline contentModule" :class="elmClasses">
		<div class="LuHeadline__inner font vSpace vSpace--LuHeadline"
			:class="titleClasses">
			<a class="LuHeadline__anchor" :id="$root.slugify( anchorTitle )" :anchor="$root.slugify( anchorTitle )" v-if="hasAnchor">{{$root.slugify( anchorTitle )}}</a>
			<h2 class="font font--bold maxWidth maxWidth--headline" :class="{ 'color color--award' : hasLink }" v-html="text"></h2>
			<strong>
				<LuDate :class="{ 'color color--award' : hasLink }" :startDate="startDate" :endDate="endDate" :showDaysLeft="true"></LuDate>
			</strong>
			<div :class="{ 'color color--award' : hasLink }" v-if="subText" v-html="subText"></div>
			<router-link class="LuHeadline__link" :to="linkUrl" v-if="hasLink">Permalink</router-link>
		</div>

		<!--
		<pre name="linkUrl">{{linkUrl}}</pre>
		<pre name="hasLink">{{hasLink}}</pre>
		-->
	</div>
</template>

<script>
	// @ is an alias to /src
	//import DevInfos from '@/components/DevInfos.vue'
	import EventBus from '@/helper/EventBus.js'
	import LuDate from '@/components/LuDate.vue'

	export default {
		name: 'LuHeadline',
		components: {
			LuDate,
		},
		mixins: [],
		props: {
			//imageObject: [Object, Boolean],
			text: [String, Boolean],
			subText: [String, Boolean],
			hasLink: [Boolean],
			linkUrl: [String, Boolean],
			hasAnchor: [Boolean],
			anchorTitle: [String, Boolean],
			startDate: [String, Number, Boolean],
			endDate: [String, Number, Boolean],
			lineAbove: [Boolean],
			size: {
				type: String,
				default: 'big',
			}
		},
		data() {
			return {
			}
		},
		watch: {},
		computed: {
			app() {
				return this.$root.$children[0]
			},
			elmClasses(){
				let classes = []

				return classes
			},
			titleClasses() {
				let classes = []

				if( this.lineAbove ) {
					classes.push('border')
					classes.push('border--strong ')
					classes.push('border--top')
				}

				classes.push('font--size' + this._.upperFirst( this.size ) )

				return classes
			}
		},
		methods: {},
		created() {},
		mounted() {},
	}
</script>

<style lang="less">
	@import "../less/vars.less";
	@import "../less/mixins.less";

	.LuHeadline {
		position: relative;

		&__anchor {
			position: absolute;
			top: -8.5rem;
			pointer-events: none;
			visibility: hidden;
		}
		&__link {
			//background-color: fade(green, 10);

			position: absolute;
			top: 0; left: 0; right: 0; bottom: 0;
			overflow: hidden;
			text-indent: -100vw;
		}
	}

	@media @mediaQuery__xs {}
	@media @mediaQuery__sm {}
	@media @mediaQuery__md {}
	@media @mediaQuery__dt {}
	@media @mediaQuery__lg {}
	@media @mediaQuery__xl {}
</style>
