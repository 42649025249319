<!--

	Was macht diese Componente?
	Wofür ist die Compoente da?

	Welche $props gibt es?

	Beispiel Code:
		<MhErrorHandler></MhErrorHandler>

	2019-09-15	init

-->

<template>
	<div class="MhErrorHandler" :class="elmClasses"></div>
</template>

<script>
	// @ is an alias to /src
	//import DevInfos from '@/components/DevInfos.vue'
	import EventBus from '@/helper/EventBus.js'
	import Vue from 'vue'

	//*
	Vue.config.errorHandler = (err, vm, info) => {
	  	// err: error trace
	  	// vm: component in which error occured
	  	// info: Vue specific error information such as lifecycle hooks, events etc.

	  	// TODO: Perform any custom logic or log to server
		console.log('Vue.config.errorHandler')
		console.log('err', err)
		console.log('vm', vm)
		console.log('info', info)
		//alert('error')
	}
	//*/

	export default {
		name: 'Dummy',
		components: {},
		mixins: [],
		props: {
			//imageObject: [Object, Boolean],
			//text: [String],
		},
		data() {
			return {
			}
		},
		watch: {
			someVar: {
				handler: function( to, from ) {
				},
				deep: true,
			}
		},
		computed: {
			app() {
				return this.$root.$children[0]
			},
			elmClasses(){
				let classes = []

				return classes
			},
		},
		methods: {},
		created() {},
		mounted() {},
	}
</script>

<style lang="less">
	@import "../../less/vars.less";
	@import "../../less/mixins.less";
	@import (reference) "../../less/atoms.less";

	.MhErrorHandler {
	}

	@media @mediaQuery__xl {}
	@media @mediaQuery__lg {}
	@media @mediaQuery__dt {}
	@media @mediaQuery__md {}
	@media @mediaQuery__sm {}
	@media @mediaQuery__xs {}
</style>
