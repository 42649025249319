<template>
	<div class="app app--layout-desktop handleLinkColors" :lastRouteName="routeFrom ? routeFrom.name : ''" :currentRouteName="$route.name">

		<LuMainHeader
			class="app__nav"
			v-if="!$root.isSmallDevice"
		></LuMainHeader>

		<main class="app__body">
			<transition
				name="fade"
				mode="out-in"
				:duration="{ leave: 200, enter: 200  }"
				appear>
				<router-view class="app__bodyInner" :key="$route.fullPath" />
			</transition>

			<div class="app__loadingSpinner"></div>
		</main>

		<LuHandleRouteChangeTransitions></LuHandleRouteChangeTransitions>
		<LuTint></LuTint>

		<MhErrorHandler></MhErrorHandler>
		<MhDelegateLinks :doLog="true"></MhDelegateLinks>
		<MhCart></MhCart>

		<MhDevInfos
			:showOnHosts="['192', 'localhost', 'lubok.local', 'dev.lubok.de']"
		></MhDevInfos>

	</div>
</template>

<script>
	// @ is an alias to /src
	import EventBus from '@/helper/EventBus.js'
	import resize from 'vue-resize-directive'
	import MhErrorHandler from '@/components/MhErrorHandler/MhErrorHandler.vue'
	import restHandler from '@/mixins/restHandler/v1/restHandler.mixin.js'
	import MhDevInfos from '@/components/MhDevInfos/MhDevInfos.vue'
	import MhCart from '@/components/MhCart/MhCart.vue'
	import MhDelegateLinks from '@/components/MhDelegateLinks/v5/MhDelegateLinks.vue'
	import MhImage from '@/components/MhImage/MhImage.vue'
	import MhView from '@/components/MhView/MhView.vue'
	import LuMainHeader from '@/components/LuMainHeader.vue'
	import LuHandleRouteChangeTransitions from '@/components/LuHandleRouteChangeTransitions.vue'
	import LuTint from '@/components/LuTint.vue'
	import LuMobileHeader from '@/components/LuMobileHeader.vue'

	export default {
		name: 'App',
		components: {
			MhErrorHandler,
			MhDevInfos,
			MhCart,
			MhDelegateLinks,
			MhImage,
			MhView,
			LuMainHeader,
			LuHandleRouteChangeTransitions,
			LuTint,
			LuMobileHeader,
		},
		directives: {
			resize,
		},
		mixins: [ restHandler ],
		data() {
			return {
				countTotalProducts : null,
				productFilters : [],
				shippingInfosLink__de : null,
				shippingInfosLink__en : null,
				routeFrom : null,
				routeTo : null,
			}
		},
		computed: {},
		methods: {},
		mounted() {
			// store before and current routeNames
			this.$router.afterEach( (to, from) => {
				this.routeFrom = from
				this.routeTo = to
			})

			// reset colors and route change
			// set app__body background for dark views
			//this.$router.beforeEach( (to, from, next) => {
			// TODO:5370 remove this
			let doit = false
			if( doit ){
				this.$router.afterEach((to, from) => {
					const toName = to.name
					const viewsWithDarkBg = ['ProductView', 'ShopView', 'FilteredShopView']
					const viewsWithVibrantColor = ['ProductView']
					const viewHasDarkBg = this._.includes( viewsWithDarkBg, toName )
					const viewHasVibrantColor = this._.includes( viewsWithVibrantColor, toName )

					/*
					console.log('');
					console.log('Handle appBodyClasses + appBodyColor + appNavColor');
					console.log('	$router.afterEach');
					console.log('		viewHasDarkBg:', viewHasDarkBg);
					console.log('		viewHasVibrantColor:', viewHasVibrantColor);
					*/

					if( viewHasDarkBg ){
						this.appBodyClasses = 'background background--productTeaser'
						this.appBodyColor = 'white'
					}
					if( viewHasDarkBg && !viewHasVibrantColor ){
						this.appNavColor = ''
					}
					if( !viewHasDarkBg && !viewHasVibrantColor ){
						this.appBodyClasses = ''
						this.appBodyColor = ''
						this.appNavColor = ''
					}

					//next()
				})
			}

			// fetch productFilters for the menu
			this.restHandler__fetch({
				action : 'GET',
				route : '/wp-json/mh/v1/lubok__productFilters',
				params : {},
				callback : (response) => {
					this._.forEach(response.data.result, (post) => {
						this.productFilters.push( post)
					})
				},
			})
			// fetch products to get countTotalProducts
			this.restHandler__fetch({
				action : 'GET',
				route : '/wp-json/mh/v1/posts/?postType=product_post&perPage=1',
				params : {},
				callback : (response) => {
					this.countTotalProducts = response.data.info.totalPosts
				},
			})

			// fetch acfOptions to get general options
			this.restHandler__fetch({
				action : 'GET',
				route : '/wp-json/mh/v1/acfOptions',
				params : {},
				callback : (response) => {
					const result = response.data.result

					this.shippingInfosLink__de = this.$root.getPathFromUrl( result.shippingInfosLink__de )
					this.shippingInfosLink__en = this.$root.getPathFromUrl( result.shippingInfosLink__en )
				},
			})
		}
	}
</script>

<style lang="less">
	@import "less/includes/normalize.include.less";
	@import "less/vars.less";
	@import "less/mixins.less";
	@import "less/atoms.less";

	@import "less/fonts.less";
	@import "less/includes/grid.include.less";

	//@columGap               : 0.75rem;
	@sidebar__width         : 265px; // TODO gehört in vars.less
	@transitionDuration     : 0.2s; // TODO gehört in vars.less
	@fadeTransitionDuration : @transitionDuration; // TODO gehört in vars.less

	.contentModule {
		[showBorders2] & {
			.label( attr(class), yellow, black );
			.outline( yellow );
		}
	}
	.hideScrollbars {
		-webkit-overflow-scrolling: touch;				// ansonsten ist das ux absolut steif. wieder was gelernt :)
				-ms-overflow-style: none !important;	// IE 10+
				   scrollbar-width: none !important; 	// Firefox

		&::-webkit-scrollbar {
			width: 0px !important;
		}
	}

	// elements by tags
	html {
		// this should prevent vertical scrolling
		max-width: 100%;
		overflow-x: hidden;
		overflow-y: auto;
	}
	body {
		// neben dem besseren schriftrendering löst das im safari außerdem das flackern des
		// headers wenn ein neues template geladen wird und manchmal wenn text gehovert wird
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;

		color: @swatches[textBlack];
	}
	ol { // TODO gehört in LuText
		@li__indent: 1.5em;

		.app__body & {
			counter-reset: section;
			list-style: none;

			li {
				position: relative;
				padding-left: @li__indent;
			}
			li:before {
				position: absolute;
				top: 0; left: 0;

				counter-increment: section;
				content: counters(section,".") ".";
			}
		}
	}
	ul { // TODO gehört in LuText
		@li__indent: 1.5em;

		//.app__body & {
		.LuText & {
			//background-color: fade( red, 20 );
			list-style: none;

			li {
				position: relative;
				padding-left: @li__indent;
			}
			li:before {
				position: absolute;
				top: 0; left: 0;

				content: "•";
			}
		}
	}

	.app {
		&__nav {
			position: fixed;
			top: 0; left: 0; bottom: 0;
			width: @sidebar__width;
			z-index: 10;
		}
		&__mobileNav {
			@media @mediaQuery__md {}
		}
		&__loadingSpinner {
			@outer__width  : 3em;
			@inner__width  : 2em;
			@stroke__width : 2px;
			@duration      : 1s;

			position: absolute;
			top: calc( 11.75rem );
			top: calc( 2.5rem );
			left: calc( 2vw + 1.5rem + @sidebar__width - 3px );
			height: @outer__width;
			width: @outer__width;
			z-index: 200;
			//background-color: currentColor;
			border-radius: 50%;
			overflow: hidden;
			display: flex;
			justify-content: center;
			align-items: center;
			opacity: 0;
			pointer-events: none;

			color: red;
			color: @swatches[textBlack];
			color: @swatches[awardColor];
			color: darken( white, 20 );

			&::before{
				display: block;
				height: @inner__width; width: @inner__width;
				border-radius: inherit;
				animation: spin @duration linear infinite;
				content: "";
			}
			&::before{
				border-top:    @stroke__width solid fade( @swatches[textWhite], 25 );
				border-right:  @stroke__width solid currentColor;
				border-bottom: @stroke__width solid currentColor;
				border-left:   @stroke__width solid currentColor;
			}

			@keyframes spin { 100% { transform:rotate(360deg); } }

			@media @mediaQuery__xs {}
			@media @mediaQuery__sm {}
			@media @mediaQuery__md {
				top: calc( 1rem );
				left: calc( 0.75rem );
			}
			@media @mediaQuery__dt {}
			@media @mediaQuery__lg {}
			@media @mediaQuery__xl {}
		}
		&__body {
			position: relative;
			//min-height: 100vh; // brauchen wir das wirklick?
		}

		@media @mediaQuery__dt {
			&__body {
				padding-left: @sidebar__width;
			}
		}
	}
	.bigNumber {
		//background-color: fade(red, 20);
		//& + * { background-color: fade(green, 20); }
		//color: red;

		//.font--bold;
		//.font--sizeHuge;
		display: inline-block;
		font-size: 3.5rem;
		width: 0.75em;
		line-height: 0.8em;
		margin-bottom: 0.1rem;

	}
	.featuredProducts {
		color: white;
	}
	.formElm {
		&--input {
			width: 2em;
			height: 1.5em;
		}
	}
	.handleLinkColors {

		@colorOnWhite : rgb(63, 63, 191);
		@colorOnWhite : darken( darkorange, 10 );
		@colorOnWhite : green;
		@colorOnWhite : red;
		@colorOnWhite : rgb(154, 120, 100);
		@colorOnWhite : lighten( rgb(208, 77, 105), 10 );

		@colorOnWhite : #DE3A7E;
		@colorOnWhite : @swatches[awardColor];
		@colorOnBlack : fade( white, 65 );

		// helper mixins
		.colorizeText( @color : @colorOnWhite ) {
			transition: all 0.05s ease;
			color: @color;
			opacity: 1;

			&:hover { opacity: 1; }
		}
		.colorizeUnderline( @color : currentColor ) {
			position: relative;
			text-decoration: none;

			background-image: linear-gradient(to right, @color, @color);
 			background-position: 0 1.025em;
 			background-repeat: repeat-x;
 			background-size: auto 0.04em;

			//&:hover { background-size: auto 0.08em; }
		}
		.colorizeTextOutline( @outlineColor : white, @shadowWidth : 0.07em ){
			text-shadow:
		      -@shadowWidth 0px 0 @outlineColor,
		       @shadowWidth 0px 0 @outlineColor,
		       0px @shadowWidth 0 @outlineColor,
		       0px -@shadowWidth 0 @outlineColor;
		}

		// farbige links auf weiss
		.LuText__inner--textOnWhite a {
			.colorizeText( @colorOnWhite );
			//.colorizeUnderline( @colorOnWhite );
			.colorizeUnderline( transparent );
			//.colorizeTextOutline( @swatches[white] );
		}
		.LuEventsWidget a {
			.colorizeText( @colorOnWhite );
		}
		// weisse links auf dunklem untergrund, z.B. product detail
		.LuText__inner--textOnBlack a {
			//.colorizeText( @colorOnBlack );
			//.colorizeUnderline( @colorOnBlack );
			//.colorizeTextOutline( @swatches[shopBackground] );
			color: inherit;
			border-bottom: 1px dotted;
			opacity: 0.8;
		}
		.LuText__inner--textOnBlack a:hover {
			border-bottom-style: solid;
			opacity: 1;
		}
		.LubokSearchView a.link--withArrow {
			.colorizeText( @colorOnWhite );
		}

		XXX.LuMainHeader__a:before {
			color: @colorOnWhite
		}

		// set underline
		.LuText__inner--textOnWhite a:hover {
			.colorizeUnderline( @colorOnWhite );
		}

		// set color transition
		XXX.LuSubNav__li a,
		.LuCartRow a.block,
		.LuCartRow a.link--color,
		.LuEventsWidget a,
		XXX.LuMainHeader__a {
			transition: color 0.1s ease;
		}

		// set color
		//.LuSubNav__li a:hover,
		.LuEventsWidget a:hover,
		.LuCartRow a.block:hover,
		.LuCartRow a.link--color,
		XXX.LuMainHeader__a:hover {
			color: @colorOnWhite;
		}
		&[currentRouteName="ProductView"] .LuMainHeader__a:hover {
			color: @swatches[shopBackground];
		}

		// Mobile Header
		XXX.LuMobileHeader a {
			transition: all 0.05s ease;
			opacity: 0.75;
		}
		XXX.LuMobileHeader a:hover {
			opacity: 1;
		}
	}

	@media @mediaQuery__xs {}
	@media @mediaQuery__sm {}
	@media @mediaQuery__md {}
	@media @mediaQuery__dt {}
	@media @mediaQuery__lg {}
	@media @mediaQuery__xl {}
</style>
