<!--

	Die Componente dient als transparent Wrapper für alle Input Elemente

	Props:
		value	String 	Wird als value des Inputs verwendet
		label 	String 	Wird vor dem Input angezeigt

	Markup:
		<BaseInput
			type="number"
			min="1" max="99" step="1"
			@change="myFunction( $event.target.value )"
			:value="123"
		></BaseInput>

		<BaseInput
			label="Vorname"
			type="text"
			@change="myFunction( $event.target.value )"
			:value="'someString'"
			:placeholder="'Max'"
		></BaseInput>

	Changelog
		2019-06-13	init

-->

<template>
	<label class="BaseInput" :class="elmClasses">
		<span class="BaseInput__label">{{ label }}</span>
		<input
			ref="input"
			class="BaseInput__input"
		  	:value="value"
		  	v-bind="$attrs"
		  	v-on="listeners"
		  	@input="input"
		/>
		<div class="BaseInput__spinnerBtns" v-if="this.type == 'number'">
			<div @click.prevent="increaseValue()" class="BaseInput__spinnerBtn BaseInput__spinnerBtn--increase"><span></span></div>
			<div @click.prevent="decreaseValue()" class="BaseInput__spinnerBtn BaseInput__spinnerBtn--decrease"><span></span></div>
		</div>
	</label>
</template>

<script>
	// @ is an alias to /src
	//import DevInfos from '@/components/DevInfos.vue'
	//import EventBus from '@/helper/EventBus.js'

	export default {
		name: 'BaseInput',
		components: {},
		mixins: [],
		props: ['value', 'label'],
		data() {
			return {
				type : null,
				step : null,
			}
		},
		watch: {},
		computed: {
			elmClasses(){
				 let classes = []

				 classes.push( 'BaseInput--' + this.type )

				 return classes
			},
			listeners(){
				const { input, ...listeners } = this.$listeners;
				return listeners;
			},
		},
		methods: {
			input( event ) {
				// handle empty, min and max values for numbers
				if( event.target.type == 'number' ){
					let value = parseFloat( event.target.value )
					let min   = event.target.min ? parseFloat( event.target.min ) : false
					let max   = event.target.max ? parseFloat( event.target.max ) : false

					/*
					console.log('')
					console.log('on input', value)
					console.log('event.target.value', event.target.value)
					console.log('event.target.max', event.target.max)
					console.log('event.target.min', event.target.min)
					*/

					// prevent empty values
					if( min && !value ){
						event.target.value = min
					}
					// prevent values below min
					if( min && value < min ){
						event.target.value = min
					}
					// prevent values above max
					if( max && value > max ){
						event.target.value = max
					}
				}

				this.$emit('input', event.target.value)
			},
			increaseValue() {
				const step     = this.step ? this.step : 1
				const value    = parseFloat( this.$refs.input.value )
				const newValue = value + step

				/*
				console.log('increaseValue')
				console.log('value', value )
				console.log('newValue', newValue )
				*/

				this.$refs.input.value = newValue
				this.$emit('input', newValue)
			},
			decreaseValue() {
				const step     = this.step ? this.step : 1
				const value    = parseFloat( this.$refs.input.value )
				const newValue = value - step

				/*
				console.log('decreaseValue')
				console.log('value', value )
				console.log('newValue', newValue )
				*/

				this.$refs.input.value = newValue
				this.$emit('input', newValue)
			},
		},
		created() {},
		mounted() {
			this.type = this.$refs.input.type
			this.step = parseFloat( this.$refs.input.step )
		},
	}
</script>

<style lang="less">
	.BaseInput {
		position: relative;

		.BaseInput__input {
			background-color: fade(black, 5);
			border: none;
			font: inherit;
			max-width: 100%;
			height: 1.6em;
			border-radius: 2px;
			border: 1px solid fade(black, 10);

			&::placeholder {
				opacity: 0.5;
				color: currentColor;
			}
			&:focus {
				outline: none;
				opacity: 1;

				&::placeholder {
					opacity: 0;
				}
			}
		}
		.BaseInput__spinnerBtns {
			display: none;
		}
	}
	.BaseInput--number,
	.BaseInput--text {
		.BaseInput__input:focus {
			background-color: fade(black, 10);
			outline: none;
		}
	}
	.BaseInput--number {
		input::-webkit-inner-spin-button {
  			opacity: 0;
		}

		@btns__gap : 2px;
		@btns__width : 1em;
		@btns__paddingLeft : 0.2em;
		@btns__paddingRight : 0.2em;
		@btns__paddingTop : 0.3em;
		@btns__paddingBottom : 0.3em;

		// CSS clip-path maker : https://bennettfeely.com/clippy/

		.BaseInput__spinnerBtns {
			//background-color: fade( red, 75 );

			position: absolute;
			top: 0; bottom: 0; right: 0;
			width: @btns__width;
			//padding: 0 0.2em;

			display: flex;
			flex-direction: column;
			justify-content: center;
			display: none;
		}
		.BaseInput__spinnerBtn {
			//outline: 1px solid green;
			//background-color: fade( green, 35 ) !important;
			height: calc( 100% - @btns__gap*0.5);
			padding-left: @btns__paddingLeft;
			padding-right: @btns__paddingRight;
			display: flex;
			cursor: pointer;

			span {
				//background-color: yellow !important;
				background-color: currentColor;
				display: block;
				opacity: 0.5;
				width: 100%;
			}
		}
		.BaseInput__spinnerBtn:hover {
			span {
				opacity: 1;
			}
		}
		.BaseInput__spinnerBtn--increase {
			margin-bottom: @btns__gap*0.5;
			padding-top: @btns__paddingTop;

			span {
				clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
			}
		}
		.BaseInput__spinnerBtn--decrease {
			margin-top: @btns__gap*0.5;
			padding-bottom: @btns__paddingBottom;

			span {
				clip-path: polygon(50% 100%, 0 0, 100% 0);
			}
		}
	}
	.BaseInput--text {
		.BaseInput__input {
			padding-left: 0.3em;
		}
	}

	.BaseInput--newsletterAbo {
		.BaseInput__input {
			padding-left: 0.3em;
			height: 2em;
		}
	}
	.BaseInput--addToCart {
		border-radius: 2px;
		border: 1px solid;
		overflow: hidden;

		width: 3.2em; height: 2.75em;
		display: block;
		margin-right: 0.5em;
		padding: 0;
		background-color: black;

		.BaseInput__input {
			//background-color: red;

			padding-left: 0.35rem;
			height: inherit; width: inherit !important;
			color: inherit;
			text-align: left !important;
			font-size: 1.2em;
		}
	}
</style>
