<!--

	MhView
	======
	Wrapper for <router-view></router-view> with
	fade transitions and loading spinner if needed
	keep scroll postions at browsers back/forward button navigation

	property "state" enum:
		"", 		> nothing
		"loading",	> shows the spinner
		"loaded"	> hides the spinner again

	property "state" can be changed from child component via $emit:
		this.$emit('stateChanged', 'loading') // before axios fetch
		this.$emit('stateChanged', 'loaded')  // after axios fetch

	Welche $props gibt es?
		spinnerSize="2rem"		<< default 2rem
		spinnerColor="black"	<< default black

	Beispiel Code:
		<MhView
			:spinnerSize="'2rem'"
			:spinnerColor="'black'"
		></MhView>

	2019-09-26	big change: um mit mobilen browsern besser kompaitibel zu sein
							habe ich die alle fixed position wieder entfernt,
							es wird jetzt immer body gescrollt. damit klappt
							das ausblenden der browser ui wieder
							das handleScrolling ist in diesem zuge rausgeflogen
	2019-08-14	improvement: added EventBus emites: changeStart + changeEnd
	2019-08-06	improvement: added spinnerSize and spinnerColor als props
	2019-08-06	improvement: place the styles in this file (was in App.vue before)
	2019-08-06	improvement: renamed classes from "mhView" to "MhView"
	2019-07		bugfix: remember scroll position funktioniert jetzt wieder
	2019-05-21	first refactored commit

-->

<template>
	<div class="MhView" :state="''" :class="classes" :fromRouteName="fromRouteName" :toRouteName="toRouteName">
		<MhSpinner class="MhView__loadingSpinner" :style="MhSpinnerStyles"></MhSpinner>

		<div class="MhView__inner">
			<!--
			<transition
				name="fade"
				mode="out-in"
				:duration="{ leave: 200, enter: 750  }"
				@before-enter="beforeEnterTransition"
				@enter="enterTransition"
				@leave="leaveTransition">
				<router-view
					:key="$route.fullPath"
				/>
			</transition>
			-->
			<router-view :key="$route.fullPath" />
		</div>
	</div>
</template>

<script>
	// @ is an alias to /src
	import { throttle } from 'lodash'
	import EventBus from '@/helper/EventBus.js'
	import MhSpinner from '@/components/MhSpinner/MhSpinner.vue'

	export default {
		name: 'MhView',
		components: {
			MhSpinner
		},
		props: {
			spinnerSize: {
				type: [String, Boolean],
				default: '2rem'
			},
			spinnerColor: {
				type: [String, Boolean],
				default: 'black'
			},
		},
		data() {
			return {
				state : '',
				debugLog : false,
				classes : '',
				fromRouteName : '',
				toRouteName : '',

				elmOffset : {
					top : undefined,
					left : undefined,
					width : undefined,
					height : undefined,
				}
			}
		},
		watch: {
			'$route.path' ( to, from ){	// resets the state if route changes
				this.state = ''
			},
		},
		computed: {
			MhSpinnerStyles(){
				let styles = {}
				const x = ( this.elmOffset.width/2 + this.elmOffset.left )
				const y = ( this.elmOffset.top )

				styles = {
					top : 'calc( 50vh - ' + y + 'px)',
					left : 'calc( ' + x + 'px)',
					height: this.spinnerSize,
					width: this.spinnerSize,
					color: this.spinnerColor,
				}

				return styles
			},
		},
		methods: {
			beforeEnterTransition( el ){
				//console.log('beforeEnterTransition()')
			},
			enterTransition( el ){
				//console.log('enterTransition()')
			},
			leaveTransition( el ){
				//console.log('leaveTransition()')
			},
			windowResizeHandler( e ){
				this.setElmOffset()
			},
			setElmOffset(){ // these are needed to keep the spinner centered
				const elm = this.$el

				this.elmOffset.top = elm.offsetTop
				this.elmOffset.left = elm.offsetLeft
				this.elmOffset.width = elm.offsetWidth
				this.elmOffset.height = elm.offsetHeight
			},
		},
		created() {
			EventBus.$on('stateChanged', newState => {
				this.state = newState

				if( this.state == 'loading' ){
					this.classes = 'MhView--state-loading'
				}
				if( this.state == 'loaded' ){
					this.$nextTick(() => {
						this.classes = 'MhView--state-loaded'
					})
				}
			})

			window.addEventListener('resize', this.windowResizeHandler);
		},
		destroyed() {
			window.removeEventListener('resize', this.windowResizeHandler);
		},
		mounted() {
			// setElmOffset to center the spinner
			this.setElmOffset()
		},
	}

</script>

<style lang="less">
	@import "../../less/vars.less";
	@import "../../less/mixins.less";
	@import (reference) "../../less/atoms.less";

	@transitionDuration : 0.2s;	// for router-view change animation
	@transitionDelay    : 0.1s;	// for router-view change animation
	// delay ist totaler quatsch, das macht timing stress
	@transitionDelay    : 0s;	// for router-view change animation
	@transitionDuration : 0s;	// for router-view change animation

	.MhView {
		position: relative;
		//background-color: fade( red, 20 );
		min-height: 100vh;

		&__loadingSpinner {
			transition-duration: @transitionDuration * 0.5; // make it faster appear/disappear
			transition: opacity @transitionDuration ease-in-out;

			position: fixed;
			transform: translateY(-50%) translateX(-50%);
			pointer-events: none;
			opacity: 0;
			z-index: 2;
		}
		&__inner {
			transition: opacity @transitionDuration ease;
			opacity: 0;

			//position: absolute; top: 0; left: 0; right: 0; bottom: 0;
			//width: inherit;
			//overflow: hidden;

			// muss scroll sein damit die scrollbar angezeigt wird und die restlichen layout berechnungen stimmen
			// overflow-y: auto;
			//overflow-y: scroll;
			//-webkit-overflow-scrolling: touch; // enable smooth scrolling on iOS
			//z-index: 1;	 // wozu der z-index?
			//display: none;
		}

		// handle states
		&--state-loading {
			.MhView__inner {
				//opacity: 0;
				pointer-events: none;
			}
			.MhView__loadingSpinner {
				opacity: 1;
			}
		}
		&--state-loaded {
			.MhView__inner {
				opacity: 1;
			}
			.MhView__loadingSpinner {
				//opacity: 1 !important; // just for debug
				opacity: 0;
			}
		}

		// router view transition
		.fade-enter-active,
		.fade-leave-active {
			transition-duration: @transitionDuration;
			transition-property: opacity;
			transition-timing-function: ease;
		}
		.fade-enter-active {
			transition-delay: @transitionDelay !important;
		}
		.fade-enter,
		.fade-leave-active {
			opacity: 0;
			transition-delay: @transitionDelay !important;
		}
	}

</style>
